import api, { Branch } from '@api';
import { RoutedDialog } from '@tsp-ui/core';
import { RoutedDialogProps } from '@tsp-ui/core/components';
import {
    replaceItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './BranchDialog.module.scss';
import AddEditBranchDialogContent from './components/AddEditBranchDialogContent';
import BranchesDialogContent from './components/BranchesDialogContent';


export type BranchDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function BranchDialog(props: BranchDialogProps) {
    const { closeTo } = props;
    const { customerID, '*': add } = useParams<AdminRouteParams<'customer'>>();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { id: clientID } = useGetCurrentAccount();

    const [ defaultValues, setDefaultValues ] = useState<Branch>();
    const [ branches, setBranches ] = useState<Branch[]>([]);
    const [ loading, setLoading ] = useState(false);

    const { customer, updateCustomer } = useContext(CustomerDetailContext);

    function onEdit(editedBranch: Branch) {
        setBranches(replaceItemById(branches, editedBranch));
        setDefaultValues(undefined);

        pageMessage.success('Branch saved');
    }

    function onCreate(branch: Branch) {
        const updatedBranches = [ ...branches, branch ];

        setBranches(updatedBranches);
        customer && updateCustomer({
            ...customer,
            branches: updatedBranches
        });

        pageMessage.success('Branch created');
    }

    function onDone() {
        navigate(`${closeTo}/branches`);
        setDefaultValues(undefined);
    }

    function onToggleActive(updatedBranch: Branch) {
        const updatedBranches = replaceItemById(branches, updatedBranch);

        setBranches(updatedBranches);

        if (customer) {
            updateCustomer({
                ...customer,
                branches: updatedBranches
            });
        }
    }

    useAsyncEffect(useCallback(async () => {
        if (customerID && !add) {
            setLoading(true);

            try {
                setBranches(await api.customer.branches.getBranches(clientID, customerID));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching the branches', error);
            }

            setLoading(false);
        }
    }, [
        add, clientID, customerID, pageMessage
    ]));

    return (
        <RoutedDialog
            title={defaultValues
                ? 'Edit branch'
                : add
                    ? 'Add branch'
                    : 'Branches'}
            loading={loading}
            classes={{
                paper: styles.root
            }}
            {...props}
        >
            {defaultValues || add ? (
                <AddEditBranchDialogContent
                    defaultValues={defaultValues}
                    onEdit={onEdit}
                    onCreate={onCreate}
                    onDone={onDone}
                />
            ) : (
                <BranchesDialogContent
                    onEditClick={setDefaultValues}
                    onToggleActive={onToggleActive}
                    branches={branches}
                />
            )}
        </RoutedDialog>
    );
}
