import { LoanActivityType } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import { LoanActivity } from './loan-activity-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/loan-activity'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loanActivity)
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan-activity'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loanActivity)
    )))
];

const loanActivity: LoanActivity[] = [
    {
        id: '1',
        date: '2021-07-12T15:00:00.000Z',
        type: LoanActivityType.VIEW,
        userId: '1',
        loanId: '1',
        loanNumber: '1'
    },
    {
        id: '2',
        date: '2021-07-12T15:00:00.000Z',
        type: LoanActivityType.UPDATE,
        userId: '1',
        loanId: '1',
        loanNumber: '1'
    },
    {
        id: '3',
        date: '2021-07-12T15:00:00.000Z',
        type: LoanActivityType.CREATE,
        userId: '1',
        loanId: '1',
        loanNumber: '1'
    }
];
