export enum LoanActivityType {
    VIEW = 'VIEW',
    UPDATE = 'UPDATE',
    CREATE = 'CREATE'
}

export const loanActivityTypeDisplay: Record<LoanActivityType, string> = {
    [LoanActivityType.VIEW]: 'Viewed',
    [LoanActivityType.UPDATE]: 'Updated',
    [LoanActivityType.CREATE]: 'Created'
};
