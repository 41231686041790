import {
    LockAvailability, LockAvailabilityOverride, LockSuspension, ToggleCustomerLockAvailabilityConfig
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


let lockAvailabilityOverrideID = 100;

export const mocks = [
    // Client endpoints
    rest.get(getMockUrl('/client/:clientID/lockAvailability'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailability)
    ))),
    rest.get(getMockUrl('/client/:clientID/lockAvailability/override'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailabilityOverrides)
    ))),
    rest.put(getMockUrl('/client/:clientID/lockAvailability/:lockAvailabilityID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/:clientID/lockAvailability/override/:lockAvailabilityOverrideID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/client/:clientID/lockAvailability/override'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json({
            ...req.body as LockAvailabilityOverride,
            lockAvailabilityOverrideID: lockAvailabilityOverrideID++
        })
    ))),
    rest.delete(getMockUrl('/client/:clientID/lockAvailability/override/:lockAvailabilityOverrideID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(lockAvailabilityOverrides[0])
    ))),
    // Toggle Customer Lock Availability Config
    rest.post(getMockUrl('/client/:clientID/lockAvailability/toggle-customer-config'), (req, res, ctx) => {
        const { clientID } = req.params;
        const toggleConfig = req.body as ToggleCustomerLockAvailabilityConfig;
        customerLockAvailabilityConfig[clientID as string] = toggleConfig.useCustomerConfig;
        return res(
            ctx.status(200),
            ctx.json({
                success: true,
                useCustomerConfig: toggleConfig.useCustomerConfig
            })
        );
    }),

    // Active Customer IDs
    rest.get(getMockUrl('/client/:clientID/lockAvailability/active-customer-config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(activeCustomerIds)
    )),
    rest.get(getMockUrl('/client/:clientID/lock-suspension/current'), (req, res, ctx) => {
        const lockSuspension: LockSuspension = {
            lockSuspensionId: '1',
            customerId: null,
            isSuspended: false,
            isSuspendedUntilNewRateSheet: false,
            suspendedComments: 'System maintenance'
        };
        return res(
            ctx.status(200),
            ctx.json(lockSuspension)
        );
    }),
    rest.post(getMockUrl('/client/:clientID/lock-suspension'), (req, res, ctx) => {
        const newLockSuspension = {
            ...req.body as LockSuspension,
            lockSuspensionId: 'newLockSuspension'
        };
        return res(
            ctx.status(201),
            ctx.json(newLockSuspension)
        );
    }),
    rest.delete(getMockUrl('/client/:clientID/lock-suspension'), (req, res, ctx) => (res(
        ctx.status(204)
    ))),

    // Customer endpoints
    rest.get(getMockUrl('/customer/:customerID/lockAvailability'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailability)
    ))),
    rest.get(getMockUrl('/customer/:customerID/lockAvailability/override'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(lockAvailabilityOverrides)
    ))),
    rest.put(getMockUrl('/customer/:customerID/lockAvailability/:lockAvailabilityID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/lockAvailability/override/:lockAvailabilityOverrideID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/customer/:customerID/lockAvailability/override'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json({
            ...req.body as LockAvailabilityOverride,
            lockAvailabilityOverrideID: lockAvailabilityOverrideID++
        })
    ))),
    rest.delete(getMockUrl('/customer/:customerID/lockAvailability/override/:lockAvailabilityOverrideID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200),
        ctx.json(lockAvailabilityOverrides[0])
    )))
];

const customerLockAvailabilityConfig: Record<string, boolean> = {};

const activeCustomerIds: string[] = [
    '1', '2', '3', '4', '5'
];

const lockAvailability: LockAvailability[] = [
    {
        lockAvailabilityId: '1',
        customerId: null,
        isMondayAvailable: true,
        mondayStart: '09:00',
        mondayEnd: '18:00',
        isTuesdayAvailable: true,
        tuesdayStart: '09:00',
        tuesdayEnd: '18:00',
        isWednesdayAvailable: true,
        wednesdayStart: '09:00',
        wednesdayEnd: '18:00',
        isThursdayAvailable: true,
        thursdayStart: '09:00',
        thursdayEnd: '18:00',
        isFridayAvailable: true,
        fridayStart: '09:00',
        fridayEnd: '18:00',
        isSaturdayAvailable: false,
        saturdayStart: null,
        saturdayEnd: null,
        isSundayAvailable: false,
        sundayStart: null,
        sundayEnd: null
    }
];

const lockAvailabilityOverrides: LockAvailabilityOverride[] = [
    {
        lockAvailabilityOverrideId: '1',
        customerId: null,
        lockAvailabilityDate: '2022-01-17T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Martin Luther King Day'
    },
    {
        lockAvailabilityOverrideId: '10',
        customerId: null,
        lockAvailabilityDate: '2022-12-26T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Christmas Day (observed)'
    },
    {
        lockAvailabilityOverrideId: '2',
        customerId: null,
        lockAvailabilityDate: '2022-01-30T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Presidents Day'
    },
    {
        lockAvailabilityOverrideId: '3',
        customerId: null,
        lockAvailabilityDate: '2022-05-30T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Memorial Day'
    },
    {
        lockAvailabilityOverrideId: '4',
        customerId: null,
        lockAvailabilityDate: '2022-06-20T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Juneteenth (observed)'
    },
    {
        lockAvailabilityOverrideId: '5',
        customerId: null,
        lockAvailabilityDate: '2022-07-04T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Independence Day'
    },
    {
        lockAvailabilityOverrideId: '6',
        customerId: null,
        lockAvailabilityDate: '2022-09-05T00:00:00Z',
        isAvailable: true,
        lockStart: '09:00',
        lockEnd: '18:00',
        description: 'Labor Day'
    },
    {
        lockAvailabilityOverrideId: '7',
        customerId: null,
        lockAvailabilityDate: '2022-10-10T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Columbus Day'
    },
    {
        lockAvailabilityOverrideId: '8',
        customerId: null,
        lockAvailabilityDate: '2022-11-11T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Veterans Day'
    },
    {
        lockAvailabilityOverrideId: '9',
        customerId: null,
        lockAvailabilityDate: '2022-11-24T00:00:00Z',
        isAvailable: false,
        lockStart: null,
        lockEnd: null,
        description: 'Thanksgiving'
    }
];
