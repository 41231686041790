import { injectValues } from '@views/loans/underwriting/underwriting-common';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import styles from './Markdown.module.scss';


interface MarkdownProps {
    children?: string;
    valuesToInject?: Record<string, any>;
}

export function Markdown({ children, valuesToInject }: MarkdownProps) {
    const text = valuesToInject ? injectValues(children, valuesToInject) : children;

    return text ? (
        <ReactMarkdown
            rehypePlugins={[ rehypeRaw ]}
            className={styles.markdown}
        >
            {text}
        </ReactMarkdown>
    ) : null;
}
