import api, { Notification, NotificationType, notificationTypeDisplay } from '@api';
import { TextField } from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    DialogProps, GroupedTimelineDialog, ValidatedDateField, renderEnumOptions
} from '@tsp-ui/core/components';
import {
    replaceItemById, useAsyncEffect, usePageMessage, usePaginatedQueryParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { NotificationCard, NotificationCardProps } from '@views/components/MainNav/components/NotificationsButton/components/NotificationCard';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';

import styles from './NotificationHistoryDialog.module.scss';


type NotificationHistoryDialogProps = Omit<DialogProps, 'title'> &
    Pick<NotificationCardProps, 'onMarkRead' | 'onMarkUnread' | 'onLinkClick'>;

export default function NotificationHistoryDialog({
    onLinkClick, onMarkRead, onMarkUnread, open, ...dialogProps
}: NotificationHistoryDialogProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ notificationResponse, setNotificationResponse ] = useState<PaginatedResponse<Notification>>();
    const [ pageLoading, setPageLoading ] = useState(false);

    const [ date, setDate ] = useState<Date | null>(null);
    const [ type, setType ] = useState<NotificationType | 'all'>('all');
    const [ loanNumber, setLoanNumber ] = useState('');

    const [ debouncedLoanNumber ] = useDebounce(loanNumber, 500);

    const [ params, setPageNumber ] = usePaginatedQueryParams({
        date: date && date.toISOString(),
        type: type === 'all' ? undefined : type,
        loanNumber: debouncedLoanNumber || undefined
    });

    useAsyncEffect(useCallback(async () => {
        if (open) {
            setPageLoading(true);

            try {
                setNotificationResponse(await api.notifications.getAllNotifications(clientId, params, customerId));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching the notifications', error);
            }

            setPageLoading(false);
        }
    }, [
        open, clientId, customerId, params, pageMessage
    ]));

    const renderNotification = useCallback((notification: Notification) => {
        const updateNotificationRead = (isRead: boolean) => (
            setNotificationResponse((notificationResponse) => ({
                ...notificationResponse!,
                data: replaceItemById(notificationResponse!.data, {
                    ...notification,
                    isRead,
                    ...(isRead && {
                        isViewed: true
                    })
                })
            }))
        );

        return (
            <NotificationCard
                className={styles.dialogCard}
                key={notification.id}
                notification={notification}
                formatDate={(date) => format(date, 'h:mm aaa')}
                onLinkClick={onLinkClick}
                onMarkRead={async (notification) => {
                    updateNotificationRead(true);

                    await onMarkRead?.(notification);
                }}
                onMarkUnread={async (notification) => {
                    updateNotificationRead(false);

                    await onMarkUnread?.(notification);
                }}
            />
        );
    }, [
        onLinkClick, onMarkRead, onMarkUnread
    ]);

    return (
        <GroupedTimelineDialog
            open={open}
            title="Notification history"
            paginatedItems={notificationResponse}
            onPageChange={setPageNumber}
            pageLoading={pageLoading}
            dateKey="createdDate"
            renderItem={renderNotification}
            filterForm={(
                <form className={styles.form}>
                    <ValidatedDateField
                        name="date"
                        size="small"
                        label="Notification date"
                        value={date}
                        onValidDateChange={setDate}
                        pickerProps={{
                            disableFuture: true
                        }}
                    />

                    <TextField
                        name="type"
                        size="small"
                        label="Notification type"
                        select
                        value={type}
                        onChange={(event) => setType(event.target.value as NotificationType)}
                    >
                        {renderEnumOptions(notificationTypeDisplay, true)}
                    </TextField>

                    <TextField
                        name="loanNumber"
                        size="small"
                        label="Loan number"
                        value={loanNumber}
                        onChange={(event) => setLoanNumber(event.target.value)}
                    />
                </form>
            )}
            {...dialogProps}
        />
    );
}

