import {
    BulkCommitmentDetails, BulkCommitmentLoan, CommitmentLoanStatus, commitmentStatusDisplay
} from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    DialogContent, IconButton, MenuItem, Pagination, TextField, Tooltip, Typography, useTheme
} from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogProps, renderEnumOptions
} from '@tsp-ui/core/components';
import { useParams } from '@tsp-ui/core/utils';
import { getGradient } from '@utils/color-utils';
import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import { BulkCommitmentLoanCard } from '@views/tracking/bulk-commitment/BulkCommitmentTrackingDetailPage';
import { ProductNoteRateDisplay } from '@views/tracking/bulk-commitment/ProductNoteRateDisplay';
import clsx from 'clsx';
import {
    useContext, useEffect, useMemo, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import styles from './BulkCommitmentLoansDialog.module.scss';


interface BulkCommitmentLoansDialogParams {
    commitmentID: string;
}

export default function BulkCommitmentLoansDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { commitmentID } = useParams<BulkCommitmentLoansDialogParams>();
    const { bulkCommitments, loading } = useContext(BulkCommitmentsContext);

    const searchParams = new URLSearchParams(useLocation().search);
    const [ tierSearchParam, loanStatusSearchParam ] = [ searchParams.get('tier'), searchParams.get('loanStatus') ];

    const commitment = bulkCommitments?.find(({ id }) => id === commitmentID) as BulkCommitmentDetails;
    const { pricingTiers } = commitment || {};

    const { primary: { main: primaryColor } } = useTheme().palette;
    const colors: tinycolor.Instance[] | null = useMemo(() => (
        getGradient(primaryColor, 3)
    ), [ primaryColor ]);

    const [ groupBy, setGroupBy ] = useState<'tier' | 'status' | string>('tier');

    const [ tier, setTier ] = useState<string>(tierSearchParam || 'all');
    const [ tierExpansionState, setTierExpansionState ] = useState<{ [key: string]: boolean }>();

    useEffect(() => {
        setTier(tierSearchParam || 'all');

        if (tierSearchParam) {
            setGroupBy('tier');
        }
    }, [ tierSearchParam ]);

    const [ loanStatus, setLoanStatus ] = useState<string>(loanStatusSearchParam || 'all');
    const [ loanStatusExpansionState, setLoanStatusExpansionState ] = useState<{ [key: string]: boolean }>();

    useEffect(() => {
        setLoanStatus(loanStatusSearchParam || 'all');

        if (loanStatusSearchParam) {
            setGroupBy('status');
        }
    }, [ loanStatusSearchParam ]);

    useEffect(() => {
        if (pricingTiers) {
            setTierExpansionState(Object.fromEntries(
                pricingTiers.map(({ productId, noteRate, price }) => (
                    [ `${productId}${noteRate}${price}`, false ]
                ))
            ));
        }
    }, [ pricingTiers ]);

    const statusOptions = Object.fromEntries(
        Object.entries(commitmentStatusDisplay)
            .filter(([ key ]) => key !== CommitmentLoanStatus.UNDELIVERED)
    );

    const tierNodes = commitment?.pricingTiers.filter(({ productId, noteRate, price }) => (
        tier === 'all' || tier === `${productId}${noteRate}${price}`
    )).map(({
        productId, noteRate, price, loans
    }) => {
        const key = `${productId}${noteRate}${price}`;
        const isExpanded = tierExpansionState?.[key] || tier !== 'all';
        const disabled = tier !== 'all';

        return ( // TODO post-demo refactor to use ExpandableHeader
            <>
                <div className={styles.sectionLabel}>
                    <div>
                        <Tooltip title={`Show ${isExpanded ? 'less' : 'more'}`}>
                            <IconButton
                                edge="start"
                                size="small"
                                className={styles.expandButton}
                                disabled={disabled}
                                onClick={() => setTierExpansionState({
                                    ...tierExpansionState,
                                    [key]: !isExpanded
                                })}
                            >
                                {isExpanded ? (
                                    <ExpandLess
                                        color={disabled ? 'disabled' : 'secondary'}
                                        fontSize="small"
                                    />
                                ) : (
                                    <ExpandMore
                                        color={disabled ? 'disabled' : 'secondary'}
                                        fontSize="small"
                                    />
                                )}
                            </IconButton>
                        </Tooltip>

                        <Typography
                            variant="body2"
                            component="span"
                        >
                            <ProductNoteRateDisplay
                                productId={productId}
                                noteRate={noteRate}
                            />
                        </Typography>
                    </div>

                    <Typography
                        variant="body2"
                        component="span"
                    >
                        {/* eslint-disable-next-line react/jsx-newline */}
                        {loans.length} loan{loans.length === 1 ? '' : 's'} @ {price.toFixed(5)}
                    </Typography>
                </div>

                {isExpanded && loans.map((loan) => (
                    <BulkCommitmentLoanCard
                        loan={loan}
                        colors={colors || []}
                        key={loan.loanNumber}
                    />
                ))}
            </>
        );
    });

    const allLoans = useMemo(() => pricingTiers?.flatMap(({ loans }) => loans), [ pricingTiers ]);

    const loansByStatus = useMemo(() => allLoans?.reduce((loansByStatus, loan) => {
        loansByStatus[loan.status as CommitmentLoanStatus.DELIVERED
            | CommitmentLoanStatus.PURCHASED | CommitmentLoanStatus.SOLD].push(loan);

        return loansByStatus;
    }, {
        [CommitmentLoanStatus.DELIVERED]: [] as BulkCommitmentLoan[],
        [CommitmentLoanStatus.PURCHASED]: [] as BulkCommitmentLoan[],
        [CommitmentLoanStatus.SOLD]: [] as BulkCommitmentLoan[]
    }), [ allLoans ]);

    const loanStatusNodes = Object.entries(loansByStatus).filter(([ status ]) => (
        loanStatus === 'all' || loanStatus === status
    )).map(([ status, loans ])  => {
        const key = status;
        const isExpanded = loanStatusExpansionState?.[key] || loanStatus !== 'all';
        const disabled = loanStatus !== 'all';

        return ( // TODO post-demo refactor to use ExpandableHeader
            <>
                <div className={styles.sectionLabel}>
                    <div>
                        <Tooltip title={`Show ${isExpanded ? 'less' : 'more'}`}>
                            <IconButton
                                edge="start"
                                size="small"
                                className={styles.expandButton}
                                disabled={disabled}
                                onClick={() => setLoanStatusExpansionState({
                                    ...loanStatusExpansionState,
                                    [key]: !isExpanded
                                })}
                            >
                                {isExpanded ? (
                                    <ExpandLess
                                        color={disabled ? 'disabled' : 'secondary'}
                                        fontSize="small"
                                    />
                                ) : (
                                    <ExpandMore
                                        color={disabled ? 'disabled' : 'secondary'}
                                        fontSize="small"
                                    />
                                )}
                            </IconButton>
                        </Tooltip>

                        <Typography
                            variant="body2"
                            component="span"
                        >
                            {commitmentStatusDisplay[status as keyof typeof commitmentStatusDisplay]}
                        </Typography>
                    </div>

                    <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                    >
                        {/* eslint-disable-next-line react/jsx-newline */}
                        {loans.length} loan{loans.length === 1 ? '' : 's'}
                    </Typography>
                </div>

                {isExpanded && loans.map((loan) => (
                    <BulkCommitmentLoanCard
                        loan={loan}
                        colors={colors || []}
                        key={loan.loanNumber}
                    />
                ))}
            </>
        );
    });

    return (
        <RoutedDialog
            {...props}
            title="Allocated loans"
            maxWidth="sm"
            fullWidth
            loading={loading}
        >
            <DialogContent
                className={clsx(styles.filters, {
                    [styles.scrolledDown]: true
                })}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    Filters
                </Typography>

                <form className={styles.form}>
                    <TextField
                        name="groupBy"
                        size="small"
                        label="Group by"
                        select
                        value={groupBy}
                        onChange={(event) => setGroupBy(event.target.value)}
                    >
                        <MenuItem value="tier">
                            Pricing tier
                        </MenuItem>

                        <MenuItem value="status">
                            Loan status
                        </MenuItem>
                    </TextField>

                    {groupBy === 'tier' ? (
                        <TextField
                            name="tier"
                            size="small"
                            label="Pricing tier"
                            select
                            value={tier}
                            onChange={(event) => setTier(event.target.value)}
                        >
                            <MenuItem value="all">
                                -- All --
                            </MenuItem>

                            {commitment.pricingTiers.map(({ productId, noteRate, price }) => (
                                <MenuItem value={`${productId}${noteRate}${price}`}>
                                    <ProductNoteRateDisplay
                                        productId={productId}
                                        noteRate={noteRate}
                                    />

                                    @

                                    {price.toFixed(5)}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <TextField
                            name="status"
                            size="small"
                            label="Loan status"
                            select
                            value={loanStatus}
                            onChange={(event) => setLoanStatus(event.target.value)}
                        >
                            {renderEnumOptions(statusOptions, true)}
                        </TextField>
                    )}
                </form>
            </DialogContent>

            <DialogContent
                className={styles.mainContent}
            >
                <div className={styles.root}>
                    {groupBy === 'tier' ? tierNodes : loanStatusNodes}
                </div>
            </DialogContent>

            <DialogActions
                className={styles.dialogActions}
                loading={false}
            >
                <Pagination
                    count={1}
                    page={1}
                    onChange={() => {}}
                    color="primary"
                />
            </DialogActions>
        </RoutedDialog>
    );
}
