import api, { CustomerWireInfo } from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useCallback, useState } from 'react';

import WireInstructionsAddEditContent from './WireInstructionsAddEditContent';
import styles from './WireInstructionsDialog.module.scss';
import WireInstructionsListContent from './WireInstructionsListContent';


export default function WireInstructionsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const { id: clientID } = useGetCurrentAccount();

    const [ wireInstructionsToEdit, setWireInstructionsToEdit ] = useState<CustomerWireInfo | {}>();
    const [ wireInstructionsArray, setWireInstructionsArray ] = useState<CustomerWireInfo[]>([]);
    const [ loading, setLoading ] = useState(false);

    const pageMessage = usePageMessage();

    const onApprove = (id: string) => {
        setWireInstructionsArray(wireInstructionsArray.map(wireInfo => (id === wireInfo.id ? {
            ...wireInfo,
            isApproved: true
        } : wireInfo)));
    };

    const onToggleActive = (id: string) => {
        setWireInstructionsArray(wireInstructionsArray.map(wireInfo => (id === wireInfo.id ? {
            ...wireInfo,
            isActive: !wireInfo.isActive
        } : wireInfo)));
    };

    const onAdd = (res: CustomerWireInfo) => {
        const newArray = [ ...wireInstructionsArray ];
        newArray.push(res);
        setWireInstructionsArray(newArray);
    };

    const onEdit = (formValues: CustomerWireInfo) => {
        setWireInstructionsArray(wireInstructionsArray.map(
            wireInfo => (formValues.id === wireInfo.id ? formValues : wireInfo)
        ));
    };

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setWireInstructionsArray(await api.customer.wireInfo.getWireInfo(clientID, customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the wire instructions', error);
        }

        setLoading(false);
    }, [
        pageMessage, customerID, clientID
    ]));

    return (
        <RoutedDialog
            title="Wire instructions"
            {...props}
            maxWidth={false}
            loading={loading}
            classes={{
                paper: wireInstructionsToEdit ? undefined : styles.listRoot
            }}
        >
            {wireInstructionsToEdit ? (
                <WireInstructionsAddEditContent
                    defaultValues={wireInstructionsToEdit}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    setWireInstructionsToEdit={setWireInstructionsToEdit}
                />
            ) : (
                <WireInstructionsListContent
                    wireInstructionsArray={wireInstructionsArray}
                    onApprove={onApprove}
                    onToggleActive={onToggleActive}
                    setWireInstructionsToEdit={setWireInstructionsToEdit}
                />
            )}
        </RoutedDialog>
    );
}
