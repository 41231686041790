import api, {
    LOSLoanStatus, LoanActivity, LoanHighlight, LoanStatusConfig,
    LoanSummary,
    PermissionType, RegisteredLoan,
    UserType
} from '@api';
import { TextField } from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import { Button, RoutedDialogManager, usePageMessage } from '@tsp-ui/core/components';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHandlePromiseSettledResult, withAuth } from '@utils';
import ViewportFill from '@views/components/ViewportFill';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import { LoanSectionBase } from '@views/loans/components/LoanSectionBase';
import RegisteredLoanCard from '@views/loans/components/RegisteredLoanCard';
import { useGetLockDetails } from '@views/product-pricing/ProductAndPricingPage';
import {
    ReactNode, createContext, useCallback, useEffect, useMemo, useState
} from 'react';
import { useDebounce } from 'use-debounce';

import Page from '../components/Page';

import styles from './LoansPage.module.scss';
import CustomerAutocomplete from './components/CustomerAutoComplete';
import LoanDataDialog from './components/LoanDataDialog';
import { LoanHighlights } from './components/LoanHighlights';
import LoanNumberAutoComplete from './components/LoanNumberAutoComplete';
import { RecentLoanActivity } from './components/RecentLoans';


const LOANS_PER_PAGE = 20;

const { CLIENT } = UserType;

export const LoansContext = createContext<{
    loanStatusConfigs: LoanStatusConfig[];
    losLoanStatuses: LOSLoanStatus[];
    isLockDeskOpen?: boolean;
        }>({
            loanStatusConfigs: [],
            losLoanStatuses: [],
            isLockDeskOpen: true
        });

/**
 * Renders the loan pipeline page
 *
 * @constructor
 */
export default function LoansPage() {
    const { id: clientId, customerId, accountUserType } = useGetCurrentAccount();

    const [ loanSummaries, setLoanSummaries ] = useState<LoanSummary[]>([]);
    const [ loanStatusConfigs, setLoanStatusConfigs ] = useState<LoanStatusConfig[]>([]);
    const [ losLoanStatuses, setLosLoanStatuses ] = useState<LOSLoanStatus[]>([]);
    const [ loanActivity, setLoanActivity ] = useState<LoanActivity[]>([]);
    const [ loanHighlights, setLoanHighlights ] = useState<LoanHighlight[]>([]);
    const [ loading, setLoading ] = useState(true);

    const [ loanNumberSearch, setLoanNumberSearch ] = useState('');
    const [ borrowerNameSearch, setBorrowerNameSearch ] = useState('');
    const [ customerIdSearch, setCustomerIdSearch ] = useState('');

    const fetchFilteredLoans = useCallback(async () => {
        try {
            const summaries = await api.loans.getLoansSummary(clientId, customerId, {
                loanNumber: loanNumberSearch.length > 2 ? loanNumberSearch : null,
                borrowerName: borrowerNameSearch.length > 2 ? borrowerNameSearch : null,
                customerId: customerIdSearch
            });
            setLoanSummaries(summaries);
        } catch (error) {
            console.error('Error fetching filtered loans:', error);
        }
    }, [
        clientId, customerId, loanNumberSearch, borrowerNameSearch, customerIdSearch
    ]);

    const [ debouncedFetchFilteredLoans ] = useDebounce(fetchFilteredLoans, 300);

    useEffect(() => {
        debouncedFetchFilteredLoans();
    }, [ debouncedFetchFilteredLoans ]);

    const activeFilterCount = useMemo(() => [
        loanNumberSearch, borrowerNameSearch, customerIdSearch
    ].filter(Boolean).length, [
        loanNumberSearch, borrowerNameSearch, customerIdSearch
    ]);

    function handleClearFilters() {
        setLoanNumberSearch('');
        setBorrowerNameSearch('');
        setCustomerIdSearch('');
    }

    const handlePromiseSettledResult = useHandlePromiseSettledResult();

    useAsyncEffect(useCallback(async () => {
        const [
            loanStatusConfigsResult, losLoanStatusesResult, loanActivityResult,
            loanHighlightsResult
        ] = await Promise.allSettled([
            api.loanStatus.getLoanStatusConfigs(clientId),
            api.loanStatus.getLOSLoanStatuses(clientId),
            api.loanActivity.getLoanActivity(clientId, customerId),
            api.loans.getLoanHighlights(clientId)
        ]);

        handlePromiseSettledResult(loanStatusConfigsResult, setLoanStatusConfigs, 'An error occurred while fetching loan status configs');
        handlePromiseSettledResult(losLoanStatusesResult, setLosLoanStatuses, 'An error occurred while fetching LOS loan statuses');
        handlePromiseSettledResult(loanActivityResult, setLoanActivity, 'An error occurred while fetching recent loan activity');
        handlePromiseSettledResult(loanHighlightsResult, setLoanHighlights, 'An error occurred while fetching loan highlights');

        setLoading(false);
    }, [
        clientId, customerId, handlePromiseSettledResult
    ]));

    const orderedConfigs = loanStatusConfigs.sort((a, b) => a.displayOrder - b.displayOrder);

    const otherLoansCount = loanSummaries.find(s => s.statusConfigId === null)?.loanCount;

    const { isLockDeskOpen, lockDetailsLoading } = useGetLockDetails();

    const loansContextValue = {
        loanStatusConfigs: loanStatusConfigs || [],
        losLoanStatuses: losLoanStatuses || [],
        isLockDeskOpen
    };

    return (
        <LoansContext.Provider value={loansContextValue}>
            <Page
                header="Loan Pipeline"
                loading={loading || lockDetailsLoading}
                className={styles.main}
                headerActions={(
                    <div className={styles.filterContainer}>
                        {activeFilterCount > 0 && (
                            <Button
                                onClick={handleClearFilters}
                            >
                                Clear filters
                            </Button>
                        )}

                        <LoanNumberAutoComplete
                            className={styles.filterField}
                            loanNumberSearch={loanNumberSearch}
                            setLoanNumberSearch={setLoanNumberSearch}
                        />

                        <TextField
                            className={styles.filterField}
                            label="Filter by borrower name"
                            value={borrowerNameSearch}
                            onChange={(event) => setBorrowerNameSearch(event.target.value)}
                            variant="filled"
                            size="small"
                        />

                        {accountUserType === CLIENT && (
                            <CustomerAutocomplete
                                className={styles.filterField}
                                customerIdSearch={customerIdSearch}
                                setCustomerIdSearch={setCustomerIdSearch}
                            />
                        )}
                    </div>
                )}
            >
                <ViewportFill className={styles.root}>
                    <div className={styles.sections}>
                        {orderedConfigs.map(loanStatusGroup => {
                            const summary = loanSummaries.find(s => s.statusConfigId === loanStatusGroup.id);

                            return (
                                <LoansSection
                                    key={loanStatusGroup.id}
                                    title={loanStatusGroup.title}
                                    statusConfigId={loanStatusGroup.id}
                                    loanCount={summary?.loanCount || 0}
                                    loanNumberSearch={loanNumberSearch}
                                    borrowerNameSearch={borrowerNameSearch}
                                    customerIdSearch={customerIdSearch}
                                />
                            );
                        })}

                        {otherLoansCount && otherLoansCount > 0 && (
                            <LoansSection
                                title="Other Loans"
                                statusConfigId={null}
                                loanCount={otherLoansCount || 0}
                                loanNumberSearch={loanNumberSearch}
                                borrowerNameSearch={borrowerNameSearch}
                                customerIdSearch={customerIdSearch}
                            />
                        )}
                    </div>

                    <div className={styles.sidebar}>
                        <LoanHighlights loanHighlights={loanHighlights} />

                        <RecentLoanActivity loanActivity={loanActivity} />
                    </div>
                </ViewportFill>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </LoansContext.Provider>
    );
}

const dialogRoutes = {
    ':loanID/loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    ':loanID/loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true)
};

interface LoansSectionProps {
    title: ReactNode;
    statusConfigId: number | null;
    loanCount: number | undefined;
    loanNumberSearch: string;
    borrowerNameSearch: string;
    customerIdSearch: string;
}

function LoansSection({
    title, statusConfigId, loanCount, loanNumberSearch, borrowerNameSearch, customerIdSearch
}: LoansSectionProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ pageNumber, setPageNumber ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ isExpanded, setIsExpanded ] = useState(false);

    const [ loans, setLoans ] = useState<PaginatedResponse<RegisteredLoan>>({
        data: [],
        totalPages: 0,
        totalRecords: 0,
        pageNumber: 1,
        pageSize: LOANS_PER_PAGE
    });

    useAsyncEffect(useCallback(async () => {
        if (!isExpanded) {
            return;
        }

        setLoading(true);
        try {
            const fetchedLoans = await api.loans.getRegisteredLoans(clientId, customerId, {
                loanNumber: loanNumberSearch.length > 2 ? loanNumberSearch : null,
                borrowerName: borrowerNameSearch.length > 2 ? borrowerNameSearch : null,
                customerId: customerIdSearch || null,
                statusConfigId,
                pageSize: LOANS_PER_PAGE,
                pageNumber
            });
            setLoans(fetchedLoans);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loans', error);
        } finally {
            setLoading(false);
        }
    }, [
        borrowerNameSearch, clientId, customerId, customerIdSearch, isExpanded,
        loanNumberSearch, pageMessage, pageNumber, statusConfigId
    ]));

    return (
        <LoanSectionBase
            paginatedResponse={loans}
            setPageNumber={setPageNumber}
            defaultExpand={false}
            disableExpand={false}
            onExpandToggle={setIsExpanded}
            title={title}
            loading={loading}
            loanCount={loanCount}
            noResultsMessage={customerIdSearch
                ? 'This customer currently has no loans in this status'
                : 'There are currently no loans in this status'}
        >
            {loans.data.map(loan => (
                <RegisteredLoanCard
                    key={loan.id}
                    loan={loan}
                    setLoans={setLoans}
                />
            ))}
        </LoanSectionBase>
    );
}
