import api, { BatchPricingResults, LoanPricingResult, WebSocketEventType } from '@api';
import { ErrorOutline } from '@mui/icons-material';
import {
    Button, IconButton, Popover, Tooltip
} from '@mui/material';
import { PaginatedResponse, replaceItemByKey } from '@tsp-ui/core';
import {
    DateTypography, LabeledValue, PaperSaveLoader, usePageMessage
} from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './PricingExpiredButton.module.scss';


interface PricingExpiredButtonProps {
    expirationDate: string;
    loanId: string;
    updateLoanPricingResults?: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    updateFloatedLoans?: () => void;
}

export default function PricingExpiredButton({
    expirationDate, loanId, updateLoanPricingResults, updateFloatedLoans
}: PricingExpiredButtonProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const [ repriceLoading, setRepriceLoading ] = useState(false);

    async function onReprice() {
        setRepriceLoading(true);

        const unsubFinished: () => void = api.webSocket.subscribe(
            WebSocketEventType.PRICING_COMPLETE, (batchResult: BatchPricingResults) => {
                const newPricingResult = batchResult.results.find(r => r.loanId === loanId);

                if (newPricingResult) {
                    updateLoanPricingResults?.(
                        (loanPricingResults) => ({
                            ...loanPricingResults,
                            data: replaceItemByKey(loanPricingResults.data, newPricingResult, 'loanId')
                        })
                    );

                    updateFloatedLoans?.();
                    pageMessage.success('Loan repriced');
                    return unsubFinished;
                }
            }
        );

        try {
            await api.pricing.repriceLoan(clientId, loanId, customerId);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while repricing the loan', error);
        } finally {
            setRepriceLoading(false);
        }
    }

    return (
        <>
            <Tooltip title="Pricing has expired. Click for options.">
                <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
                    <ErrorOutline color="error" />
                </IconButton>
            </Tooltip>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.expirationOptions
                }}
            >
                <LabeledValue
                    label="Pricing expired on"
                    value={(
                        <DateTypography
                            date={expirationDate}
                            component="span"
                            variant="body2"
                            time
                        />
                    )}
                    variant="vertical"
                />

                <Button
                    onClick={onReprice}
                    variant="contained"
                    disabled={repriceLoading}
                >
                    Reprice
                </Button>

                <PaperSaveLoader loading={repriceLoading} />
            </Popover>
        </>
    );
}
