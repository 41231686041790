import { CustomQuestionType, UnderwritingQuestion } from '@api';
import { Markdown } from '@views/components/Markdown';
import { ReactNode, useContext, useEffect } from 'react';

import { UnderWritingStepContext } from '../../UnderwritingStepSection';

import { AusTable } from './components/AUSTable';
import AssetAccountsSection from './components/AssetAccountsSection';
import LiabilitiesSection from './components/LiabilitiesSection';


export function CustomQuestionCard({ question }: { question: UnderwritingQuestion }) {
    const {
        setReadyToSubmit
    } = useContext(UnderWritingStepContext);

    useEffect(() => { // this is for the default rendering of the question, not when a user clicks next
        if (question.next?.action === 'END') {
            setReadyToSubmit(true);
        }
    }, [ question, setReadyToSubmit ]);

    return (
        <>
            <Markdown valuesToInject={question.data}>
                {question.text}
            </Markdown>

            {customQuestionsMap[question.customQuesionType!]}
        </>
    );
}

const customQuestionsMap: Record<CustomQuestionType, ReactNode> = {
    AUS_DETAILS: <AusTable />,
    LIABILITIES: <LiabilitiesSection />,
    ASSET_ACCOUNTS: <AssetAccountsSection />
};
