import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import { useContext } from 'react';


interface ProductNoteRateDisplayProps {
    productId: string | undefined;
    noteRate: number | undefined;
}

export function ProductNoteRateDisplay({ productId, noteRate }: ProductNoteRateDisplayProps) {
    const { products } = useContext(BulkCommitmentsContext);

    const productLabel = productId
        ? products?.find(({ id }) => id === productId)?.productCode
        : 'All Products';

    return (
        <>
            {productLabel}

            /

            {noteRate ? `${noteRate.toFixed(3)}%` : 'All note rates'}
        </>
    );
}
