import { Link as MuiLink, Paper, Typography } from '@mui/material';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './AlertCard.module.scss';


interface AlertCardProps {
    loanID: string;
    loanNumber: string;
    date: string;
    children?: ReactNode;
}

export function AlertCard({
    loanID, loanNumber, date, children
}: AlertCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.alertCard}
        >
            <Typography color="textSecondary">
                Loan #

                <MuiLink
                    component={Link}
                    to={`${loanID}/detail/all`}
                >
                    {loanNumber}
                </MuiLink>
            </Typography>

            {children ? children : (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {formatDistanceToNowStrict(parseISO(date))} ago
                </Typography>
            )}
        </Paper>
    );
}
