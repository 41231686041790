import {
    BulkCommitmentDetails,
    BulkCommitmentLoan,
    BulkCommitmentPricingTierDetails,
    CommitmentLoanStatus,
    commitmentStatusDisplay
} from '@api';
import { HourglassBottom, HourglassTop, Lock } from '@mui/icons-material';
import {
    Button, LinearProgress, Link as MuiLink, Paper, Tooltip, Typography, useTheme
} from '@mui/material';
import {
    CurrencyTypography, FilledSection, IconTypography, RoutedDialogManager
} from '@tsp-ui/core/components';
import {
    createDate,
    formatCurrencyAbbreviation,
    isToday,
    useParams
} from '@tsp-ui/core/utils';
import { getGradient, transparentize } from '@utils/color-utils';
import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import BulkCommitmentDialog from '@views/admin/bulk-commitment/components/BulkCommitmentDialog';
import Page from '@views/components/Page';
import {
    AllocationHistoryChart,
    AllocationHistoryChartData,
    AllocationHistoryChartProps
} from '@views/tracking/bulk-commitment/AllocationHistoryChart';
import {
    CardLinearProgress,
    CommitmentTrackingCard
} from '@views/tracking/bulk-commitment/BulkCommitmentTrackingPage';
import { ProductNoteRateDisplay } from '@views/tracking/bulk-commitment/ProductNoteRateDisplay';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Tooltip as ChartTooltip,
    Colors,
    DoughnutController,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale
// @ts-ignore
} from 'chart.js';
import clsx from 'clsx';
import {
    format, formatDistanceToNowStrict, isBefore, parseISO
} from 'date-fns';
import {
    useContext, useEffect, useMemo, useState
} from 'react';
// @ts-ignore
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import BulkCommitmentLoansDialog from './BulkCommitmentLoansDialog';
import styles from './BulkCommitmentTrackingDetailPage.module.scss';
import PairOffDialog from './PairOffDialog';


ChartJS.register(
    ArcElement,
    Colors,
    DoughnutController,
    LineController,
    CategoryScale,
    LinearScale,
    Legend,
    PointElement,
    LineElement,
    ChartTooltip,
    TimeScale
);

const {
    SOLD, UNDELIVERED, DELIVERED, PURCHASED
} = CommitmentLoanStatus;

interface BulkCommitmentTrackingDetailPageParams {
    commitmentID: string;
}

export default function BulkCommitmentTrackingDetailPage() {
    const { commitmentID } = useParams<BulkCommitmentTrackingDetailPageParams>();

    const { bulkCommitments, loading } = useContext(BulkCommitmentsContext);
    // TODO post-demo
    const commitment = bulkCommitments?.find(({ id }) => id === commitmentID) as BulkCommitmentDetails || undefined;

    const { primary: { main: primaryColor }, action: { active: actionColor } } = useTheme().palette;
    const colors: tinycolor.Instance[] | null = useMemo(() => (
        getGradient(primaryColor, 3)
    ), [ primaryColor ]);

    const { pricingTiers, tradeAmount } = commitment || {};

    const allLoans = useMemo(() => pricingTiers?.flatMap(({ loans }) => loans), [ pricingTiers ]);

    const totalsByStatus = useMemo(() => allLoans?.reduce((loansByStatus, loan) => {
        loansByStatus[loan.status] += loan.loanAmount;

        return loansByStatus;
    }, {
        [DELIVERED]: 0,
        [PURCHASED]: 0,
        [SOLD]: 0,
        [UNDELIVERED]: 0
    }), [ allLoans ]);

    const [ tierExpansionState, setTierExpansionState ] = useState<{ [key: string]: boolean }>();

    useEffect(() => {
        if (pricingTiers) {
            setTierExpansionState(Object.fromEntries(
                pricingTiers.map(({ productId, noteRate, price }) => (
                    [ `${productId}${noteRate}${price}`, false ]
                ))
            ));
        }
    }, [ pricingTiers ]);

    const defaultCurrentData = useMemo<AllocationHistoryChartData>(() => {
        const delivered = (totalsByStatus && totalsByStatus[DELIVERED] + totalsByStatus[PURCHASED]
            + totalsByStatus[SOLD]) || 0;

        return ({
            lockDate: new Date(),
            undelivered: (tradeAmount || 0) - delivered,
            delivered,
            purchased: (totalsByStatus && totalsByStatus[PURCHASED] + totalsByStatus[SOLD]) || 0,
            sold: (totalsByStatus && totalsByStatus[SOLD]) || 0
        });
    }, [ totalsByStatus, tradeAmount ]);

    const [
        {
            lockDate: currentDataDate, delivered, purchased, sold, undelivered
        },
        setCurrentData
    ] = useState<typeof defaultCurrentData>(defaultCurrentData);

    useEffect(() => {
        setCurrentData(defaultCurrentData);
    }, [ defaultCurrentData ]);

    const chartData = useMemo<AllocationHistoryChartProps['data'] | undefined>(() => {
        let delivered = 0;
        let purchased = 0;
        let sold = 0;

        if (allLoans) {
            allLoans.sort((a, b) => parseISO(a.lockDate).getTime() - parseISO(b.lockDate).getTime());
        }

        const dataItems: AllocationHistoryChartProps['data'] | undefined = allLoans && (
            allLoans.flatMap((loan, index) => {
                const dataItems = [];

                delivered += loan.loanAmount;
                dataItems.push({
                    lockDate: createDate(index, parseISO(loan.lockDate)),
                    delivered,
                    purchased,
                    sold,
                    undelivered: (tradeAmount || 0) - delivered
                });

                if (loan.status === PURCHASED || loan.status === SOLD) {
                    purchased += loan.loanAmount;

                    dataItems.push({
                        lockDate: createDate(index + 1, parseISO(loan.lockDate)),
                        delivered,
                        purchased,
                        sold,
                        undelivered: (tradeAmount || 0) - delivered
                    });
                }

                if (loan.status === SOLD) {
                    sold += loan.loanAmount;
                    dataItems.push({
                        lockDate: createDate(index + 2, parseISO(loan.lockDate)),
                        delivered,
                        purchased,
                        sold,
                        undelivered: (tradeAmount || 0) - delivered
                    });
                }

                return dataItems;
            })
        );

        dataItems?.sort((a, b) => a.lockDate.getTime() - b.lockDate.getTime());

        const itemSet = new Set<string>();
        return dataItems?.filter(item => {
            const key = `${item.lockDate.toString()}`;
            const has = itemSet.has(key);
            itemSet.add(key);

            return !has;
        });
    }, [ allLoans, tradeAmount ]);

    const colorz = {
        undeliveredColor: actionColor,
        deliveredColor: colors?.[0].toHexString() || '',
        purchasedColor: colors?.[1].toHexString() || '',
        soldColor: colors?.[2].toHexString() || ''
    };

    const {
        undeliveredColor,
        deliveredColor,
        purchasedColor,
        soldColor
    } = colorz;

    const multiPieOptions = useMemo<ChartJS<'doughnut'>['options']>(() => ({
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            animateRotate: false,
            animateScale: false,
            duration: 0
        },
        cutout: '0%',
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        }
    }), []);

    const multiPieData = useMemo<ChartJS<'doughnut'>['data']>(() => ({
        datasets: [
            {
                backgroundColor: [ undeliveredColor, deliveredColor ],
                data: [ (tradeAmount || 0) - delivered, delivered ],
                borderRadius: 4
            },
            {
                backgroundColor: [ transparentize(purchasedColor, 0.05), purchasedColor ],
                data: [ (tradeAmount || 0) - purchased, purchased ],
                borderRadius: 4
            },
            {
                backgroundColor: [ transparentize(soldColor, 0.05), soldColor ],
                data: [ (tradeAmount || 0) - sold, sold ],
                borderRadius: 4
            }
        ]
    }), [
        delivered, deliveredColor, purchased, purchasedColor, sold, soldColor,
        tradeAmount, undeliveredColor
    ]);

    return (
        <Page
            header="Bulk Commitment Details"
            loading={loading}
            headerActions={(
                <>
                    <Button
                        component={Link}
                        to="pair-offs"
                        disabled={!allLoans?.length}
                    >
                        View pair-offs
                    </Button>

                    <Button
                        component={Link}
                        to="loans"
                        disabled={!allLoans?.length}
                    >
                        View allocated loans ({allLoans?.length || 0})
                    </Button>

                    <Button
                        component={Link}
                        to="edit"
                        variant="contained"
                    >
                        Edit commitment details
                    </Button>
                </>
            )}
            breadcrumbs={[
                'Bulk Commitment Tracking',
                commitmentID
            ]}
        >
            <div className={styles.root}>
                {commitment && (
                    <div className={styles.left}>
                        <CommitmentTrackingCard
                            hideTiersButton
                            commitment={commitment}
                        />

                        {commitment.pricingTiers
                            .map((tier) => {
                                const key = `${tier.productId}${tier.noteRate}${tier.price}`;
                                const isExpanded = tierExpansionState?.[key];

                                return (
                                    <PricingTierTrackingCard
                                        key={key}
                                        tier={tier}
                                        isExpanded={isExpanded}
                                        onExpandClick={() => setTierExpansionState({
                                            ...tierExpansionState,
                                            [key]: !isExpanded
                                        })}
                                        colors={colors}
                                    />
                                );
                            })}
                    </div>
                )}

                <FilledSection
                    className={styles.allocationSection}
                    header={(
                        <>
                            Trade Details

                            <Typography variant="body2">
                                {isToday(currentDataDate.toISOString())
                                    ? 'As of now'
                                    : `As of ${format(currentDataDate, 'MMM dd, yyyy')}`}
                            </Typography>
                        </>
                    )}
                >
                    <div className={styles.chartContainer}>
                        <Paper
                            elevation={0}
                            className={styles.chartPaper}
                        >
                            <Doughnut
                                data={multiPieData}
                                options={multiPieOptions}
                            />
                        </Paper>

                        <div className={styles.legend}>
                            <LegendItem
                                color={actionColor}
                                label={commitmentStatusDisplay[UNDELIVERED]}
                                value={undelivered ? undelivered : '--'}
                                percentage={!commitment ? '' : undelivered && (undelivered / commitment?.tradeAmount)}
                            />

                            <LegendItem
                                color={colors?.[0].toHexString()}
                                label={commitmentStatusDisplay[DELIVERED]}
                                value={delivered ? delivered : '--'}
                                percentage={!commitment ? '' : delivered && (delivered / commitment?.tradeAmount)}
                                to={`loans?loanStatus=${DELIVERED}`}
                                toButtonLabel={`view ${commitmentStatusDisplay[DELIVERED]} loans`}
                            />

                            <LegendItem
                                color={colors?.[1].toHexString()}
                                label={commitmentStatusDisplay[PURCHASED]}
                                value={purchased ? purchased : '--'}
                                percentage={!commitment ? '' : purchased && (purchased / commitment?.tradeAmount)}
                                to={`loans?loanStatus=${PURCHASED}`}
                                toButtonLabel={`view ${commitmentStatusDisplay[PURCHASED]} loans`}
                            />

                            <LegendItem
                                color={colors?.[2].toHexString()}
                                label={commitmentStatusDisplay[SOLD]}
                                value={sold ? sold : '--'}
                                percentage={!commitment ? '' : sold && (sold / commitment?.tradeAmount)}
                                to={`loans?loanStatus=${SOLD}`}
                                toButtonLabel={`view ${commitmentStatusDisplay[SOLD]} loans`}
                            />
                        </div>
                    </div>

                    <div
                        onMouseOut={() => {
                            setTimeout(() => setCurrentData(defaultCurrentData));
                        }}
                    >
                        {chartData && (
                            <AllocationHistoryChart
                                max={tradeAmount || 0}
                                data={chartData}
                                updateData={(newData) => setCurrentData({
                                    ...newData,
                                    undelivered: (tradeAmount || 0) - newData.delivered
                                })}
                                colors={colorz}
                            />
                        )}
                    </div>
                </FilledSection>
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

interface BulkCommitmentLoanCardProps {
    loan: BulkCommitmentLoan;
    colors: tinycolor.Instance[];
}

export function BulkCommitmentLoanCard({ loan, colors }: BulkCommitmentLoanCardProps) {
    const {
        loanNumber, status, loanAmount, lockExpiration, lockDate
    } = loan;

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.paper, styles.loanPaper)}
        >
            <Tooltip title={`${commitmentStatusDisplay[status]}`}>
                <div
                    className={styles.loanStatusColor}
                    style={{
                        backgroundColor: status === DELIVERED
                            ? colors?.[0].toHexString()
                            : status === PURCHASED
                                ? colors?.[1].toHexString()
                                : colors?.[2].toHexString()
                    }}
                />
            </Tooltip>

            <Typography
                color="textSecondary"
                variant="body2"
            >
                Loan #

                <MuiLink>
                    {loanNumber}
                </MuiLink>
            </Typography>

            <CurrencyTypography
                variant="body2"
                value={loanAmount}
                align="center"
                className={styles.loanAmount}
            />

            <IconTypography
                fontWeight={400}
                variant="body2"
                className={styles.tradeLockDateIconTypography}
                icon={(
                    <Tooltip title="Trade lock date">
                        <Lock
                            color="primary"
                            fontSize="small"
                        />
                    </Tooltip>
                )}
            >
                {formatDistanceToNowStrict(parseISO(lockDate), {
                    addSuffix: true
                })}
            </IconTypography>

            <IconTypography
                fontWeight={400}
                variant="body2"
                icon={(
                    <Tooltip title="Lock expiration">
                        {isBefore(parseISO(lockExpiration), new Date()) ? (
                            <HourglassBottom
                                color="primary"
                                fontSize="small"
                            />
                        ) : (
                            <HourglassTop
                                color="primary"
                                fontSize="small"
                            />
                        )}
                    </Tooltip>
                )}
            >
                {formatDistanceToNowStrict(parseISO(lockExpiration), {
                    addSuffix: true
                })}
            </IconTypography>
        </Paper>
    );
}

const dialogRoutes = {
    edit: BulkCommitmentDialog,
    loans: BulkCommitmentLoansDialog,
    'pair-offs': PairOffDialog
};

interface LegendItemProps {
    color: string | undefined;
    label: string;
    value?: number | string;
    percentage?: number | string;
    to?: string;
    toButtonLabel?: string;
}

function LegendItem({
    color, label, value, percentage, to, toButtonLabel
}: LegendItemProps) {
    return (
        <div className={styles.legendItem}>
            {to && (
                <div
                    className={styles.buttonContainer}
                    style={{ color }}
                >
                    <Button
                        component={Link}
                        to={to}
                        size="small"
                        color="inherit"
                    >
                        {toButtonLabel}
                    </Button>
                </div>
            )}

            <LinearProgress
                variant="determinate"
                color="inherit"
                style={{ color }}
                className={styles.color}
                value={100}
                // value={typeof percentage === 'string' || !percentage
                //     ? 0
                //     : (percentage * 100)}
            />

            <Typography
                fontWeight={500}
                color="textSecondary"
                variant="body2"
            >
                {label}
            </Typography>

            <Typography
                variant="h6"
                component="span"
                className={styles.legendItemPercentage}
            >
                {typeof percentage === 'string' || !percentage
                    ? percentage || '--'
                    : `${(percentage * 100).toFixed(2)}%`}
            </Typography>

            <Typography
                variant="body2"
                color="textSecondary"
            >
                {typeof value === 'string' ? value : formatCurrencyAbbreviation(value)}
            </Typography>
        </div>
    );
}


interface PricingTierTrackingCardProps {
    tier: BulkCommitmentPricingTierDetails;
    isExpanded?: boolean;
    onExpandClick: () => void;
    colors: tinycolor.Instance[] | null;
    toPathPrefix?: string;
}
export function PricingTierTrackingCard({
    tier, isExpanded, onExpandClick, colors, toPathPrefix
}: PricingTierTrackingCardProps) {
    const {
        productId, noteRate, price, loans, subLimit
    } = tier;

    const key = `${productId}${noteRate}${price}`;
    const totalAmount = loans.reduce((total, loan) => total + loan.loanAmount, 0);

    const {
        [DELIVERED]: numDelivered, [PURCHASED]: numPurchased, [SOLD]: numSold
    } = useMemo(() => loans.reduce((loansByStatus, loan) => {
        loansByStatus[loan.status]++;

        return loansByStatus;
    }, {
        [DELIVERED]: 0,
        [PURCHASED]: 0,
        [SOLD]: 0,
        [UNDELIVERED]: 0
    }), [ loans ]);

    return (
        <>
            <Paper
                variant="outlined"
                className={styles.paper}
            >
                <CardLinearProgress
                    onExpandClick={onExpandClick}
                    expanded={isExpanded}
                    disabled={!subLimit}
                    value={subLimit ? (totalAmount / subLimit) * 100 : 0}
                    centerLabel={`${loans.length} loans @ ${price.toFixed(5)}`}
                    leftLabel={(
                        <ProductNoteRateDisplay
                            productId={productId}
                            noteRate={noteRate}
                        />
                    )}
                    rightLabel={subLimit
                        ? `${formatCurrencyAbbreviation(totalAmount)}
                           / ${formatCurrencyAbbreviation(subLimit)}`
                        : 'No limit'}
                />
            </Paper>

            {isExpanded && (
                <>
                    <div className={styles.statusBreakdownContainer}>
                        <CardLinearProgress
                            leftLabel={`${numDelivered} ${commitmentStatusDisplay[DELIVERED]}`}
                            centerLabel={`${numPurchased} ${commitmentStatusDisplay[PURCHASED]}`}
                            rightLabel={`${numSold} ${commitmentStatusDisplay[SOLD]}`}
                            value={(numDelivered / loans.length) * 100}
                            linearProgressClassName={styles.mainLinearProgress}
                        />

                        <LinearProgress
                            variant="determinate"
                            value={100}
                            color="inherit"
                            sx={{
                                color: colors?.[1].toHexString(),
                                left: `${(numPurchased / loans.length) * 100}%`,
                                width: `${(numPurchased / loans.length) * 100}%`
                            }}
                            className={styles.absoluteLinearProgress}
                        />

                        <LinearProgress
                            variant="determinate"
                            value={100}
                            color="inherit"
                            sx={{
                                color: colors?.[2].toHexString(),
                                right: 0,
                                width: `${(numSold / loans.length) * 100}%`
                            }}
                            className={styles.absoluteLinearProgress}
                        />
                    </div>

                    <Button
                        component={Link}
                        to={`${toPathPrefix ?? ''}loans?tier=${key}`}
                        size="small"
                        className={styles.viewLoansButton}
                    >
                        View allocated loans for tier ({loans.length})
                    </Button>
                </>
            )}
        </>
    );
}
