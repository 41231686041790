import { PaginatedResponse } from '@tsp-ui/core';
import { getItemById, randomNum } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { Notification, NotificationType } from '..';
import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json({
            pageNumber: parseInt(req.url.searchParams.get('pageNumber') as string),
            pageSize: 25,
            totalPages: 3,
            totalRecords: 75,
            data: req.url.searchParams.get('isRead') === 'false'
                ? notifications.filter(({ isRead }) => !isRead)
                : notifications
        } as PaginatedResponse<Notification>)
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json({
            pageNumber: parseInt(req.url.searchParams.get('pageNumber') as string),
            pageSize: 25,
            totalPages: 3,
            totalRecords: 75,
            data: req.url.searchParams.get('isRead') === 'false'
                ? notifications.filter(({ isRead }) => !isRead)
                : notifications
        } as PaginatedResponse<Notification>)
    ))),
    rest.patch(getMockUrl('/notification/:notificationID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...getItemById(notifications, req.params.notificationID as string),
            ...req.body as Notification
        })
    )))
];

let notificationID = 0;
const notifications: Notification[] = [
    {
        id: `${notificationID++}`,
        type: NotificationType.CONDITION_EVENT,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 2000).toISOString(),
        isRead: false,
        isViewed: false
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.CONDITION_EXCEPTION_STATUS,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 2000000).toISOString(),
        isRead: false,
        isViewed: false
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_ROLE_ASSIGNMENT,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 20000000).toISOString(),
        isRead: false,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_ROLE_ASSIGNMENT,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 100000000).toISOString(),
        isRead: false,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 100000000).toISOString(),
        isRead: false,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_ROLE_ASSIGNMENT,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 200000000).toISOString(),
        isRead: false,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: new Date(Date.now() - 200000000).toISOString(),
        isRead: false,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: '2022-01-17T00:00:00Z',
        isRead: true,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: '2022-01-17T00:00:00Z',
        isRead: true,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: '2022-01-17T00:00:00Z',
        isRead: true,
        isViewed: true
    },
    {
        id: `${notificationID++}`,
        type: NotificationType.LOAN_STATUS_DELEGATED,
        description: 'Some description',
        loanNumber: '123456789',
        createdDate: '2022-01-17T00:00:00Z',
        isRead: true,
        isViewed: true
    }
];
