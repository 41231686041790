import api from '@api';
import {
    Autocomplete, CircularProgress, Link, TextField
} from '@mui/material';
import { getAccountBaseRoute, useGetCurrentAccount } from '@utils';
import React, {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { useDebounce } from 'use-debounce';


interface LoanOption {
    loanNumber: string;
    id: string;
}

interface LoanNumberAutocompleteProps {
    className?: string;
    loanNumberSearch: string | null;
    setLoanNumberSearch: Dispatch<SetStateAction<string>>;
    renderAsLinks?: boolean;
}

export default function LoanNumberAutoComplete({
    className,
    loanNumberSearch,
    setLoanNumberSearch,
    renderAsLinks = false
}: LoanNumberAutocompleteProps) {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ loanOptions, setLoanOptions ] = useState<LoanOption[]>([]);
    const [ errorText, setErrorText ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const fetchFilteredLoans = useCallback(async (query: string) => {
        if (query.length > 2) {
            setLoading(true);
            try {
                const loans = await api.loans.getRegisteredLoans(clientId, customerId, {
                    loanNumber: query,
                    pageSize: 20,
                    pageNumber: 1
                });

                const options = loans.data.map(loan => ({
                    loanNumber: loan.loanNumber,
                    id: loan.id
                }));

                setLoanOptions(options);
                setErrorText('');
            } catch (error) {
                setErrorText('Error fetching loans');
                setLoanOptions([]);
            } finally {
                setLoading(false);
            }
        } else {
            setLoanOptions([]);
            setErrorText('');
        }
    }, [ clientId, customerId ]);

    const [ debouncedFetchFilteredLoans ] = useDebounce(fetchFilteredLoans, 300);

    const getLoanUrl = (loanId: string) => `${getAccountBaseRoute(clientId, customerId)}/loans/${loanId}/detail/all`;

    function renderOption(props: React.HTMLAttributes<HTMLLIElement>, option: LoanOption) {
        if (renderAsLinks) {
            return (
                <li {...props}>
                    <Link
                        href={getLoanUrl(option.id)}
                        onClick={() => setLoanNumberSearch(option.loanNumber)}
                    >
                        {option.loanNumber}
                    </Link>
                </li>
            );
        }

        return (
            <li {...props}>
                {option.loanNumber}
            </li>
        );
    }

    return (
        <Autocomplete
            className={className}
            options={loanOptions}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.loanNumber;
            }}
            value={loanNumberSearch}
            inputValue={loanNumberSearch || ''}
            onChange={(_, newValue) => {
                if (newValue && typeof newValue !== 'string') {
                    setLoanNumberSearch(newValue.loanNumber);
                } else {
                    setLoanNumberSearch(newValue || '');
                }
            }}
            onInputChange={(_, newInputValue) => {
                setLoanNumberSearch(newInputValue);
                debouncedFetchFilteredLoans(newInputValue);
            }}
            renderOption={renderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${renderAsLinks ? 'Search' : 'Filter'} by loan number`}
                    variant="filled"
                    helperText={errorText}
                    error={!!errorText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}

                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            isOptionEqualToValue={(option, value) => {
                if (typeof value === 'string') {
                    return option.loanNumber === value;
                }
                return option.loanNumber === value.loanNumber;
            }}
            freeSolo
            size="small"
            noOptionsText="Start typing to search"
            loading={loading}
        />
    );
}
