import api, { Comment, PermissionType, UnderwritingConditionEvent } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import {
    Paper, Typography
} from '@mui/material';
import {
    IconButton, formatDate, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { useContext, useState } from 'react';

import UserLink from '../users/UserLink';

import styles from './CommentCard.module.scss';


interface CommentCardProps {
    conditionID: string;
    comment?: Comment;
    onEventsUpdate: (events: UnderwritingConditionEvent[]) => void;
}

export default function CommentCard({ conditionID, comment, onEventsUpdate }: CommentCardProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { id: clientID, customerId } = useGetCurrentAccount();
    const losLoanId = useContext(LoanDetailContext).loanDetail?.losLoanId!;
    const canManage = useHasPermission([ PermissionType.MANAGE_LOAN_CONDITION_COMMENTS ]);

    const { commentId } = comment!;

    const { setConditions } = useContext(LoanDetailContext);

    const [ deleteLoading, setDeleteLoading ] = useState(false);

    async function handleDelete() {
        if (commentId && await confirm('Are you sure you want to delete this comment?')) {
            try {
                setDeleteLoading(true);
                await api.loans.deleteUWConditionComment(clientID, losLoanId, conditionID, commentId, customerId);

                setConditions(await api.loans.getUnderwritingConditions(
                    clientID, customerId, losLoanId
                ));

                onEventsUpdate(await api.loans.getUWConditionEvents(
                    clientID, losLoanId, conditionID, customerId
                ));

                pageMessage.success('Comment deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the comment', error);
            }

            setDeleteLoading(false);
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.actionsContainer}>
                <Typography
                    variant="body2"
                    className={styles.description}
                >
                    {comment?.comments}
                </Typography>

                <Typography
                    variant="caption"
                    color="textSecondary"
                    className={styles.createdBy}
                >
                    {`${formatDate(comment?.dateCreated)} by`}

                    <UserLink userId={comment?.creatorUserId || ''} />
                </Typography>
            </div>

            <IconButton
                edge="end"
                tooltip={canManage
                    ? 'Delete comment'
                    : 'You do not have permission to delete comments'}
                onClick={handleDelete}
                loading={deleteLoading}
                className={styles.button}
            >
                <RemoveCircleOutline color="error" />
            </IconButton>
        </Paper>
    );
}
