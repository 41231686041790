import api, { CustomerSummary, User } from '@api';
import { Toolbar, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { getCognitoToken, useAsyncEffect } from '@tsp-ui/core';
import { Loader, SentryRoutes } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { ConfigurableValues } from '@utils';
import { useTryGetCurrentAccount, useTryGetCurrentUser } from '@utils/hooks';
import { withAuth } from '@utils/withAuth';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { apiUtils, refreshClientToken } from '../api/api-utils';

import styles from './AuthenticatedRouteSwitch.module.scss';
import { default as NotFoundPageBase } from './NotFoundPage';
import { default as AccountDashboardPageBase } from './accounts/AccountDashboardPage';
import { default as SwitchAccountsPageBase } from './accounts/SwitchAccountsPage';
import { default as AdminRouteSwitchBase } from './admin/AdminRouteSwitch';
import { ActiveTokenContext } from './components/ActiveTokenContext';
import MainNav from './components/MainNav/MainNav';
import AccountButton from './components/MainNav/components/AccountButton';
import NotificationsButton from './components/MainNav/components/NotificationsButton/NotificationsButton';
import { default as LoanDetailPageBase } from './loans/LoanDetailPage';
import { default as LoansPageBase } from './loans/LoansPage';
import { default as PendingLoanDetailPageBase } from './loans/PendingLoanDetailPage';
import { default as ManualLoanEntryPageBase } from './product-pricing/ManualLoanEntryPage';
import { default as ProductAndPricingPageBase } from './product-pricing/ProductAndPricingPage';
import { default as TrackingRouteSwitchBase } from './tracking/TrackingRouteSwitch';


const AdminRouteSwitch = withAuth(AdminRouteSwitchBase);
const TrackingRouteSwitch = withAuth(TrackingRouteSwitchBase);
const AccountDashboardPage = withAuth(AccountDashboardPageBase);
const LoansPage = withAuth(LoansPageBase);
const LoanDetailPage = withAuth(LoanDetailPageBase);
const PendingLoanDetailPage = withAuth(PendingLoanDetailPageBase);
const ManualLoanEntryPage = withAuth(ManualLoanEntryPageBase);
const ProductAndPricingPage = withAuth(ProductAndPricingPageBase);
const SwitchAccountsPage = withAuth(SwitchAccountsPageBase);
const NotFoundPage = withAuth(NotFoundPageBase);

export interface UserLinkContextValue {
    users: User[];
}

export const UserLinkContext = createContext<UserLinkContextValue>({
    users: []
});

export default function AuthenticatedRouteSwitch() {
    const pageMessage = usePageMessage();
    const user = useTryGetCurrentUser();
    const account = useTryGetCurrentAccount();
    const { customerName, id: clientId, customerId } = account || {};
    const { pathname } = useLocation();

    const [ users, setUsers ] = useState<User[]>([]);

    const [ clientTokenLoading, setClientTokenLoading ] = useState(!!clientId);

    const { activeAuthToken, setActiveAuthToken } = useContext(ActiveTokenContext);

    const showLoader = clientTokenLoading || !activeAuthToken;

    useAsyncEffect(useCallback(async () => {
        if (clientId && clientId !== 'internal') {
            try {
                setClientTokenLoading(true);
                setActiveAuthToken(await refreshClientToken(clientId, customerId));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while authenticating with client', error);
            }
        } else {
            apiUtils.clearClientToken();
            setActiveAuthToken(await getCognitoToken());
        }

        if (clientId) {
            try {
                setUsers(await api.users.getUsers(clientId, customerId));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching users for the account', error);
            }
        }

        // open web socket after user is authenticated
        api.webSocket.open();

        setClientTokenLoading(false);
    }, [
        clientId, customerId, pageMessage, setActiveAuthToken
    ]));

    useEffect(() => {
        if (account) {
            Sentry.setContext('account', account);
        }
    }, [ account ]);

    const userLinkContextValue = useMemo(() => ({
        users
    }), [ users ]);

    return !user ? (
        <Navigate
            to="/login"
            state={{ redirectTo: pathname }}
        />
    ) : (
        <div className={styles.root}>
            <MainNav account={account} />

            <header className={styles.headerContainer}>
                <Toolbar className={styles.toolbar}>
                    {account && (
                        <>
                            <div className={styles.logoContainer}>
                                <img
                                    alt="account logo"
                                    src={account.logoUrl}
                                    className={styles.logo}
                                />

                                {!!customerId && (
                                    <Typography
                                        variant="body2"
                                        className={styles.customerName}
                                    >
                                        {customerName}
                                    </Typography>
                                )}
                            </div>

                            {!clientTokenLoading && <NotificationsButton />}
                        </>
                    )}

                    <AccountButton account={account} />
                </Toolbar>
            </header>

            {showLoader ? <Loader loading /> : (
                <UserLinkContext.Provider value={userLinkContextValue}>
                    <SentryRoutes>
                        <Route
                            path="/accounts"
                            element={<SwitchAccountsPage />}
                        />

                        <Route
                            path={customerId
                                ? '/accounts/:accountID/:customerId/*'
                                : '/accounts/:accountID/*'}
                            element={<AccountRoutes />}
                        />

                        <Route
                            path="*"
                            element={<NotFoundPage />}
                        />
                    </SentryRoutes>
                </UserLinkContext.Provider>
            )}
        </div>
    );
}

type ClientContextValue = {
    configurableValues: ConfigurableValues | undefined;
    setConfigurableValues: Dispatch<SetStateAction<ConfigurableValues | undefined>>;
    customers: CustomerSummary[] | undefined;
    setCustomers: Dispatch<SetStateAction<CustomerSummary[] | undefined>>;
}

export const ClientContext = createContext<ClientContextValue>({
    configurableValues: undefined,
    setConfigurableValues: () => {},
    customers: undefined,
    setCustomers: () => {}
});

function AccountRoutes() {
    const account = useTryGetCurrentAccount();
    const pageMessage = usePageMessage();
    const [ configurableValues, setConfigurableValues ] = useState<ConfigurableValues>();
    const [ customers, setCustomers ] = useState<CustomerSummary[]>();

    useEffect(() => {
        if (!account) {
            pageMessage.error('You do not have permission to view the account you attempted to access');
        }
    }, [ pageMessage, account ]);

    useAsyncEffect(useCallback(async () => {
        if (account) {
            try {
                setConfigurableValues(await api.configurableValues.getConfigurableValues(account.id));

                if (!account.customerId) {
                    setCustomers(await api.customer.getCustomers(account.id));
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching account data', error);
            }
        }
    }, [ account, pageMessage ]));

    const clientContextValue = useMemo(() => ({
        configurableValues,
        setConfigurableValues,
        customers,
        setCustomers
    }), [ configurableValues, customers ]);

    return !account ? (
        <Navigate to="/accounts" />
    ) : (
        <ClientContext.Provider value={clientContextValue}>
            <SentryRoutes>
                <Route
                    path="/"
                    element={<Navigate to="dashboard" />}
                />

                <Route
                    path="dashboard/*"
                    element={<AccountDashboardPage />}
                />

                <Route
                    path="admin/*"
                    element={<AdminRouteSwitch />}
                />

                <Route
                    path="loans/*"
                    element={<LoansRoutes />}
                />

                <Route
                    path="product-pricing/*"
                    element={<ProductAndPricingPage />}
                />

                <Route
                    path="product-pricing/new/manual"
                    element={<ManualLoanEntryPage />}
                />

                <Route
                    path="tracking/*"
                    element={<TrackingRouteSwitch />}
                />

                <Route
                    path="*"
                    element={<NotFoundPage />}
                />
            </SentryRoutes>
        </ClientContext.Provider>
    );
}

function LoansRoutes() {
    return (
        <SentryRoutes>
            <Route
                path="*"
                element={<LoansPage />}
            />

            <Route
                path=":loanID/detail/:underwritingCategoryId/*"
                element={<LoanDetailPage />}
            />

            <Route
                path=":loanID/pending/detail/*"
                element={<PendingLoanDetailPage />}
            />
        </SentryRoutes>
    );
}
