import api, { PermissionType } from '@api';
import { AddCircleOutline } from '@mui/icons-material';
import {
    Alert, AlertTitle, Button as MuiButton, Link as MuiLink
} from '@mui/material';
import { Button, FilledSection, IconButton } from '@tsp-ui/core/components';
import { capitalize, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './LockAvailabilityContent.module.scss';
import { Day, LockAvailabilityContext, LockAvailabilityDay } from './LockAvailabilityPage';
import { LockOverrideAvailabilityCard } from './LockOverrideAvailabilityCard';
import { LockWeekdayAvailabilityCard } from './LockWeekdayAvailabilityCard';


export function LockAvailabilityContent() {
    const { customerID } = useParams();
    const { id: clientID } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const {
        lockAvailability, setLockAvailability, lockAvailabilityOverrides,
        setLockAvailabilityOverrides, lockSuspension, setLockSuspension
    } = useContext(LockAvailabilityContext);

    const [ showWeekends, setShowWeekends ] = useState(false);

    const days: LockAvailabilityDay[] = ([
        'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
    ] as Day[]).filter(day => showWeekends || (day !== 'saturday' && day !== 'sunday')).map(day => ({
        day,
        available: !!lockAvailability?.[`is${capitalize(day)}Available`],
        startTime: lockAvailability?.[`${day}Start`],
        endTime: lockAvailability?.[`${day}End`]
    }));

    const toggleLockAvailability = async () => {
        try {
            setToggleLockAvailabilityLoading(true);

            await api.availability.toggleCustomerLockAvailabilityConfig(clientID,
                { useCustomerConfig: !usingCustomerAvailabilityConfig }, customerID);

            setLockAvailability(
                (!usingCustomerAvailabilityConfig
                    ? (await api.availability.getLockAvailabilities(
                        clientID, customerID
                    ))[0]
                    : undefined)
            );

            setLockAvailabilityOverrides(
                (!usingCustomerAvailabilityConfig)
                    ? await api.availability.getLockAvailabilityOverrides(
                        clientID, customerID
                    )
                    : []
            );

            pageMessage.success(usingCustomerAvailabilityConfig ? 'Customer lock availability config removed'
                : 'Customer lock availability config created');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating lock availability', error);
        }
        setToggleLockAvailabilityLoading(false);
    };

    const [ toggleLockAvailabilityLoading, setToggleLockAvailabilityLoading ] = useState(false);

    const [
        canAddLockAvailabilityOverride, canEditLockAvailability, canDeleteLockSuspensions
    ] = useHasPermission([
        PermissionType.CREATE_LOCK_AVAILABILITY_OVERRIDE,
        PermissionType.EDIT_LOCK_AVAILABILITY,
        PermissionType.DELETE_LOCK_SUSPENSIONS
    ]);

    const isCustomerView = !!customerID;
    const isSuspended = lockSuspension?.isSuspended || lockSuspension?.isSuspendedUntilNewRateSheet;
    const isSuspendedForCustomer = isCustomerView && isSuspended;
    const isSuspendedGlobally = !lockSuspension?.customerId && isSuspended;

    const usingCustomerAvailabilityConfig = isCustomerView && !!lockAvailability;
    const showLockAvailability = !isCustomerView || usingCustomerAvailabilityConfig;

    const disableReactivateButton = (
        !canDeleteLockSuspensions || (isSuspendedGlobally && isCustomerView)
    );

    const reactivateButtonTooltip = !canDeleteLockSuspensions
        ? 'You do not have permission to remove lock suspensions' : (isSuspendedGlobally && isCustomerView)
            ? 'You can not reactivate global locks from the customer view' : '';

    const linkToGlobalLockAvailability = isCustomerView && isSuspendedGlobally;

    return (
        <>
            {(isSuspendedGlobally || isSuspendedForCustomer) && (
                <Alert
                    severity="warning"
                    className={styles.suspendedAlert}
                    action={(
                        <Button
                            disabled={disableReactivateButton}
                            color="inherit"
                            tooltip={reactivateButtonTooltip}
                            onClick={async () => {
                                try {
                                    await api.availability.deleteLockSuspension(clientID, customerID);
                                    setLockSuspension(await api.availability.getCurrentLockSuspension(
                                        clientID, customerID
                                    ));

                                    pageMessage.success('Locks activated');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while reactivating locks', error);
                                }
                            }}
                        >
                            Reactivate locks
                        </Button>
                    )}
                >
                    <AlertTitle classes={{ root: styles.alertTitle }}>
                        {lockSuspension.isSuspended ? 'All locks suspended' : 'Locks suspended until new ratesheet'}
                    </AlertTitle>

                    {!linkToGlobalLockAvailability ? null : (
                        <span>
                            Locks are currently suspended globally.&nbsp;
                            <MuiLink
                                component={Link}
                                to="../../../lock-availability"
                            >
                                Click here
                            </MuiLink> to manage your global lock suspension.
                        </span>
                    )}

                    {!lockSuspension.suspendedComments ? null : (
                        <div>{lockSuspension.suspendedComments}</div>
                    )}
                </Alert>
            )}

            {isCustomerView && (
                <Alert
                    severity="info"
                    className={styles.availabilityAlert}
                    action={(
                        <Button
                            disabled={!canEditLockAvailability || toggleLockAvailabilityLoading}
                            color="inherit"
                            onClick={toggleLockAvailability}
                            tooltip={canEditLockAvailability ? '' : 'You do not have permission to edit lock availability'}
                        >
                            {usingCustomerAvailabilityConfig ? 'Revert to global availability' : 'Add customer specific availability'}
                        </Button>
                    )}
                >
                    <AlertTitle classes={{ root: styles.alertTitle }}>
                        {usingCustomerAvailabilityConfig ? 'Using customer specific availability' : 'Using global availability'}
                    </AlertTitle>

                    {usingCustomerAvailabilityConfig ? null : (
                        <span>
                            <MuiLink
                                component={Link}
                                to="../../../lock-availability"
                            >
                                Click here
                            </MuiLink> to manage your global lock availability.
                        </span>
                    )}
                </Alert>
            )}

            {showLockAvailability && (
                <div className={styles.sections}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                Standard availability

                                <MuiButton onClick={() => setShowWeekends(!showWeekends)}>
                                    {showWeekends ? 'Hide' : 'Show'} weekends
                                </MuiButton>
                            </>
                        )}
                    >
                        {lockAvailability && days.map((lockAvailabilityDay) => (
                            <LockWeekdayAvailabilityCard
                                key={lockAvailabilityDay.day}
                                lockAvailabilityDay={lockAvailabilityDay}
                            />
                        ))}
                    </FilledSection>

                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                Availability Overrides

                                <IconButton
                                    tooltip={canAddLockAvailabilityOverride ? 'Add override' : 'You do not have permission to add an override'}
                                    disabled={!canAddLockAvailabilityOverride}
                                    component={Link}
                                    to="add"
                                >
                                    <AddCircleOutline color="secondary" />
                                </IconButton>
                            </>
                        )}
                    >
                        {lockAvailabilityOverrides.map((override) => (
                            <LockOverrideAvailabilityCard
                                key={override.lockAvailabilityDate}
                                override={override}
                            />
                        ))}
                    </FilledSection>
                </div>
            )}
        </>
    );
}
