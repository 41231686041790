import { LoanDocument, PremicorrConditionCategory, UnderwritingCategory } from '@api';
import { ListItemButton, Paper, Typography } from '@mui/material';
import StatusChecklistItem from '@views/admin/customers/components/StatusChecklistItem';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { LoanDetailContext } from '../LoanDetailPage';
import { LoanDocumentItem } from '../components/LoanDocumentsDialog';
import { ConditionsDisplay } from '../conditions/ConditionsDisplay';
import ConditionsSummary from '../conditions/ConditionsSummary';

import styles from './UnderwritingCategoryDetail.module.scss';
import { UnderwritingStepSection } from './UnderwritingStepSection';


export function UnderwritingCategoryDetail({ category }: { category: UnderwritingCategory }) {
    const { conditions } = useContext(LoanDetailContext);
    const [ underwritingStepId, setUnderwritingStepId ] = useState<string>();

    // when category changes, reset underwritingStepId
    useEffect(() => {
        setUnderwritingStepId(undefined);
    }, [ category.id ]);

    // TODO conditions need to be updated to be linked by category id, not enum category
    // i.e. condition.category should be replaced with condition.categoryId
    // conditions also need to be associated with a specific underwriting step
    const filteredConditions = conditions?.filter(
        condition => condition.category === PremicorrConditionCategory.FRAUD_REPORT
    );

    return (
        <>
            <div className={styles.leftPanel}>
                <Paper
                    elevation={0}
                    className={styles.relevantDocs}
                >
                    <Typography color="textSecondary">
                        Relevant documents
                    </Typography>

                    <ul className={styles.files}>
                        {[ 'fraud-report.pdf' ].map((doc) => (
                            <LoanDocumentItem
                                key={doc}
                                documentId="1"
                                documents={[
                                    {
                                        id: '1',
                                        instanceCount: 1,
                                        name: doc
                                    } as LoanDocument
                                ]} // TODO post-demo make this better
                            />
                        ))}
                    </ul>
                </Paper>

                <div>
                    <ListItemButton
                        selected={!underwritingStepId}
                        className={styles.allConditionsStep}
                        classes={{
                            selected: clsx(styles.selected, {
                                [styles.extraWidth]: false
                            })
                        }}
                        onClick={() => setUnderwritingStepId(undefined)}
                    >
                        Conditions

                        <ConditionsDisplay numConditions={0} />
                    </ListItemButton>

                    <Typography color="textSecondary">
                        Underwriting steps
                    </Typography>

                    {category?.steps.map((step) => (
                        <ListItemButton
                            key={step.id}
                            selected={underwritingStepId === step.id}
                            className={styles.stepChecklistItemContainer}
                            classes={{
                                selected: clsx(styles.selected, {
                                    [styles.extraWidth]: false
                                })
                            }}
                            onClick={() => setUnderwritingStepId(step.id)}
                        >
                            <StatusChecklistItem
                                label={step.name}
                                done={step.isSubmitted}
                                selected={underwritingStepId === step.id}
                            />

                            {step.isSubmitted && (
                                <ConditionsDisplay numConditions={
                                    conditions.filter(condition => condition.underwritingStepId === step.id).length
                                }
                                />
                            )}
                        </ListItemButton>
                    ))}
                </div>
            </div>

            {!underwritingStepId ? (
                <ConditionsSummary
                    header={`${category.name} Conditions`}
                    conditions={filteredConditions}
                />
            ) : (
                <UnderwritingStepSection
                    underwritingStepId={underwritingStepId}
                    underwritingCategory={category}
                    setUnderwritingStepId={setUnderwritingStepId}
                />
            )}
        </>
    );
}
