import api, {
    LoanAllocationLimit,
    LoanAllocationLimitType,
    PermissionType,
    Product,
    ProductTypeConfig,
    loanAllocationLimitTypeDisplay,
    productTypeDisplay,
    underwritingTypeDisplay
} from '@api';
import { Edit, RemoveCircleOutline, Schedule } from '@mui/icons-material';
import {
    Paper, Tooltip, Typography
} from '@mui/material';
import {
    Button,
    DialogActions,
    ExpandableHeader,
    IconButton,
    IconTypography,
    RoutedDialogImplProps,
    useConfirm
} from '@tsp-ui/core/components';
import {
    formatCurrencyAbbreviation, removeItemById, useAsyncEffect, usePageMessage
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import {
    CustomerDetailContext,
    CustomerDetailContextValue
} from '@views/admin/customers/CustomerDetailPage/CustomerDetailPage';
import CardDialogContent from '@views/components/CardDialogContent';
import RoutedFormDialog from '@views/components/RoutedFormDialog';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { useCallback, useContext, useState } from 'react';

import styles from './LimitsDialog.module.scss';
import LimitForm from './components/LimitForm';


export default function LimitsDialog(props: RoutedDialogImplProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const { id: clientId } = useGetCurrentAccount();

    const { customer, products } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    const customerId = customer.id;

    const [ loading, setLoading ] = useState(true);
    const [ limits, setLimits ] = useState<LoanAllocationLimit[]>();

    const [ showAdd, setShowAdd ] = useState(false);
    const [ limitIdToEdit, setLimitIdToEdit ] = useState<string>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);

            setLimits(await api.customer.limits.getLoanAllocationLimits(clientId, customerId));
        } catch (error) {
            pageMessage.handleApiError(
                'An error occurred while fetching the loan allocation limits for this customer', error
            );
        }

        setLoading(false);
    }, [
        pageMessage, clientId, customerId
    ]));

    async function handleDeleteClick(limitId: string) {
        if (await confirm('Are you sure you want to delete this limit?')) {
            try {
                await api.customer.limits.deleteLoanAllocationLimit(clientId, customerId, limitId);
                setLimits((limits) => limits && removeItemById(limits, limitId));

                pageMessage.success('Limit deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the limit', error);
            }
        }
    }

    const commonSectionProps = {
        limits,
        products,
        productTypeConfigs: customer?.productTypeConfigs,
        onDeleteClick: handleDeleteClick,
        onEditClick: (limitId: string) => {
            setLimitIdToEdit(limitId);
        }
    };

    const showForm = showAdd || !!limitIdToEdit;

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_ALLOCATION_LIMITS ]);

    return (
        <RoutedFormDialog
            title="Allocation limits"
            loading={loading}
            maxWidth="lg"
            classNames={{ formShown: styles.formShown }}
            classes={{ paper: styles.dialogPaper }}
            showForm={showForm}
            formTitle={`${showAdd ? 'Add' : 'Edit'} limit`}
            form={(
                <LimitForm
                    key={limitIdToEdit || (showAdd ? 'add' : 'hidden')}
                    limit={limits?.find(({ id }) => id === limitIdToEdit)}
                    limits={limits}
                    setLimits={setLimits}
                    customerId={customerId}
                    onEditClick={(limitId) => {
                        setShowAdd(false);
                        setLimitIdToEdit(limitId);
                    }}
                    closeForm={() => {
                        setShowAdd(false);
                        setLimitIdToEdit(undefined);
                    }}
                />
            )}
            {...props}
        >
            <CardDialogContent>
                <LimitCardSection
                    limitType={LoanAllocationLimitType.ALL}
                    {...commonSectionProps}
                />

                <LimitCardSection
                    limitType={LoanAllocationLimitType.UNDERWRITING_TYPE}
                    {...commonSectionProps}
                />

                <LimitCardSection
                    limitType={LoanAllocationLimitType.PRODUCT_TYPE}
                    {...commonSectionProps}
                />

                <LimitCardSection
                    limitType={LoanAllocationLimitType.PRODUCT}
                    {...commonSectionProps}
                />
            </CardDialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setShowAdd(true)}
                    disabled={!canManage}
                    tooltip={canManage ? '' : noPermissionText}
                >
                    Add limit
                </Button>
            </DialogActions>
        </RoutedFormDialog>
    );
}

const noPermissionText = 'You don\'t have permission to manage limits';

interface LimitCardSectionProps {
    limitType: LoanAllocationLimitType;
    limits: LoanAllocationLimit[] | undefined;
    products: Product[] | undefined;
    productTypeConfigs: ProductTypeConfig[] | undefined;
    onEditClick: (limitId: string) => void;
    onDeleteClick: (limitId: string) => void;
}

function LimitCardSection({
    limitType, limits, products, productTypeConfigs, onEditClick, onDeleteClick
}: LimitCardSectionProps) {
    limits = limits?.filter(({ type }) => type === limitType);

    return (
        <ExpandableHeader
            variant="body2"
            fontWeight={400}
            disableExpand={false}
            title={loanAllocationLimitTypeDisplay[limitType]}
            secondaryText={`${limits?.length || 'No'} ${limits?.length === 1 ? 'limit' : 'limits'}`}
            className={styles.header}
            expandedContent={!limits?.length ? (
                <Paper
                    variant="outlined"
                    className={styles.noResultsPaper}
                >
                    <Typography
                        variant="body2"
                        align="center"
                    >
                        There are currently no limits of this type for this customer
                    </Typography>
                </Paper>
            ) : limits?.map((limit) => (
                <LimitCard
                    key={limit.id}
                    limit={limit}
                    products={products}
                    productTypeConfigs={productTypeConfigs}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                />
            ))}
        />
    );
}

interface LimitCardProps {
    limit: LoanAllocationLimit;
    products: Product[] | undefined;
    productTypeConfigs: ProductTypeConfig[] | undefined;
    onEditClick: (limitId: string) => void;
    onDeleteClick: (limitId: string) => void;
}

function LimitCard({
    limit, products, productTypeConfigs, onEditClick, onDeleteClick
}: LimitCardProps) {
    const { productType } = productTypeConfigs?.find(({ id }) => id === limit.productTypeConfigId) || {};

    const label = limit.type === LoanAllocationLimitType.ALL
        ? 'All products'
        : limit.type === LoanAllocationLimitType.UNDERWRITING_TYPE
            ? underwritingTypeDisplay[limit.underwritingType!]
            : limit.type === LoanAllocationLimitType.PRODUCT_TYPE
                ? productType ? productTypeDisplay[productType] : '--'
                : products?.find(({ id }) => id === limit.productId)?.productDescription || '--';

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_ALLOCATION_LIMITS ]);

    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography variant="body2">
                {label}
            </Typography>

            <IconTypography
                fontWeight={400}
                variant="body2"
                icon={(
                    <Tooltip title="Expiration date">
                        <Schedule
                            color="primary"
                            fontSize="small"
                        />
                    </Tooltip>
                )}
            >
                {formatDistanceToNowStrict(parseISO(limit.expirationDate), {
                    addSuffix: true
                })}
            </IconTypography>

            <Typography
                variant="body2"
                className={styles.limitAmount}
            >
                {formatCurrencyAbbreviation(limit.limitAmount)}
            </Typography>

            <div>
                <IconButton
                    tooltip={canManage ? 'Edit limit' : noPermissionText}
                    size="small"
                    onClick={() => onEditClick(limit.id)}
                    disabled={!canManage}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete limit' : noPermissionText}
                    size="small"
                    edge="end"
                    onClick={() => onDeleteClick(limit.id)}
                    disabled={!canManage}
                >
                    <RemoveCircleOutline
                        color="error"
                        fontSize="small"
                    />
                </IconButton>
            </div>
        </Paper>
    );
}
