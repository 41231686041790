import api, { ClientInvestor, ExclusionOverlay, InvestorStatus } from '@api';
import { Button as MuiButton, Typography } from '@mui/material';
import {
    Button, FilterTextField, FilterableFilledSection, RoutedDialogManager
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import ExclusionOverlaySection from '@views/admin/investor-eligibility/components/ExclusionOverlaySection';
import {
    EligibilityVersionButton, useEligibilityVersionIdQueryParam
} from '@views/admin/investors/components/EligibilityVersionButton';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import styles from './InvestorEligibilityManagementPage.module.scss';
import { InvestorEligibilityContext } from './InvestorEligibilityRoutes';
import { ClientInvestorCard } from './components/ClientInvestorCard';
import { overlayDialogRoutes } from './overlayDialogRoutes';


export interface ExclusionOverlayContextValue {
    overlays: ExclusionOverlay[];
    setOverlays: Dispatch<SetStateAction<ExclusionOverlay[]>>;
}

export const ExclusionOverlayContext = createContext<ExclusionOverlayContextValue>({
    overlays: [],
    setOverlays: () => {}
});

export default function InvestorEligibilityManagementPage() {
    const pageMessage = usePageMessage();
    const { search } = useLocation();
    const clientId = useActingClientID();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const [ loading, setLoading ] = useState(true);
    const [ showInactive, setShowInactive ] = useState(false);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const { investors, eligibilityVersions, isSelectedVersionEditable } = useContext(InvestorEligibilityContext);
    const filteredInvestors = investors?.filter(({ name, status }) => (
        name.toLocaleLowerCase().includes(debouncedTerm) && (showInactive || status !== InvestorStatus.INACTIVE)
    )).sort(sortAlphabeticallyByStatus);

    const [ overlays, setOverlays ] = useState<ExclusionOverlay[]>([]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setOverlays(await api.overlay.getExclusionOverlays(clientId, {
                effectiveVersionId: eligibilityVersionId
            }));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching exclusion overlays', error);
        }

        setLoading(false);
    }, [
        clientId, eligibilityVersionId, pageMessage
    ]));

    return (
        <ExclusionOverlayContext.Provider
            value={{
                overlays,
                setOverlays
            }}
        >
            <Page
                header="Investor Eligibility Management"
                loading={loading || !investors || !eligibilityVersions}
                headerActions={(
                    <EligibilityVersionButton eligibilityVersions={eligibilityVersions} />
                )}
            >
                <RoutedDialogManager routes={overlayDialogRoutes} />

                <div className={styles.root}>
                    <FilterableFilledSection
                        header={(
                            <>
                                Investors

                                <MuiButton
                                    className={styles.disabledCheckbox}
                                    onClick={() => setShowInactive(!showInactive)}
                                    disabled={!investors?.some(({ status }) => status === InvestorStatus.INACTIVE)}
                                >
                                    {showInactive ? 'Hide' : 'Show'} inactive
                                </MuiButton>
                            </>
                        )}
                        addButton={(
                            <Button
                                variant="contained"
                                component={Link}
                                to={`investors/add${search}`}
                                disabled={!isSelectedVersionEditable}
                                tooltip={!isSelectedVersionEditable
                                    ? 'You can only add investors to a future eligibility version'
                                    : ''}
                            >
                                Add Investor
                            </Button>
                        )}
                        filterField={(
                            <FilterTextField
                                autoFocus
                                placeholder="Filter investors"
                                helperText="Filter by name"
                                onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                            />
                        )}
                    >
                        {filteredInvestors?.length ? filteredInvestors.map(investor => (
                            <ClientInvestorCard
                                key={investor.id}
                                investor={investor}
                            />
                        )) : (
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                No investors are currently configured
                            </Typography>
                        )}
                    </FilterableFilledSection>

                    <ExclusionOverlaySection
                        overlays={overlays}
                        disableAdd={!isSelectedVersionEditable}
                    />
                </div>
            </Page>
        </ExclusionOverlayContext.Provider>
    );
}

function sortAlphabeticallyByStatus(investorA: ClientInvestor, investorB: ClientInvestor) {
    if (investorA.status === investorB.status) {
        // sort alphabetically
        return investorA.name.localeCompare(investorB.name);
    } else if (investorB.status === InvestorStatus.INACTIVE) {
        // sort A first
        return -1;
    } else if (investorB.status === InvestorStatus.ACTIVE) {
        // sort B first
        return 1;
    } else if (investorA.status === InvestorStatus.ACTIVE) {
        // if B is pending and A is active, sort A first
        return 1;
    } else {
        // if B is pending and A is inactive, sort B first
        return -1;
    }
}
