import { NotificationConfig, PermissionType } from '@api';
import { Button } from '@tsp-ui/core';
import { FilledSection } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks';
import { Link, useLocation } from 'react-router-dom';

import NotificationCard from './NotificationCard';
import styles from './NotificationsSection.module.scss';


interface NotificationsSectionProps {
    notifications: NotificationConfig[];
}

export default function NotificationsSection({ notifications }: NotificationsSectionProps) {
    const location = useLocation();

    const [ canViewNotifications, canManageNotifications ] = useHasPermission(
        [ PermissionType.VIEW_CLIENT_NOTIFICATION_CONFIGS, PermissionType.MANAGE_CLIENT_NOTIFICATION_CONFIGS ]
    );

    return (
        <FilledSection
            className={styles.paper}
            header={(
                <>
                    Notifications

                    <Button
                        component={Link}
                        to={`${location.pathname}/notifications/new`}
                        disabled={!canManageNotifications}
                        tooltip={!canManageNotifications ? 'You do not have permission to add a notification' : ''}
                    >
                        Add notification
                    </Button>
                </>
            )}
        >
            {canViewNotifications ? (
                notifications.map((notification) => (
                    <NotificationCard
                        key={notification.id}
                        notification={notification}
                    />
                ))
            ) : (
                <div>
                    You do not have permission to view notifications.
                </div>
            )}
        </FilledSection>
    );
}
