import api, { DueDiligenceStep } from '@api';
import { Divider, List, Typography } from '@mui/material';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Page from '../../components/Page';

import styles from './DataRequestsPage.module.scss';
import { DataRequestSection } from './components/DataRequestSection';
import { DataRequestsMenuListItem } from './components/DataRequestsMenuListItem';


export default function DataRequestsPage() {
    const { dataRequestID } = useParams();

    const [ dueDiligenceSteps, setDueDiligenceSteps ] = useState<DueDiligenceStep[]>([]);
    const { id: clientID, customerId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setDueDiligenceSteps(await api.customer.dueDiligenceStep.getDueDiligenceSteps(clientID, customerId!));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching due diligence steps', error);
        }

        setLoading(false);
    }, [
        clientID, customerId, pageMessage
    ]));

    const selectedStep = dueDiligenceSteps.find(({ id }) => id === dataRequestID);

    return (
        <Page
            header="Data Requests"
            loading={loading}
        >
            <Divider className={styles.divider} />

            {!!dueDiligenceSteps.length ? (
                <div className={styles.content}>
                    <List
                        disablePadding
                        className={styles.listRoot}
                    >
                        {dueDiligenceSteps.map(dueDiligenceStep => (
                            <DataRequestsMenuListItem
                                key={dueDiligenceStep.id}
                                tab={dueDiligenceStep.id}
                                status={dueDiligenceStep.approvalStatus}
                                title={dueDiligenceStep.name}
                            />
                        ))}
                    </List>

                    {selectedStep ? (
                        <DataRequestSection
                            dueDiligenceStep={selectedStep}
                            firstSelected={dataRequestID === dueDiligenceSteps[0]?.id}
                            setDueDiligenceSteps={setDueDiligenceSteps}
                        />
                    ) : (
                        <Navigate to={dueDiligenceSteps[0].id} />
                    )}
                </div>
            ) : (
                <Typography>There are no data requests at this time.</Typography>
            )}
        </Page>
    );
}
