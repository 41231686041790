import api, {
    AmortizationType, Investor, PermissionType, Product, ProductType, productTypeDisplay
} from '@api';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ExpandableHeader } from '@tsp-ui/core';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import {
    createContext, useCallback, useContext, useMemo, useState
} from 'react';

import AdminPageTemplate, {
    AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import ProductDetailPage from './ProductDetailPage/ProductDetailPage';
import styles from './ProductManagementPage.module.scss';
import ProductCard from './components/ProductCard';
import { ProductDetailsForm } from './components/ProductDetailsForm';


export const ProductManagementPageContext = createContext<AdminPageContextValues<Product>>(
    defaultAdminPageContextValues
);

export const InvestorsContext = createContext<{ investors: Investor[] }>({ investors: [] });

export default function ProductManagementPage() {
    const pageMessage = usePageMessage();
    const clientId = useActingClientID();

    const [ investors, setInvestors ] = useState<Investor[]>([]);
    const [ showInactive, setShowInactive ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            setInvestors(await api.client.investors.getInvestors(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching investors', error);
        }
    }, [
        clientId, setInvestors, pageMessage
    ]));

    const investorsContextValue = useMemo(() => ({ investors }), [ investors ]);
    const fetchProducts = useCallback(() => (
        api.products.getAllProducts(clientId)
    ), [ clientId ]);

    const [ canManageProduct ] = useHasPermission([ PermissionType.MANAGE_PRODUCTS ]);

    const { configurableValues } = useContext(ClientContext);

    return (
        <InvestorsContext.Provider value={investorsContextValue}>
            <AdminPageTemplate
                Context={ProductManagementPageContext}
                disableAddEntity={!canManageProduct}
                disabledAddTooltip="You don't have permission to add a product"
                CreateEditForm={ProductDetailsForm}
                entityName="product"
                DetailPage={ProductDetailPage}
                EntityGroupComponent={ProductGroup}
                fetchEntities={fetchProducts}
                sortEntitiesBy="isActive"
                filterByLabel="name or code"
                classes={{ entityGroupContainer: styles.entityGroupContainer }}
                filterEntity={({ productDescription, productCode, isActive }, filterInputValue) => (
                    (productDescription.toLocaleLowerCase().includes(filterInputValue.toLocaleLowerCase())
                    || productCode.toLocaleLowerCase().includes(filterInputValue.toLocaleLowerCase()))
                    && (showInactive ? true : isActive)
                )}
                otherFilters={(
                    <FormControlLabel
                        className={styles.showInactive}
                        control={<Checkbox onChange={() => setShowInactive(!showInactive)} />}
                        label="Show inactive"
                    />
                )}
                visibleGroups={
                    configurableValues?.togglableValues.productType as ProductType[] || Object.values(ProductType)
                }
            />
        </InvestorsContext.Provider>
    );
}

function ProductGroup({
    entities: products,
    group: productType
}: AdminEntityGroupProps<Product, ProductType>) {
    products = products.filter(product => product.productType === productType);

    const fixedProducts = products.filter(product => product.amortizationType === AmortizationType.FIXED);
    const adjustableProducts = products.filter(product => product.amortizationType === AmortizationType.ADJUSTABLE);

    return !products.length ? null : (
        <div>
            <ExpandableHeader
                title={productTypeDisplay[productType]}
                defaultExpand
                disableExpand={false}
                secondaryText={`${products.length} product${products.length === 1 ? '' : 's'}`}
                expandedContent={(
                    <div className={styles.expandedContent}>
                        {!!fixedProducts.length && (
                            <Typography
                                color="textSecondary"
                                className={styles.productSetHeader}
                            >
                                Fixed
                            </Typography>
                        )}

                        {fixedProducts.map(product => (
                            <ProductCard
                                key={product.id}
                                product={product}
                            />
                        ))}

                        {!!adjustableProducts.length && (
                            <Typography
                                color="textSecondary"
                                className={styles.productSetHeader}
                            >
                                Adjustable
                            </Typography>
                        )}

                        {adjustableProducts.map(product => (
                            <ProductCard
                                key={product.id}
                                product={product}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    );
}
