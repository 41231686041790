import { LoanDocument } from '@api';
import {
    createDate, getRandomItemFromArray, randomBoolean, randomNum
} from '@tsp-ui/core/utils';
import {
    DefaultBodyType, ResponseResolver, RestContext, RestRequest, rest
} from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../../customer/due-diligence-step/due-diligence-step-mocks';


let loanDocumentID = 400;

type MswHandler = ResponseResolver<
    RestRequest<
        Record<string, any>,
        Record<string, string>
    >,
    RestContext,
    DefaultBodyType
>;

const getLoanDocumentsHandler: MswHandler = (req, res, ctx) => res(
    ctx.status(200),
    ctx.delay(500),
    ctx.json(getLoanDocuments(req.params.loanID))
);

const getLoanDocumentUrlHandler: MswHandler = (req, res, ctx) => res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json({
        preSignedUrl: SAMPLE_PDF_URL,
        expiration: createDate(.001).toISOString() // 1.44 minutes from now
    })
);

const uploadLoanDocumentHandler: MswHandler = (req, res, ctx) => res(
    ctx.status(200),
    ctx.json<LoanDocument>({
        id: String(loanDocumentID++),
        loanId: req.params.loanID,
        name: req.body.file.name,
        containerName: 'Unnamed Container',
        instanceCount: 1,
        url: SAMPLE_PDF_URL,
        uploadedAt: '',
        uploadedByUserId: '1'
    })
);

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/loan/:loanID/document'), getLoanDocumentsHandler),
    rest.get(getMockUrl('/client/:clientID/customer/:customerId/loan/:loanID/document'), getLoanDocumentsHandler),
    rest.get(getMockUrl('/client/:clientID/loan/:loanID/document/:documentId'), getLoanDocumentUrlHandler),
    rest.get(getMockUrl('/client/:clientID/customer/:customerId/loan/:loanID/document/:documentId'), getLoanDocumentUrlHandler),
    rest.post(getMockUrl('/client/:clientID/loan/:loanID/document'), uploadLoanDocumentHandler)
];

const containers = [
    {
        name: 'Lock Confirmation',
        docs: [
            'Float Lock Agreement', 'Lock In Agreement', 'Lock Extension Approval'
        ]
    },
    {
        name: 'Purchase Advice',
        docs: [
            'Purchase Advice Or Confirmation', 'Asset Checklist', 'Repurchase Requests'
        ]
    },
    {
        name: 'Borrower Information',
        docs: [
            'Credit History Report', 'Background Check', 'Transaction Records'
        ]
    }
];

function getLoanDocuments(loanID: string): LoanDocument[] {
    return [
        ...(randomBoolean()
            ? []
            : Array(randomNum(1, 8)))
    ].map(() => {
        const container = getRandomItemFromArray(containers);

        return {
            id: String(loanDocumentID++),
            loanId: loanID,
            name: getRandomItemFromArray(container.docs),
            containerName: container.name,
            instanceCount: 1,
            url: getMockUrl('/document/loan/someLoanID/someContainer/someFilename'),
            uploadedAt: '2024-08-19T12:30:00Z',
            uploadedByUserId: '1'
        };
    }).reduce((documents, currentDoc) => { // increment instanceCount on nonunique docNames
        const duplicate = documents.find(({ name }) => name === currentDoc.name);

        if (duplicate) {
            currentDoc.id = duplicate.id;
            currentDoc.instanceCount += 1;
        }

        return documents.concat([ currentDoc ]);
    }, [] as LoanDocument[]);
}

