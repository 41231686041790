import { CustomerWireInfo, PermissionType } from '@api';
import { Typography } from '@mui/material';
import {
    Button, CardDialogContent, DialogActions, ExpandableHeader
} from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './WireInstructionsListContent.module.scss';
import WireInstructionsCard from './components/WireInstructionsCard';


export interface WireInstructionsListContentProps {
    wireInstructionsArray: CustomerWireInfo[];
    onApprove: (id: string) => void;
    onToggleActive: (id: string) => void;
    setWireInstructionsToEdit: Dispatch<SetStateAction<{} | CustomerWireInfo | undefined>>;
}

export default function WireInstructionsListContent({
    wireInstructionsArray, onApprove, onToggleActive, setWireInstructionsToEdit
}: WireInstructionsListContentProps) {
    const [ loading, setLoading ] = useState(false);

    const needsApproval = wireInstructionsArray?.filter(({ isApproved, isActive }) => !isApproved && isActive);
    const active = wireInstructionsArray?.filter(({ isApproved, isActive }) => isActive && isApproved);
    const inactive = wireInstructionsArray?.filter(({ isActive }) => !isActive);

    const cardProps = (wireInstructions: CustomerWireInfo) => ({
        key: wireInstructions.id,
        wireInstructions,
        wireInstructionsArray,
        onApprove,
        onToggleActive,
        setWireInstructionsToEdit,
        setLoading
    });

    const [ canEditCustomerWireInfo ] = useHasPermission([ PermissionType.EDIT_WIRE_INFO ]);

    return (
        <>
            <CardDialogContent className={styles.content}>
                <ExpandableHeader
                    title="Needs approval"
                    defaultExpand={needsApproval.length > 0}
                    disableExpand={false}
                    secondaryText={`${needsApproval.length} wire instruction${needsApproval.length === 1 ? '' : 's'}`}
                    expandedContent={(
                        <div className={styles.expandedContent}>
                            {needsApproval.length === 0 ? (
                                <Typography variant="body2">
                                    No wire instructions require approval at this time
                                </Typography>
                            ) : needsApproval.map(wireInstructions => (
                                <WireInstructionsCard {...cardProps(wireInstructions)} />
                            ))}
                        </div>
                    )}
                />

                <ExpandableHeader
                    title="Active"
                    defaultExpand={active.length > 0}
                    disableExpand={false}
                    secondaryText={`${active.length} wire instruction${active.length === 1 ? '' : 's'}`}
                    expandedContent={(
                        <div className={styles.expandedContent}>
                            {active.length === 0 ? (
                                <Typography variant="body2">
                                    No wire instructions are currently active for this customer
                                </Typography>
                            ) : active.map(wireInstructions => (
                                <WireInstructionsCard {...cardProps(wireInstructions)} />
                            ))}
                        </div>
                    )}
                />

                <ExpandableHeader
                    title="Inactive"
                    defaultExpand={inactive.length > 0}
                    disableExpand={false}
                    secondaryText={`${inactive.length} wire instruction${inactive.length === 1 ? '' : 's'}`}
                    expandedContent={(
                        <div className={styles.expandedContent}>
                            {inactive.length === 0 ? (
                                <Typography variant="body2">
                                    No wire instructions are currently inactive for this customer
                                </Typography>
                            ) : inactive.map(wireInstructions => (
                                <WireInstructionsCard {...cardProps(wireInstructions)} />
                            ))}
                        </div>
                    )}
                />
            </CardDialogContent>

            <DialogActions loading={loading}>
                <Button
                    variant="contained"
                    onClick={() => setWireInstructionsToEdit({})}
                    disabled={loading || !canEditCustomerWireInfo}
                    tooltip={canEditCustomerWireInfo ? '' : 'You do not have permission to add new wire instructions'}
                >
                    Add new
                </Button>
            </DialogActions>
        </>
    );
}
