import api, {
    CustomerCompensationType,
    CustomerFeesComp,
    FeeAmountType,
    FeeType,
    PermissionType,
    PurchaseAdviceFee,
    customerCompensationPercentageOfDisplay,
    customerTypeDisplay
} from '@api';
import { AddCircleOutline } from '@mui/icons-material';
import { FormLabel, Typography } from '@mui/material';
import {
    CurrencyField,
    FilledSection,
    IconButton,
    PercentField,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import {
    Dispatch, SetStateAction, useContext, useEffect
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import { CompensationDialogProps } from './CompensationDialog';
import styles from './CompensationDialogForm.module.scss';
import CustomerCustomFeeCard from './CustomerCustomFeeCard';
import CustomerPurchaseAdviceFeeCard from './CustomerPurchaseAdviceFeeCard';


interface CompensationDialogFormProps extends CompensationDialogProps {
    purchaseAdviceFees: PurchaseAdviceFee[];
    compensationDetails?: CustomerFeesComp;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export const COMPENSATION_FORM_ID = 'compensation-form';

export default function CompensationDialogForm({
    purchaseAdviceFees, compensationDetails, closeTo, setLoading
}: CompensationDialogFormProps) {
    const navigate = useNavigate();

    const { id: clientID } = useGetCurrentAccount();
    const { customer, refreshCustomer } = useContext(CustomerDetailContext);
    const { id: customerID } = customer!;

    const formMethods = useForm<CustomerFeesComp>({
        defaultValues: compensationDetails
    });
    const {
        watch, setValue, getValues, reset
    } = formMethods;

    const compensationType = watch('compensation.type');

    const feesArray = useFieldArray<CustomerFeesComp>({
        name: 'fees',
        control: formMethods.control
    });

    const customFees = feesArray.fields.filter(fee => !fee.purchaseAdviceFeeId);

    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formValues: CustomerFeesComp) => {
        setLoading(true);

        try {
            await api.customer.compensation.updateFeesComp(
                clientID, customerID, formValues
            );
            await refreshCustomer();

            navigate(closeTo);

            pageMessage.success('Compensation details saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the compensation details', error);
        }

        setLoading(false);
    });

    useEffect(() => {
        if (compensationType === CustomerCompensationType.FIXED) {
            setValue('compensation.percentage', undefined);
            setValue('compensation.percentageOf', undefined);
            setValue('compensation.minAmount', undefined);
            setValue('compensation.maxAmount', undefined);
        } else if (compensationType === CustomerCompensationType.PERCENTAGE) {
            setValue('compensation.amount', undefined);
        }
    }, [ compensationType, setValue ]);

    const [ canManageFeesComp ] = useHasPermission([ PermissionType.MANAGE_FEE_COMPENSATION ]);

    return (
        <form
            className={styles.root}
            id={COMPENSATION_FORM_ID}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                <div>
                    <Switch<CustomerFeesComp>
                        name="compensation.firstLienOnly"
                        label="Compensation only paid for the 1st lien of a combo"
                        disabled={!canManageFeesComp}
                    />

                    <div className={styles.container}>
                        <div>
                            <FormLabel>
                                Compensation type
                            </FormLabel>

                            <RadioGroup<CustomerFeesComp>
                                name="compensation.type"
                                defaultValue={CustomerCompensationType.FIXED}
                            >
                                <Radio
                                    disabled={!canManageFeesComp}
                                    value={CustomerCompensationType.FIXED}
                                    label={customerTypeDisplay[CustomerCompensationType.FIXED]}
                                />

                                <Radio
                                    disabled={!canManageFeesComp}
                                    value={CustomerCompensationType.PERCENTAGE}
                                    label={customerTypeDisplay[CustomerCompensationType.PERCENTAGE]}
                                />
                            </RadioGroup>
                        </div>

                        {compensationType === CustomerCompensationType.FIXED ? (
                            <CurrencyField<CustomerFeesComp>
                                name="compensation.amount"
                                label="Compensation amount"
                                required
                                hideRequiredIndicator
                            />
                        ) : (
                            <div className={styles.percentageContent}>
                                <div className={styles.firstRow}>
                                    <PercentField
                                        name="compensation.percentage"
                                        className={styles.percentageInput}
                                        percentVariant="adorned"
                                    />

                                    <Typography
                                        variant="body2"
                                        className={styles.separationText}
                                    >
                                        of the:
                                    </Typography>

                                    <TextField<CustomerFeesComp>
                                        className={styles.loanAmount}
                                        name="compensation.percentageOf"
                                        label="Loan amount type"
                                        select
                                        required
                                        hideRequiredIndicator
                                    >
                                        {renderEnumOptions(customerCompensationPercentageOfDisplay)}
                                    </TextField>
                                </div>

                                <CurrencyField<CustomerFeesComp>
                                    name="compensation.minAmount"
                                    label="Min compensation"
                                    required
                                    hideRequiredIndicator
                                />

                                <CurrencyField<CustomerFeesComp>
                                    name="compensation.maxAmount"
                                    label="Max compensation"
                                    required
                                    hideRequiredIndicator
                                />
                            </div>
                        )}
                    </div>

                    <TextField<CustomerFeesComp>
                        name="compensation.notes"
                        fullWidth
                        label="Notes"
                        multiline
                        rows={3}
                    />
                </div>

                <div>
                    {purchaseAdviceFees ? (
                        <FilledSection
                            className={styles.feesSection}
                            header={(
                                <>
                                    Fees
                                    <IconButton
                                        tooltip={canManageFeesComp ? 'Add fee' : 'You do not have permission to add fees'}
                                        disabled={!canManageFeesComp}
                                        className={styles.addFeeButton}
                                        onClick={() => feesArray.append({
                                            id: undefined,
                                            customerId: customerID,
                                            type: '' as FeeType,
                                            amount: 0,
                                            amountType: FeeAmountType.FIXED,
                                            isActive: true
                                        })}
                                    >
                                        <AddCircleOutline color="secondary" />
                                    </IconButton>
                                </>
                            )}
                        >
                            {purchaseAdviceFees.map(fee => (
                                <CustomerPurchaseAdviceFeeCard
                                    customerId={customerID}
                                    fee={fee}
                                    feesArray={feesArray}
                                    key={fee.id}
                                />
                            ))}

                            {customFees.length ? (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={styles.additionalFees}
                                >
                                    Additional customer fees
                                </Typography>
                            ) : null}

                            {customFees.map(fee => (
                                <CustomerCustomFeeCard
                                    key={fee.id}
                                    index={feesArray.fields.findIndex(formFee => formFee.id === fee.id)}
                                    onRemove={(index) => {
                                        feesArray.remove(index);
                                        reset(getValues()); // this resets the default values of the field to undefined
                                    }}
                                />
                            ))}
                        </FilledSection>
                    ) : (
                        <Typography variant="body2">
                            No applicable fees for the selected customer.
                        </Typography>
                    )}
                </div>
            </FormProvider>
        </form>
    );
}
