import { createDate, randomNum } from '@tsp-ui/core/utils';

import { apiUtils } from '../api-utils';
import { getCustomers } from '../customer/customer-api';
import { CustomerStatus } from '../enums/customerStatusEnum';
import { PricingUploadBatch } from '../pricing/pricing-api';
import { Product, getAllProducts } from '../products/products-api';


let commitments: BulkCommitment[] | null = null;

export async function getCommitments(clientId: string): Promise<BulkCommitment[]> {
    if (commitments !== null) {
        return Promise.resolve(commitments);
    }

    // TODO post-demo, was having issues with msw where the data coming back doesn't match mocks
    // leaving this here for now so it works during the demo
    const customers = await getCustomers(clientId);
    const products = await getAllProducts(clientId);

    const activeCustomers = customers.filter(({ status }) => status === CustomerStatus.ACTIVE);

    const id1 = activeCustomers[0].id;
    const id2 = activeCustomers[1].id;

    commitments = [
        {
            ...getMockedCommitment(id1, products),
            lockDate: createDate(-60).toISOString(),
            deliveryExpiration: createDate(-30).toISOString()
        },
        {
            ...getMockedCommitment(id2, products),
            lockDate: createDate(-60).toISOString(),
            deliveryExpiration: createDate(-30).toISOString()
        },
        {
            ...getMockedCommitment(id2, products),
            lockDate: createDate(-60).toISOString(),
            deliveryExpiration: createDate(-30).toISOString()
        },
        getMockedCommitment(id1, products),
        getMockedCommitment(id1, products),
        getMockedCommitment(id1, products),
        getMockedCommitment(id1, products),
        getMockedCommitment(id2, products),
        getMockedCommitment(id2, products),
        getMockedCommitment(id2, products)
    ];

    return Promise.resolve(commitments);
}

export function getCommitment(clientId: string, commitmentId: string): Promise<BulkCommitmentDetails> {
    return apiUtils.get(`/client/${clientId}/bulk-commitment/${commitmentId}`);
}

export function createCommitment(clientId: string, commitment: Omit<BulkCommitment, 'id'>): Promise<BulkCommitment> {
    return apiUtils.post(`/client/${clientId}/bulk-commitment`, commitment);
}

export function updateCommitment(clientId: string, commitment: BulkCommitment): Promise<BulkCommitment>  {
    return apiUtils.put(`/client/${clientId}/bulk-commitment/${commitment.id}`, commitment);
}

export function uploadToCommitment(
    clientId: string, commitmentId: string, formData: FormData
): Promise<PricingUploadBatch> {
    return apiUtils.post(`/client/${clientId}/bulk-commitment/${commitmentId}/upload`, formData, { useAutoContentType: true });
}

export interface BulkCommitment {
    id: string;
    customerId: string;
    lockDate: string;
    lockWindow: number;
    deliveryExpiration: string;
    tradeAmount: number;
    tradeIncentive: number;
    tradeVariance: number;
    pricingTiers: BulkCommitmentPricingTier[];
}

export interface BulkCommitmentDetails extends Omit<BulkCommitment, 'pricingTiers'> {
    pricingTiers: BulkCommitmentPricingTierDetails[];
}

export interface BulkCommitmentPricingTier {
    productId: string;
    noteRate?: number;
    price: number;
    subLimit?: number;
}

export interface BulkCommitmentPricingTierDetails extends BulkCommitmentPricingTier {
    loans: BulkCommitmentLoan[];
}

export interface BulkCommitmentLoan {
    loanNumber: string;
    lockDate: string;
    lockExpiration: string;
    loanAmount: number;
    status: CommitmentLoanStatus;
}

export enum CommitmentLoanStatus {
    SOLD = 'SOLD',
    PURCHASED = 'PURCHASED',
    DELIVERED = 'DELIVERED',
    UNDELIVERED = 'UNDELIVERED'
}

export const commitmentStatusDisplay = {
    [CommitmentLoanStatus.SOLD]: 'Sold',
    [CommitmentLoanStatus.PURCHASED]: 'Purchased',
    [CommitmentLoanStatus.DELIVERED]: 'Delivered',
    [CommitmentLoanStatus.UNDELIVERED]: 'Undelivered'
};

let commitmentId = 10000;
let loanNumber = 71435423;

function getMockedCommitment(customerId: string = '1', products: Product[]): BulkCommitmentDetails {
    return {
        id: `${commitmentId++}`,
        customerId,
        deliveryExpiration: createDate(60).toISOString(),
        lockDate: createDate(30).toISOString(),
        lockWindow: 30,
        pricingTiers: [
            {
                productId: products[0].id,
                noteRate: 6.375,
                price: 100.145,
                subLimit: undefined,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-1).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-2).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-3).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            },
            {
                productId: products[0].id,
                noteRate: 6.500,
                price: 100.145,
                subLimit: 3000000,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-4).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-5).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-6).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            },
            {
                productId: products[0].id,
                noteRate: 6.625,
                price: 100.145,
                subLimit: 2000000,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-7).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-8).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-9).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            }
        ],
        tradeAmount: 6000000,
        tradeIncentive: 0.025,
        tradeVariance: 1.000
    };
}
