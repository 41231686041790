import { Tooltip, Typography } from '@mui/material';


interface ConditionsDisplayProps {
    numConditions: number;
}

export function ConditionsDisplay({ numConditions }: ConditionsDisplayProps) {
    const conditionsDisplay = !numConditions
        ? 'No conditions'
        : numConditions === 1
            ? '1 condition'
            : `${numConditions} conditions`;

    return (
        <Tooltip title={conditionsDisplay}>
            <Typography
                color={numConditions ? 'error' : 'textSecondary'}
                fontWeight={numConditions ? 500 : 400}
            >
                {numConditions}
            </Typography>
        </Tooltip>
    );
}
