import api, {
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    Borrower,
    CommitmentType,
    DocumentationType,
    LoanDetail, LoanType, NumUnits,
    SpecialtyProgram,
    amortizationTypeDisplay, automatedUwRecommendationDisplay, automatedUwSystemDisplay,
    commitmentTypeDisplay, documentationTypeDisplay,
    loanPurposeDisplay, loanTypeDisplay, numUnitsDisplay,
    occupancyTypeDisplay, propertyTypeDisplay,
    specialtyProgramDisplay
} from '@api';
import {
    AttachMoney, ExpandMore, HowToReg, LocalAtm, Place, TrendingUp, Verified
} from '@mui/icons-material';
import {
    DialogContent, Divider, IconButton, Tooltip, Typography
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import {
    AddressTypography, DiffIcon, DiffLabeledValue, DiffLabeledValueProps,
    DiffValue, EmailLink, FilterTextField, IconTypography, LabelGroup,
    RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/core/components';
import {
    formatCurrency, getFullName, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import React, {
    Fragment, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { LoanDetailContext, LoanStatusButton } from '../LoanDetailPage';
import { PendingLoanDetailContext } from '../PendingLoanDetailPage';

import styles from './LoanDataDialog.module.scss';


type DiffLabeledValuePropsBase = Omit<DiffLabeledValueProps<LoanDetail, keyof LoanDetail>, 'property' | 'fieldSet' | 'renderValue'>;

interface LocationState {
    isPendingLoan: boolean;
}

interface LoanDataDialogProps extends RoutedDialogImplProps {
    originalLoan?: LoanDetail;
    updatedLoan?: LoanDetail;
}

export default function LoanDataDialog({ originalLoan, updatedLoan, ...props }: LoanDataDialogProps) {
    const { loanDetail: loanDetailFromContext } = useContext(LoanDetailContext || PendingLoanDetailContext);
    const [ loading, setLoading ] = useState(!loanDetailFromContext);
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail | undefined>(loanDetailFromContext);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);
    const { loanID } = useParams<{ loanID: string }>();
    const location = useLocation();
    const { isPendingLoan } = (location.state as LocationState) || { isPendingLoan: false };
    const displayLoan = updatedLoan || originalLoan || loanDetail;

    const { id: clientID, customerId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        if (!loanDetail) {
            try {
                if (!isPendingLoan) {
                    setLoanDetail(await api.loans.getLoanDetail(clientID, loanID, customerId));
                } else {
                    setLoanDetail(await api.loans.getProspectiveLoanDetail(clientID, loanID, customerId));
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching loan details', error);
            }
            setLoading(false);
        }
    }, [
        loanDetail, isPendingLoan, clientID, loanID, customerId, pageMessage
    ]));

    const diffProps: DiffLabeledValuePropsBase  = {
        original: originalLoan || loanDetail,
        updated: updatedLoan || loanDetail,
        ignoreDelete: true,
        variants: {
            value: 'body1' as Variant,
            label: 'body2' as Variant
        },
        hideIfUnchanged: false,
        label: ''
    } as const;

    /**
     * TODO post-demo idea for improvement: use context and some components to
     * handle some of this for us. Create SearchableFieldGroup and SearchableField
     * components where the group knows the fields that are below it in the tree
     * so we don't need to keep logic like this:
     * `(include([ 'borrowers' ])) && render fields` in sync.
     */
    const filterLoanFields = useCallback((loan: LoanDetail) => {
        if (!loan) {
            return [];
        }
        return (Object.keys(loan) as (keyof LoanDetail)[])
            .filter(
                key => String(loan[key]).toLowerCase().includes(debouncedTerm.toLowerCase())
                || (loanFieldLabels[key] || '').toLowerCase().includes(debouncedTerm.toLowerCase())
            );
    }, [ debouncedTerm ]);

    const filteredFields = displayLoan ? filterLoanFields(displayLoan) : [];

    const includeField = (field: (keyof LoanDetail)) => filteredFields.includes(field);

    if (!displayLoan) {
        return null;
    }

    return (
        <RoutedDialog
            {...props}
            title="Loan data"
            maxWidth="xl"
            loading={loading}
        >
            {displayLoan && (
                <>
                    <DialogContent className={styles.headerContent}>
                        <Typography>
                            Loan #
                            <Typography
                                component="span"
                                fontWeight={500}
                            >
                                {displayLoan.loanNumber || displayLoan.customerLoanNumber}
                            </Typography>
                        </Typography>

                        <FilterTextField
                            placeholder="Find a loan field"
                            helperText="Search by field label or value"
                            onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                        />

                        {!isPendingLoan && (
                            <LoanStatusButton
                                status={displayLoan.loanStatus}
                                loanID={displayLoan.id}
                            />
                        )}
                    </DialogContent>

                    <DialogContent className={styles.content}>
                        <div className={styles.column}>
                            <LoanInfo
                                includeField={includeField}
                                diffProps={diffProps}
                            />
                        </div>

                        <div className={styles.column}>
                            <PropertyInfo
                                includeField={includeField}
                                diffProps={diffProps}
                            />

                            <AdditionalData
                                includeField={includeField}
                                diffProps={diffProps}
                            />
                        </div>
                    </DialogContent>
                </>
            )}
        </RoutedDialog>
    );
}

interface SectionProps {
    includeField: (field: keyof LoanDetail) => boolean;
    diffProps: DiffLabeledValuePropsBase;
}

function LoanInfo({
    includeField, diffProps
}: SectionProps) {
    const [ showArmDetails, setShowArmDetails ] = useState(false);

    function shouldShowExpandButton() {
        const currentAmortType = diffProps.updated?.amortizationType ?? diffProps.original?.amortizationType;
        const isAdjustable = currentAmortType === 'ADJUSTABLE';
        return isAdjustable;
    }

    return (
        <>
            <div>
                <Typography className={styles.header}>Loan Information</Typography>

                <div className={styles.section}>
                    <div className={styles.infoHeader}>
                        {includeField('loanType') && diffProps.original && diffProps.updated && (
                            <div className={styles.diffValue}>
                                <DiffValue
                                    originalValue={diffProps.original.loanType}
                                    updatedValue={diffProps.updated.loanType}
                                    renderValue={(type: LoanType) => (
                                        <Tooltip title={loanFieldLabels['loanType']}>
                                            <Typography>{loanTypeDisplay[type]}</Typography>
                                        </Tooltip>
                                    )}
                                />
                            </div>
                        )}

                        <div className={styles.icons}>
                            {includeField('escrowsFlag') && diffProps.original && diffProps.updated && (
                                <DiffIcon
                                    originalValue={diffProps.original.escrowsFlag}
                                    updatedValue={diffProps.updated.escrowsFlag}
                                    renderIcon={(value) => (
                                        <Tooltip title={`Taxes & insurance ${value ? '' : 'not'} escrowed`}>
                                            <LocalAtm
                                                color={value ? 'primary' : 'disabled'}
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    )}
                                />
                            )}

                            {includeField('interestOnlyFlag') && diffProps.original && diffProps.updated && (
                                <DiffIcon
                                    originalValue={diffProps.original.interestOnlyFlag}
                                    updatedValue={diffProps.updated.interestOnlyFlag}
                                    renderIcon={value => (
                                        <Tooltip title={`${value ? '' : 'Not'} Interest Only`}>
                                            <TrendingUp
                                                color={value ? 'primary' : 'disabled'}
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    )}
                                />
                            )}
                        </div>
                    </div>

                    {includeField('loanAmount') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.loanAmount}
                                updatedValue={diffProps.updated.loanAmount}
                                renderValue={amount => (
                                    <Tooltip title={loanFieldLabels['loanAmount']}>
                                        <Typography>{formatCurrency(amount)}</Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    )}

                    {includeField('loanTerm') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.loanTerm}
                                updatedValue={diffProps.updated.loanTerm}
                                renderValue={term => (
                                    <Tooltip title={loanFieldLabels['loanTerm']}>
                                        <Typography align="right">
                                            {term ? `${term} months` : 'Term not specified'}
                                        </Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    )}

                    {includeField('interestRate') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.interestRate}
                                updatedValue={diffProps.updated.interestRate}
                                renderValue={rate => (
                                    <Tooltip title={loanFieldLabels['interestRate']}>
                                        <Typography>{rate ? `${rate.toFixed(3)}%` : '--'}</Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    )}

                    {includeField('lockPeriod') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.lockPeriod}
                                updatedValue={diffProps.updated.lockPeriod}
                                renderValue={period => (
                                    <Tooltip title={loanFieldLabels['lockPeriod']}>
                                        <Typography align="right">
                                            {period ? `${period} days` : 'Lock period not specified'}
                                        </Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>

                    )}

                    {includeField('amortizationType') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.amortizationType}
                                updatedValue={diffProps.updated.amortizationType}
                                renderValue={(type) => (
                                    <Tooltip title={loanFieldLabels['amortizationType']}>
                                        <Typography>{type ? amortizationTypeDisplay[type] : '--'}</Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    )}

                    {shouldShowExpandButton() && (includeField('armMargin') || includeField('armInitialCap') || includeField('armSubsequentCap') || includeField('armLifeCap')) && (
                        <Tooltip title="Show amortization details">
                            <IconButton
                                size="small"
                                className={styles.showArmButton}
                                onClick={() => setShowArmDetails(!showArmDetails)}
                            >
                                <ExpandMore
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    )}


                    {showArmDetails && (
                        <LabelGroup className={styles.armDetails}>
                            {includeField('armMargin') && (
                                <DiffLabeledValue
                                    {...diffProps}
                                    property="armMargin"
                                    label={`${loanFieldLabels['armMargin']}:`}
                                    renderValue={margin => (margin ? `${margin}%` : '--')}
                                />
                            )}

                            {includeField('armInitialCap') && (
                                <DiffLabeledValue
                                    {...diffProps}
                                    property="armInitialCap"
                                    label={`${loanFieldLabels['armInitialCap']}:`}
                                    renderValue={cap => (cap ? `${cap}%` : '--')}
                                />
                            )}

                            {includeField('armSubsequentCap') && (
                                <DiffLabeledValue
                                    {...diffProps}
                                    property="armSubsequentCap"
                                    label={`${loanFieldLabels['armSubsequentCap']}:`}
                                    renderValue={cap => (cap ? `${cap}%` : '--')}
                                />
                            )}

                            {includeField('armLifeCap') && (
                                <DiffLabeledValue
                                    {...diffProps}
                                    property="armLifeCap"
                                    label={`${loanFieldLabels['armLifeCap']}:`}
                                    renderValue={cap => (cap ? `${cap}%` : '--')}
                                />
                            )}
                        </LabelGroup>
                    )}
                </div>
            </div>

            {includeField('borrowers') && (
                <div>
                    <Typography className={styles.header}>
                        Borrowers
                    </Typography>

                    <div className={styles.borrowers}>
                        {(diffProps.updated?.borrowers
                        || diffProps.original?.borrowers)?.map((borrower: Borrower, index: number) => (
                            <Fragment key={borrower.id}>
                                <BorrowerCard
                                    borrower={borrower}
                                    diffProps={diffProps}
                                />

                                {index < (
                                    diffProps.updated?.borrowers || diffProps.original?.borrowers || []
                                ).length - 1 && (
                                    <Divider className={styles.divider} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

interface BorrowerProps {
    borrower: Borrower;
    diffProps: DiffLabeledValuePropsBase;
}

function BorrowerCard({ borrower, diffProps }: BorrowerProps) {
    const [ hideSSN, setHideSSN ] = useState(true);
    const [ hideContactInfo, setHideContactInfo ] = useState(true);

    const borrowerDiffProps = {
        ...diffProps,
        original: diffProps.original?.borrowers.find((b) => b.id === borrower.id),
        updated: diffProps.updated?.borrowers.find((b) => b.id === borrower.id)
    };

    function formatSSN(borrower: Borrower) {
        if (!borrower.ssn) {
            return 'N/A';
        }
        return hideSSN ? `*-${borrower.ssn.slice(-4)}` : borrower.ssn;
    }

    function toggleSSNVisibility() {
        setHideSSN(!hideSSN);
    };

    function toggleContactVisibility() {
        setHideContactInfo(!hideContactInfo);
    };

    return (
        <div>
            <div className={styles.borrowerCardHeader}>
                {borrowerDiffProps.original && borrowerDiffProps.updated && (
                    <DiffLabeledValue<Borrower, 'firstName' | 'lastName'>
                        {...borrowerDiffProps}
                        property={[ 'firstName', 'lastName' ]}
                        label=""
                        fieldSet
                        renderValue={({ firstName, lastName }) => (
                            <Tooltip title={hideContactInfo ? 'Show Contact Info' : 'Hide Contact Info'}>
                                <Typography
                                    className={styles.borrowerName}
                                    onClick={toggleContactVisibility}
                                >
                                    {getFullName({
                                        firstName,
                                        lastName
                                    })}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                )}

                <div className={styles.icons}>
                    {borrowerDiffProps.original && borrowerDiffProps.updated && (
                        <DiffIcon
                            originalValue={borrowerDiffProps.original.primaryWageEarner}
                            updatedValue={borrowerDiffProps.updated.primaryWageEarner}
                            renderIcon={value => (
                                <Tooltip
                                    title={`${value ? 'P' : 'Not the p'}rimary wage earner`}
                                    enterDelay={0}
                                >
                                    <AttachMoney
                                        color={value ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        />
                    )}

                    {diffProps.original && diffProps.updated && (
                        <DiffIcon
                            originalValue={diffProps.original.firstTimeHomeBuyer}
                            updatedValue={diffProps.updated.firstTimeHomeBuyer}
                            renderIcon={value => (
                                <Tooltip
                                    title={`${value ? 'F' : 'Not a f'}irst time home buyer`}
                                    enterDelay={0}
                                >
                                    <HowToReg
                                        color={value ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        />
                    )}
                </div>
            </div>

            {!hideContactInfo && (
                <DiffLabeledValue
                    {...borrowerDiffProps}
                    label=""
                    property="email"
                    renderValue={(email) => <EmailLink email={email || ''} />}
                    className={styles.email}
                />
            )}

            <div className={styles.borrowerDetails}>
                <LabelGroup>
                    <DiffLabeledValue
                        {...borrowerDiffProps}
                        property="fico"
                        label="FICO:"
                        renderValue={(fico) => (
                            <IconTypography
                                compact
                                iconPosition="after"
                                fontWeight={400}
                                variant="inherit"
                                icon={(
                                    <Verified
                                        color="primary"
                                        fontSize="inherit"
                                    />
                                )}
                            >
                                {fico}
                            </IconTypography>
                        )}
                    />
                </LabelGroup>

                <LabelGroup>
                    <DiffLabeledValue
                        {...borrowerDiffProps}
                        property="ssn"
                        label="SSN:"
                        renderValue={() => (
                            <Tooltip title={hideSSN ? 'Reveal' : 'Hide'}>
                                <Typography
                                    onClick={toggleSSNVisibility}
                                    variant="body2"
                                    className={styles.ssn}
                                >
                                    {formatSSN(borrower)}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                </LabelGroup>
            </div>
        </div>
    );
}

function PropertyInfo({ includeField, diffProps }: SectionProps) {
    return (
        <div>
            <Typography className={styles.header}>Property Details</Typography>

            <div className={styles.section}>
                {includeField('propertyType') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.propertyType}
                            updatedValue={diffProps.updated.propertyType}
                            renderValue={(type) => (
                                <Tooltip title={loanFieldLabels['propertyType']}>
                                    <Typography>
                                        {type ? propertyTypeDisplay[type] : 'Property type not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('units') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.units}
                            updatedValue={diffProps.updated.units}
                            renderValue={(units) => (
                                <Tooltip title={loanFieldLabels['units']}>
                                    <Typography align="right">
                                        {units ? `${numUnitsDisplay[units]} unit${units !== NumUnits.ONE ? 's' : ''}` : 'Number of units not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('purpose') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.purpose}
                            updatedValue={diffProps.updated.purpose}
                            renderValue={(purpose) => (
                                <Tooltip title={loanFieldLabels['purpose']}>
                                    <Typography>
                                        {purpose ? loanPurposeDisplay[purpose] : 'Loan purpose not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('occupancy') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.occupancy}
                            updatedValue={diffProps.updated.occupancy}
                            renderValue={(type) => (
                                <Tooltip title={loanFieldLabels['occupancy']}>
                                    <Typography align="right">
                                        {type ? occupancyTypeDisplay[type] : 'Occupancy type not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('address') && diffProps.original && diffProps.updated && (
                    <div className={styles.addressWrapper}>
                        <Divider className={styles.divider} />

                        <Tooltip title={loanFieldLabels['address']}>
                            <DiffValue
                                originalValue={diffProps.original.address}
                                updatedValue={diffProps.updated.address}
                                renderValue={address => (
                                    <div className={styles.propertyAddress}>
                                        <Place color="primary" />

                                        <AddressTypography address={address} />
                                    </div>
                                )}
                            />
                        </Tooltip>

                        <Divider className={styles.divider} />
                    </div>
                )}

                <LabelGroup className={styles.justifyStart}>
                    {includeField('appraisedValue') && (
                        <DiffLabeledValue
                            {...diffProps}
                            property="appraisedValue"
                            label="Appraised value:"
                            renderValue={(value) => formatCurrency(value) || '--'}
                        />
                    )}
                </LabelGroup>

                <LabelGroup className={styles.justifyEnd}>
                    {includeField('salePrice') && (
                        <DiffLabeledValue
                            {...diffProps}
                            property="salePrice"
                            label="Sale price:"
                            renderValue={(price) => formatCurrency(price) || '--'}
                        />
                    )}
                </LabelGroup>
            </div>
        </div>
    );
}

function AdditionalData({
    includeField, diffProps
}: SectionProps) {
    function getEnumDisplay<T extends string | number>(
        value: unknown,
        enumObject: { [key: string]: T },
        displayObject: { [key in T]: string }
    ): string {
        return (typeof value === 'string' && Object.values(enumObject).includes(value as T))
            ? displayObject[value as T]
            : '--';
    }

    function getRenderValueForField(key: keyof LoanDetail) {
        return (value: unknown) => {
            if (typeof value === 'boolean') {
                return renderBoolean(value);
            }
            if (typeof value === 'number' && [
                'cashOutAmount', 'subordinatedBalance', 'mortgageInsCoverage', 'subordinatedLoanAmount', 'totalLoanAmount'
            ].includes(key)) {
                return formatCurrency(value);
            }
            switch (key) {
            case 'specialtyProgram':
                return getEnumDisplay(value, SpecialtyProgram, specialtyProgramDisplay);
            case 'documentationType':
                return getEnumDisplay(value, DocumentationType, documentationTypeDisplay);
            case 'commitmentType':
                return getEnumDisplay(value, CommitmentType, commitmentTypeDisplay);
            case 'automatedUwSystem':
                return getEnumDisplay(value, AutomatedUwSystem, automatedUwSystemDisplay);
            case 'automatedUwRecommendation':
                return getEnumDisplay(value, AutomatedUwRecommendation, automatedUwRecommendationDisplay);
            default:
                return value?.toString() || '--';
            }
        };
    }

    const additionalFields: (keyof LoanDetail)[] = [
        'customerName', 'customerId', 'customerLoanNumber', 'productCode', 'specialtyProgram',
        'documentationType', 'subordinatedBalance', 'cashOutAmount', 'limitedLiabilityCorp',
        'commitmentType', 'commitmentIdentifier', 'mortgageInsFlag', 'mortgageInsCompany',
        'mortgageInsCoverage', 'underwriteFlag', 'automatedUwSystem', 'automatedUwRecommendation'
    ];

    return (
        <div>
            <Typography className={styles.header}>Additional Data</Typography>

            <div className={styles.section}>
                <LabelGroup className={styles.justifyStart}>
                    {additionalFields.map((field) => includeField(field) && (
                        <DiffLabeledValue
                            key={field}
                            {...diffProps}
                            property={field}
                            label={`${loanFieldLabels[field]}:`}
                            renderValue={getRenderValueForField(field)}
                            variants={{
                                label: 'body2',
                                value: 'body1'
                            }}
                        />
                    ))}
                </LabelGroup>
            </div>
        </div>
    );
}

const loanFieldLabels: Partial<Record<keyof LoanDetail, string>> = {
    loanNumber: 'Loan number',
    assignee: 'Assignee',
    loanStatus: 'Loan status',
    loanType: 'Loan type',
    loanAmount: 'Loan amount',
    interestRate: 'Interest rate',
    amortizationType: 'Amortization type',
    armMargin: 'Arm margin',
    armInitialCap: 'Arm initial cap',
    armSubsequentCap: 'Arm subsequent cap',
    armLifeCap: 'Arm life cap',
    escrowsFlag: 'Escrows flag',
    interestOnlyFlag: 'Interest only flag',
    loanTerm: 'Loan term',
    lockPeriod: 'Lock period',
    borrowers: 'Borrowers',
    propertyType: 'Property type',
    units: 'Number of units',
    occupancy: 'Occupancy type',
    purpose: 'Purpose',
    address: 'Property address',
    appraisedValue: 'Appraised value',
    salePrice: 'Sale price',
    customerName: 'Customer name',
    customerId: 'Customer ID',
    customerLoanNumber: 'Customer loan number',
    productCode: 'Product code',
    specialtyProgram: 'Specialty program',
    documentationType: 'Documentation type',
    subordinatedBalance: 'Subordinated balance',
    cashOutAmount: 'Cash out amount',
    limitedLiabilityCorp: 'Limited liability corp',
    commitmentType: 'Commitment type',
    commitmentIdentifier: 'Commitment identifier',
    mortgageInsFlag: 'Mortgage ins flag',
    mortgageInsCompany: 'Mortgage ins company',
    mortgageInsCoverage: 'Mortgage ins coverage',
    underwriteFlag: 'Underwrite flag',
    automatedUwSystem: 'AUS',
    automatedUwRecommendation: 'AUR'
};
