import { Contact, PermissionType, User } from '@api';
import { Typography } from '@mui/material';
import {
    Button, CardDialogContent, DialogActions, ExpandableHeader
} from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks';
import { UserOrContact } from '@views/admin/customers/CustomerDetailPage/components/dialogs/UsersAndContactsDialog';
import { UserCard } from '@views/admin/users/UserCard';
import { Dispatch, SetStateAction } from 'react';


import ContactCard from './ContactCard';
import styles from './UsersAndContactsListDialogContent.module.scss';


interface UsersAndContactsListDialogContentProps {
    users: User[];
    setUsers: Dispatch<SetStateAction<User[]>>;
    contacts: Contact[];
    setContacts: Dispatch<SetStateAction<Contact[]>>;
    onAddClick: (type: UserOrContact) => void;
    onEditClick: (item: Contact | User) => void;
}

export function UsersAndContactsListDialogContent({
    users, setUsers, contacts, setContacts, onAddClick, onEditClick
}: UsersAndContactsListDialogContentProps) {
    const [
        canViewContacts, canManageContacts, canViewUsers, canManageUsers
    ] = useHasPermission([
        PermissionType.VIEW_CUSTOMER_CONTACTS,
        PermissionType.MANAGE_CUSTOMER_CONTACTS,
        PermissionType.VIEW_USERS,
        PermissionType.EDIT_USERS
    ]);

    return (
        <>
            <CardDialogContent className={styles.content}>
                <div className={styles.column}>
                    <ExpandableHeader
                        title="Users"
                        defaultExpand
                        disableExpand={false}
                        secondaryText={!canViewUsers ? 'No permission' : `${users.length} user${users.length === 1 ? '' : 's'}`}
                        expandedContent={(
                            <div className={styles.expandableContent}>
                                {!canViewUsers ? (
                                    <Typography>
                                        You do not have permission to view users
                                    </Typography>
                                ) : !users.length ? (
                                    <Typography>
                                        No users exist for this customer
                                    </Typography>
                                ) : users.map(user => (
                                    <UserCard
                                        key={user.id}
                                        user={user}
                                        setUsers={setUsers}
                                        onEditClick={onEditClick}
                                        variant="outlined"
                                    />
                                ))}
                            </div>
                        )}
                    />
                </div>

                <div className={styles.column}>
                    <ExpandableHeader
                        title="Contacts"
                        defaultExpand
                        disableExpand={false}
                        secondaryText={!canViewContacts ? 'No permission' : `${contacts.length} contact${contacts.length === 1 ? '' : 's'}`}
                        expandedContent={(
                            <div className={styles.expandableContent}>
                                {!canViewContacts ? (
                                    <Typography>
                                        You do not have permission to view contacts
                                    </Typography>
                                ) : !contacts.length ? (
                                    <Typography>
                                        No contacts exist for this customer
                                    </Typography>
                                ) : contacts.map(contact => (
                                    <ContactCard
                                        key={contact.id}
                                        contact={contact}
                                        setContacts={setContacts}
                                        onEditClick={onEditClick}
                                    />
                                ))}
                            </div>
                        )}
                    />
                </div>
            </CardDialogContent>

            <DialogActions>
                <Button
                    onClick={() => onAddClick('contact')}
                    disabled={!canManageContacts}
                    tooltip={!canManageContacts ? 'You do not have permission to add a contact' : ''}
                >
                    Add contact
                </Button>

                <Button
                    variant="contained"
                    onClick={() => onAddClick('user')}
                    disabled={!canManageUsers}
                    tooltip={!canManageUsers ? 'You do not have permission to add a user' : ''}
                >
                    Add user
                </Button>
            </DialogActions>
        </>
    );
}
