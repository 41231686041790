import api, { LoanStatusConfig, PermissionType } from '@api';
import {
    ArrowDownward, ArrowUpward, Description, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import {
    IconButton, getItemById, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './LoanStatusConfigCard.module.scss';
import { LoanStatusContext } from './LoanStatusPage';


interface LoanStatusConfigCardProps {
    loanStatusConfig: LoanStatusConfig;
}

export function LoanStatusConfigCard({ loanStatusConfig }: LoanStatusConfigCardProps) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_STATUS_CONFIGS ]);
    const { losLoanStatuses } = useContext(LoanStatusContext);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { id: clientId } = useGetCurrentAccount();

    const { loanStatusConfigs, setLoanStatusConfigs } = useContext(LoanStatusContext);

    async function moveConfig(direction: 'up' | 'down') {
        const setLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setLoading(true);

        try {
            await api.loanStatus.updateLoanStatusConfig(clientId, {
                ...loanStatusConfig,
                displayOrder: loanStatusConfig.displayOrder + (direction === 'up' ? -1 : 1)
            });

            setLoanStatusConfigs(await api.loanStatus.getLoanStatusConfigs(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while moving the loan status configuration', error);
        }

        setLoading(false);
    }

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this loan status configuration?')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.loanStatus.deleteLoanStatusConfig(clientId, loanStatusConfig.id);
            setLoanStatusConfigs(await api.loanStatus.getLoanStatusConfigs(clientId));

            pageMessage.success('Loan status configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the loan status configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <Typography>
                    {loanStatusConfig.title}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {loanStatusConfig.losLoanStatusIds.length
                        ? loanStatusConfig.losLoanStatusIds.map(id => getItemById(losLoanStatuses, id)?.name).join(', ')
                        : 'No LOS loan statuses have been added to this configuration'}
                </Typography>
            </div>

            <span className={styles.icons}>
                {loanStatusConfig.displayOrder === 1 && (
                    <Tooltip title="This loan status requires documents.">
                        <Description
                            color="info"
                            className={styles.info}
                        />
                    </Tooltip>
                )}

                <IconButton
                    loading={moveUpLoading}
                    tooltip={!canManage ? 'You don\t have permission to move configs' : 'Move up'}
                    disabled={loanStatusConfig.displayOrder === 1 || !loanStatusConfig.displayOrder || !canManage}
                    onClick={() => moveConfig('up')}
                >
                    <ArrowUpward color="secondary" />
                </IconButton>

                <IconButton
                    loading={moveDownLoading}
                    tooltip={!canManage
                        ? 'You don\t have permission to move configs' : 'Move down'}
                    disabled={
                        loanStatusConfig.displayOrder >= loanStatusConfigs.length
                    || loanStatusConfig.displayOrder === null
                    || !canManage
                    }
                    onClick={() => moveConfig('down')}
                >
                    <ArrowDownward color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit loan status configuration' : 'You do not have permission to edit loan status configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${loanStatusConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete loan status configuration' : 'You do not have permission to delete loan status configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
