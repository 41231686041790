import api, { LoanDocument } from '@api';
import {
    Close, Description, ExpandLess, ExpandMore, History, UnfoldLess, UnfoldMore
} from '@mui/icons-material';
import { Timeline } from '@mui/lab';
import {
    DialogContent, Divider, IconButton, Popover, Tooltip, Typography
} from '@mui/material';
import {
    FilterTextField, IconTypography, RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { DocumentLink } from '@views/components/DocumentLink';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import {
    Fragment, ReactNode, useCallback, useEffect, useRef, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';


import styles from './LoanDocumentsDialog.module.scss';
import { LoanTimelineContactTimeDisplay, LoanTimelineItem } from './LoanTimeline';


export default function LoanDocumentsDialog(props: RoutedDialogImplProps) {
    const [ documents, setDocuments ] = useState<LoanDocument[]>();
    const [ expandAll, setExpandAll ] = useState(0);
    const [ collapseAll, setCollapseAll ] = useState(0);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);
    const location = useLocation();

    const loanNumber = location.state as string | undefined;

    const { loanID } = useParams<{ loanID: string }>();

    const { id: clientId, customerId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();

    const filteredDocuments = documents?.filter(
        ({ name, containerName }) => name.toLowerCase().includes(
            debouncedTerm.toLowerCase()
        ) || containerName.toLowerCase().includes(debouncedTerm.toLowerCase())
    );

    const containers = [ ...new Set(filteredDocuments?.map(doc => doc.containerName)) ];

    useAsyncEffect(useCallback(async () => {
        try {
            setDocuments(await api.loans.document.getLoanDocuments(clientId, loanID, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }
    }, [
        clientId, loanID, customerId, pageMessage
    ]));

    return (
        <RoutedDialog
            {...props}
            title="Loan documents"
            maxWidth={false}
            loading={!documents}
        >
            <DialogContent className={styles.headerContent}>
                <Typography>
                    Loan #

                    <Typography
                        component="span"
                        fontWeight={500}
                    >
                        {loanNumber}
                    </Typography>
                </Typography>

                <FilterTextField
                    placeholder="Find a loan document"
                    helperText="Search by document or container name"
                    className={styles.filterField}
                    onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                />

                <div className={styles.expandCollapseAllButtons}>
                    <Tooltip title="Expand all">
                        <IconButton
                            size="small"
                            onClick={() => setExpandAll(expandAll + 1)}
                        >
                            <UnfoldMore color="secondary" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Collapse all">
                        <IconButton
                            size="small"
                            onClick={() => setCollapseAll(collapseAll + 1)}
                        >
                            <UnfoldLess color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogContent>

            <Divider className={styles.headerDivider} />

            <DialogContent className={styles.mainContent}>
                <div className={styles.borderedList}>
                    {containers.map((containerName, index) => (
                        <Fragment key={containerName}>
                            <DocumentContainerCard
                                title={containerName}
                                documents={filteredDocuments || []}
                                expandAll={expandAll}
                                collapseAll={collapseAll}
                            />

                            {index < containers.length - 1 && <Divider className={styles.divider} /> }
                        </Fragment>
                    ))}

                    {!containers.length && (
                        <Typography>
                            No documents {documents?.length ? 'match your search prompt' : 'exist for this loan'}
                        </Typography>
                    )}
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}

interface DocumentContainerCardProps {
    title: ReactNode;
    documents: LoanDocument[];
    expandAll: number; // using numbers for expand & collapse all allows us to track when an expand/collapse all event
    collapseAll: number; // takes place while keeping the expanded state local to this component
}

function DocumentContainerCard({
    title, documents, expandAll, collapseAll
}: DocumentContainerCardProps) {
    const [ expanded, setExpanded ] = useState(false);

    const containerDocuments = documents.filter(
        document => document.containerName === title && document.instanceCount === 1
    );

    useEffect(() => setExpanded(true), [ expandAll ]);
    useEffect(() => setExpanded(false), [ collapseAll ]);

    return (
        <>
            <BorderedListItem
                title={(
                    <div className={styles.itemHeader}>
                        <IconButton
                            size="small"
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? (
                                <ExpandLess color="secondary" />
                            ) : (
                                <ExpandMore color="secondary" />
                            )}
                        </IconButton>

                        {title}
                    </div>
                )}
                icons={(
                    <IconTypography
                        compact
                        icon={(
                            <Tooltip title={`${containerDocuments.length} document${containerDocuments.length > 1 ? 's' : ''} in this container`}>
                                <Description
                                    color="primary"
                                    fontSize="small"
                                />
                            </Tooltip>
                        )}
                    >
                        {containerDocuments.length}
                    </IconTypography>
                )}
            />

            {expanded && (
                <ul className={styles.fileList}>
                    {containerDocuments.map(document => (
                        <LoanDocumentItem
                            key={document.id}
                            documentId={document.id}
                            documents={documents}
                        />
                    ))}
                </ul>
            )}
        </>
    );
}

interface LoanDocumentItemProps {
    documents: LoanDocument[];
    documentId: string;
}

export function LoanDocumentItem({ documents, documentId }: LoanDocumentItemProps) {
    const loanDocument = documents.find(document => document.id === documentId && document.instanceCount === 1)!;
    const liRef = useRef<HTMLLIElement | null>(null);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

    return (
        <li ref={liRef}>
            <div className={styles.fileContainer}>
                <DocumentLink
                    name={loanDocument.name}
                    document={loanDocument}
                />

                <Tooltip title="Show file history"/* TODO post-demo implement file history*/>
                    <IconButton
                        size="small"
                        onClick={(event) => {
                            event.preventDefault();
                            setAnchorEl(liRef.current);
                        }}
                    >
                        <History
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            </div>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.fileDetailsPopoverPaper
                }}
            >
                <div className={styles.fileDetailsHeader}>
                    <Typography fontWeight={500}>
                        File history
                    </Typography>

                    <IconButton
                        size="small"
                        className={styles.closeButton}
                        onClick={() => setAnchorEl(null)}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </div>

                <Timeline
                    position="left"
                    className={styles.fileTimeline}
                    nonce={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                >
                    {documents.filter(
                        ({ id }) => id === documentId
                    ).sort((a, b) => b.instanceCount - a.instanceCount).map((document, index, array) => (
                        <LoanTimelineItem
                            key={`${document.id}-v${array.length - index}`}
                            hideTopConnector={index === 0}
                            hideBottomConnector={index === array.length - 1}
                            icon={(
                                <div className={styles.timelineItem}>
                                    {array.length - index}
                                </div>
                            )}
                        >
                            <LoanTimelineContactTimeDisplay
                                text={(
                                    <DocumentLink
                                        document={document}
                                        name={`${document.name} - v${document.instanceCount}`}
                                    />
                                )}
                                userId={document.uploadedByUserId}
                                time={document.uploadedAt ? `${formatDistanceToNowStrict(parseISO(document.uploadedAt))} ago` : 'N/A'}
                            />
                        </LoanTimelineItem>
                    ))}
                </Timeline>
            </Popover>
        </li>
    );
}

interface BorderedListItemProps {
    title: ReactNode;
    icons: ReactNode;
}

function BorderedListItem({ title, icons }: BorderedListItemProps) {
    return (
        <div>
            <div className={styles.itemHeader}>
                {title}

                <div className={styles.icons}>
                    {icons}
                </div>
            </div>
        </div>
    );
}
