import api, { ConditionConfigGroup, LoanType, loanTypeDisplay } from '@api';
import { AccountBalance, CorporateFare, InfoOutlined } from '@mui/icons-material';
import {
    Button, Chip, DialogContent, Divider, Tooltip, Typography
} from '@mui/material';
import {
    getItemById, replaceItemById, useForm, useParams
} from '@tsp-ui/core';
import {
    Autocomplete, DialogActions, IconTypography, RoutedDialog, usePageMessage
} from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { useContext, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { SelectableButton } from '../investor-eligibility/components/ClientInvestorForm';

import styles from './ConditionConfigGroupDialog.module.scss';
import { ConditionConfigContext } from './ConditionConfigPage';


export function ConditionConfigGroupDialog() {
    const [ loading, setLoading ] = useState(false);
    const { conditionConfigGroupId } = useParams();
    const { conditionConfigGroups, loanPrograms, setConditionConfigGroups } = useContext(ConditionConfigContext);
    const { configurableValues } = useContext(ClientContext);
    const conditionConfigGroupToEdit = conditionConfigGroupId
        ? getItemById(conditionConfigGroups, conditionConfigGroupId)
        : undefined;

    const { id: clientId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const formMethods = useForm<ConditionConfigGroup>({
        defaultValues: conditionConfigGroupToEdit
    });
    const { setValue } = formMethods;

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            setConditionConfigGroups(conditionConfigGroupToEdit
                ? replaceItemById(
                    conditionConfigGroups,
                    await api.conditionConfig.updateConditionConfigGroup(clientId, formValues)
                )
                : [
                    ...conditionConfigGroups,
                    await api.conditionConfig.createConditionConfigGroup(clientId, formValues)
                ]);

            pageMessage.success('Condition config group saved');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the condition config group', error);
        }

        setLoading(false);
    });

    const groupType = formMethods.watch('type');
    const groupTypeIsTouched = formMethods.getFieldState('type').isTouched;

    useEffect(() => {
        // Only reset values if group type has been edited
        if (groupTypeIsTouched) {
            setValue('loanTypes', []);
            setValue('loanProgramIds', []);
        }
    }, [
        groupType, groupTypeIsTouched, setValue
    ]);

    return (
        <RoutedDialog
            title={conditionConfigGroupId ? 'Edit condition group' : 'Add condition group'}
            maxWidth="sm"
            closeTo=".."
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <form
                        id="condition-group-form"
                        className={styles.root}
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <Typography align="center">
                            Which type of condition group would you like to add?
                        </Typography>

                        <div className={styles.loanTypeOrLoanProgramButtons}>
                            <SelectableButton
                                selected={groupType === 'loanType'}
                                onClick={() => {
                                    formMethods.setValue('type', 'loanType', { shouldTouch: true });
                                }}
                            >
                                <CorporateFare
                                    color="secondary"
                                    className={styles.icon}
                                />

                                Loan Type
                            </SelectableButton>

                            <SelectableButton
                                selected={groupType === 'loanProgram'}
                                onClick={() => {
                                    formMethods.setValue('type', 'loanProgram', { shouldTouch: true });
                                }}
                                disabled={!loanPrograms.length}
                                title={loanPrograms.length ? '' : 'You cannot create conditions based on loan programs because no loan programs exist for this account.'}
                            >
                                <AccountBalance
                                    color={loanPrograms.length ? 'secondary' : 'disabled'}
                                    className={styles.icon}
                                />

                                Loan Program
                            </SelectableButton>
                        </div>

                        {groupType && (
                            <IconTypography
                                fontWeight={400}
                                variant="caption"
                                className={styles.help}
                                icon={(
                                    <InfoOutlined
                                        color="primary"
                                        fontSize="small"
                                    />
                                )}
                            >
                                The conditions you add to this group will apply to loans with the selected {groupType === 'loanType' ? 'loan types' : 'loan programs'}. You can add conditions to this group later.
                            </IconTypography>
                        )}

                        <Divider className={styles.divider} />

                        {!groupType ? null : groupType === 'loanType' ? (
                            <Autocomplete<ConditionConfigGroup>
                                name="loanTypes"
                                label="Loan types"
                                required
                                fullWidth
                                multiple
                                options={configurableValues?.togglableValues.loanType as string[]
                                    || Object.keys(loanTypeDisplay)}
                                getOptionLabel={loanType => loanTypeDisplay[loanType as LoanType]}
                                disableCloseOnSelect
                            />
                        ) : (
                            <Autocomplete<ConditionConfigGroup>
                                name="loanProgramIds"
                                label="Loan programs"
                                required
                                fullWidth
                                options={loanPrograms.map(({ id }) => id)}
                                getOptionLabel={id => getItemById(loanPrograms, id)?.name}
                                renderOption={(props, option) => (
                                    <Tooltip title={getItemById(loanPrograms, option)?.description || ''}>
                                        <li {...props}>
                                            {getItemById(loanPrograms, option)?.name}
                                        </li>
                                    </Tooltip>
                                )}
                                renderTags={(value, getTagProps) => value.map((option, index) => (
                                    <Tooltip title={getItemById(loanPrograms, option)?.description || ''}>
                                        <Chip
                                            label={getItemById(loanPrograms, option)?.name}
                                            {...getTagProps({ index })}
                                        />
                                    </Tooltip>
                                ))}
                                multiple
                                disableCloseOnSelect
                            />
                        )}
                    </form>
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="condition-group-form"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
