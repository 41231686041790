import api, {
    AmortizationType, AutomatedUwRecommendation, AutomatedUwSystem, CommitmentType,
    ConditionEventType, ConditionPriorTo, ConditionStatus,
    ConditionType, DocumentationType, FileUpload, LoanActivityType, LoanEvent, LoanEventType,
    LoanPurpose, LoanRegistrationResponse, LoanType, NumUnits, OccupancyType,
    PremicorrConditionCategory,
    PricingUploadBatch, PropertyType, RegisteredLoan, RegistrationType, SpecialtyProgram, State, UploadStatus
} from '@api';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    createDate, getItemById, getRandomEnumValue, getRandomItemFromArray, randomBoolean, randomNum, replaceItemByKey
} from '@tsp-ui/core/utils';
import {
    addDays,
    differenceInDays, parseISO, startOfToday, subDays, subHours
} from 'date-fns';
import { DefaultBodyType, MockedRequest, rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { loanStatusConfigs, losLoanStatuses } from '../loan-status-config/loan-status-config-mocks';
import { getMockedPricingResult } from '../pricing/pricing-mocks';
import { clientUsers, customerUsers, internalUsers } from '../user/user-mocks';

import { mocks as loanDocumentMocks } from './document/loanDocument-mocks';
import {
    Comment, EncompassUnderwritingCondition, EntityBase, ForRole, LoanDetail,
    LoanHighlight,
    LoanQueryParams,
    LoanSummary,
    LoanSummaryParams,
    LoanWithActivity,
    UnderwritingConditionEvent
} from './loan-api';


/**
 * This array corresponds to the ids in customerSummaryResults in customer-mocks.ts.
 * We cannot import it here because it would create a circular dependency
 * */
const customerIds = [ ...Array(11) ].map((_, i) => (i + 1).toString());

let loanID = 0;
let clientLoanNumber = 7543670;
let customerLoanNumber = 100;
let loanHighlightID = 500;
let loanTimelineEventID = 700;
let pendingUploadId = 1;
let batchId = 1;
let conditionId = 0;
let pricingResultId = 0;

export function createBatchFromReq(req: MockedRequest<DefaultBodyType>): PricingUploadBatch {
    const reqBody = req.body as any;

    // If it is a single file, reqBody.files is set to the single file, not an array
    const files: File[] = Array.isArray(reqBody.files) ? reqBody.files : [ reqBody.files ];

    return {
        batchId: `${batchId++}`,
        createdDate: new Date().toISOString(),
        status: UploadStatus.PENDING,
        customerId: '1',
        fileCount: files.length,
        submittedBy: 'someone',
        rateSheetId: '3',
        files: files.map((file) => ({
            fileId: `${pendingUploadId++}`,
            fileName: file.name,
            status: UploadStatus.PENDING,
            errors: [],
            loanCount: 3,
            passedValidation: true
        }))
    };
}

function setFileCompleteTimeout(batch: PricingUploadBatch, file: FileUpload) {
    setTimeout(() => {
        const isError = file.fileName.includes('ERR');
        const newFile = {
            ...file,
            status: isError ? UploadStatus.ERROR : UploadStatus.COMPLETE,
            errors: isError ? [
                {
                    prospectiveLoanFileId: '1',
                    fileRowNumber: '2',
                    prospectiveLoanDisplayNumber: '135263465',
                    errorDetails: [ 'Row 2 has no loan number' ]
                },
                {
                    prospectiveLoanFileId: '1',
                    fileRowNumber: '3',
                    prospectiveLoanDisplayNumber: '273548597',
                    errorDetails: [ 'Row 3 has no loan number', "LoanLimitType value 'CONFORMING' is not valid" ]
                }
            ] : []
        };

        api.webSocket.simulateUploadComplete(newFile);
        batch.files = replaceItemByKey(batch.files, newFile, 'fileId');
    }, randomNum(2000, 3000));
}

export const mocks = [
    rest.post(getMockUrl('/client/:clientId/customer/:customerId/loan/file-upload'), (req, res, ctx) => {
        const batch = createBatchFromReq(req);
        batches.push(batch);

        batch.files.forEach((file) => setFileCompleteTimeout(batch, file));

        if (batch.files.every(({ fileName }) => !fileName.includes('ERR'))) {
            setTimeout(() => (
                api.webSocket.simulatePricingComplete({
                    batchId: batch.batchId,
                    results: [
                        getMockedPricingResult('Bill Clinton'),
                        getMockedPricingResult('George Washington')
                    ]
                })
            ), 3000);
        }

        return (res(
            ctx.status(200),
            ctx.json(batch)
        ));
    }),
    rest.get(getMockUrl('/client/:clientId/loan/batch/:batchId'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(batches.find(({ batchId }) => batchId === req.params.batchId))
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/batch/:batchId'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(batches.find(({ batchId }) => batchId === req.params.batchId))
    ))),
    rest.put(getMockUrl('/client/:clientId/customer/:customerId/loan/batch/:batchId/file/:fileId'), (req, res, ctx) => {
        const file = batches.flatMap(({ files }) => files).find(({ fileId }) => (
            fileId === req.params.fileId
        ));

        if (file) {
            file.status = UploadStatus.PENDING;
            file.errors = [];
            file.loanCount = 0;
            file.passedValidation = false;
        }

        setFileCompleteTimeout(batches.find(({ batchId }) => batchId === req.params.batchId)!, file!);

        return res(
            ctx.status(200),
            ctx.json(file)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/loan/batch/:batchId/file/:fileId'), (req, res, ctx) => {
        const file = batches.flatMap(({ files }) => files).find(({ fileId }) => (
            fileId === req.params.fileId
        ));

        if (!file) {
            return res(
                ctx.status(404),
                ctx.json({ message: 'File not found' })
            );
        }

        const blob = new Blob([ 'Mock file content' ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return res(
            ctx.status(200),
            ctx.set('Content-Disposition', `attachment; filename="${file.fileName}"`),
            ctx.body(blob)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/file-upload/template'), (req, res, ctx) => {
        // Create a simple CSV content as an example template
        const templateContent = `LoanNumber,BorrowerName,LoanAmount,InterestRate
      123456,John Doe,250000,3.5
      789012,Jane Smith,300000,3.75
      345678,Bob Johnson,200000,3.25
      `;

        const blob = new Blob([ templateContent ], { type: 'text/csv' });

        return res(
            ctx.status(200),
            ctx.set('Content-Type', 'text/csv'),
            ctx.set('Content-Disposition', 'attachment; filename="loan_upload_template.csv"'),
            ctx.body(blob)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/customer/:customerId/loan/batch/:batchId'), (req, res, ctx) => res(
        ctx.status(204)
    )),
    rest.get(getMockUrl('/client/:clientId/loan/loan-number'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const loanNumber = params.get('loanNumber') || '';

        // Filter loan numbers based on the search query
        const filteredLoanNumbers = loans
            .map(loan => loan.loanNumber)
            .filter(number => number.includes(loanNumber))
            .slice(0, 20);

        return res(
            ctx.status(200),
            ctx.json(filteredLoanNumbers)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/loan-number'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const loanNumber = params.get('loanNumber') || '';

        // Filter loan numbers based on the search query
        const filteredLoanNumbers = loans
            .map(loan => loan.loanNumber)
            .filter(number => number.includes(loanNumber))
            .slice(0, 20);

        return res(
            ctx.status(200),
            ctx.json(filteredLoanNumbers)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const queryParams: LoanQueryParams = {
            loanNumber: params.get('loanNumber') || undefined,
            borrowerName: params.get('borrowerName') || undefined,
            customerId: params.get('customerId') || undefined,
            statusConfigId: parseInt(params.get('statusConfigId') || '0'),
            pageNumber: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('pageSize') || '2')
        };

        const filteredLoans = filterLoans(loans, queryParams);
        const paginatedLoans = paginateLoans(filteredLoans, queryParams.pageNumber, queryParams.pageSize || 0);

        return res(
            ctx.status(200),
            ctx.json(paginatedLoans)
        );
    }),

    rest.get(getMockUrl('/client/:clientId/loan'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const queryParams: LoanQueryParams = {
            loanNumber: params.get('loanNumber') || undefined,
            borrowerName: params.get('borrowerName') || undefined,
            customerId: params.get('customerId') || undefined,
            statusConfigId: parseInt(params.get('statusConfigId') || '0'),
            pageNumber: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('pageSize') || '2')
        };

        const registrationType = params.get('registrationType') as RegistrationType | null;
        let filteredLoans = filterLoans(loans, queryParams);

        if (registrationType) {
            filteredLoans = filteredLoans.filter(loan => loan.registrationType === registrationType);
        }

        const paginatedLoans = paginateLoans(filteredLoans, queryParams.pageNumber, queryParams.pageSize || 0);

        return res(
            ctx.status(200),
            ctx.json(paginatedLoans)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/loan/summary'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const queryParams: LoanSummaryParams = {
            loanNumber: params.get('loanNumber') || undefined,
            borrowerName: params.get('borrowerName') || undefined,
            customerId: params.get('customerId') || undefined
        };

        // Filter loans based on search parameters
        const filteredLoans = loans.filter(
            loan => (!queryParams.loanNumber || loan.loanNumber.toLowerCase().includes(
                queryParams.loanNumber.toLowerCase()
            ))
            && (!queryParams.borrowerName || loan.borrowerName.toLowerCase().includes(
                queryParams.borrowerName.toLowerCase()
            ))
            && (!queryParams.customerId || loan.customerId === queryParams.customerId)
        );

        // Generate summary for all status configs
        const summaries: LoanSummary[] = loanStatusConfigs.map(config => ({
            statusConfigId: config.id,
            loanCount: filteredLoans.filter(loan => config.losLoanStatusIds.includes(losLoanStatuses.find(status => status.name === loan.loanStatus)?.id || '')).length
        }));
        return res(
            ctx.status(200),
            ctx.delay(100),
            ctx.json(summaries)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/loan/highlight'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(generateDynamicLoanHighlights())
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerIdloan/highlight'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(generateDynamicLoanHighlights())
    ))),
    rest.get(getMockUrl('/loan/recent'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(recentlyActiveLoans)
    ))),
    rest.post(getMockUrl('/loan/:loanID/register'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(loanRegistrationResponse)
    ))),
    rest.get(getMockUrl('/client/:clientId/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanDetail(req.params.loanID as string))
    )),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanDetail(req.params.loanID as string))
    )),
    rest.get(getMockUrl('/client/:clientId/loan/:prospectiveLoanId/pending'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanDetail(req.params.prospectiveLoanId as string))
    )),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/:prospectiveLoanId/pending'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanDetail(req.params.prospectiveLoanId as string))
    )),
    rest.put(getMockUrl('/client/:clientId/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json(req.body)
    )),
    rest.put(getMockUrl('/client/:clientId/customer/:customerId/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json(req.body)
    )),
    rest.get(getMockUrl('/loan/:loanID/events'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(getLoanEvents(req.params.loanID as string))
    )),
    rest.get(getMockUrl('/client/:clientId/loan/:loanID/conditions/underwriting'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(underwritingConditions)
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/loan/:loanID/conditions/underwriting'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(underwritingConditions)
    ))),
    rest.post(getMockUrl('/client/:clientId/loan/:loanID/conditions/underwriting'), (req, res, ctx) => {
        const newCondition = req.body as Omit<EncompassUnderwritingCondition, 'id'>;

        const createdCondition: EncompassUnderwritingCondition = {
            ...newCondition,
            id: `${conditionId++}`,
            isAddedToConditionSet: false,
            isCleared: false,
            isFulfilled: false,
            isReceived: false,
            isRejected: false,
            isRemoved: false,
            isRequested: true,
            isRerequested: false,
            isReviewed: false,
            isWaived: false,
            status: ConditionStatus.REQUESTED,
            conditionType: ConditionType.UNDERWRITING,
            createdDate: new Date().toISOString(),
            statusDate: new Date().toISOString(),
            requestedDate: new Date().toISOString(),
            createdBy: {
                entityId: '1',
                entityType: 'User',
                entityName: 'Test User',
                entityUri: ''
            }
        };

        underwritingConditions.push(createdCondition);

        return res(
            ctx.status(201),
            ctx.json(createdCondition)
        );
    }),
    rest.delete(getMockUrl('/client/:clientID/loan/:loanID/conditions/underwriting/:conditionId/comments/:commentId'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/conditions/:conditionId/events'), (req, res, ctx) => {
        const { loanId, conditionId } = req.params;

        const events = generateMockConditionEvents(conditionId as string, loanId as string);

        return res(
            ctx.status(200),
            ctx.json(events)
        );
    }),
    ...loanDocumentMocks
];

const firstNames = [
    'Jeff', 'Jim', 'John', 'Jane'
];
const lastNames = [
    'Burrows', 'Baker', 'Brown', 'Bailey'
];

function getLoans(registrationType?: RegistrationType): RegisteredLoan[] {
    return [ ...Array(randomNum(20, 38)) ].map(() => {
        const loanStatus = getRandomItemFromArray(losLoanStatuses).name;

        return {
            id: String(loanID++),
            loanNumber: String(clientLoanNumber++),
            customerLoanNumber: String(customerLoanNumber++),
            borrowerName: `${getRandomItemFromArray(firstNames)} ${getRandomItemFromArray(lastNames)}`,
            loanAmount: randomNum(20, 500) * 1000,
            loanStatus,
            expirationDate: createDate(randomNum(-15, 180)).toISOString(),
            interestRate: randomNum(4, 8, 3),
            registrationType: registrationType || getRandomEnumValue(RegistrationType),
            customerId: getRandomItemFromArray(customerIds),
            pricingResultId: String(pricingResultId++)
        };
    });
}

export const loans = getLoans();

function needsRepricing(loan: RegisteredLoan): { needsReprice: boolean, daysToExpiration: number } | null {
    if (!loan.expirationDate) {
        return null;
    }
    const daysToExpiration = differenceInDays(parseISO(loan.expirationDate), startOfToday());
    return {
        needsReprice: daysToExpiration < 0,
        daysToExpiration
    };
}

export function generateDynamicLoanHighlights(): LoanHighlight[] {
    const highlights: LoanHighlight[] = [];
    const today = startOfToday();

    loans.forEach(loan => {
        // Check lock expiration
        const lockDaysRemaining = Math.floor(Math.random() * 3);
        if (lockDaysRemaining > 0) {
            highlights.push({
                id: String(loanHighlightID++),
                loanID: loan.id,
                loanNumber: loan.loanNumber,
                highlightType: `Locks expiring in ${lockDaysRemaining} day${lockDaysRemaining > 1 ? 's' : ''}`,
                triggeredAt: subDays(today, Math.floor(Math.random() * 2)).toISOString()
            });
        }

        // Check remaining conditions
        const remainingConditions = Math.floor(Math.random() * 3);
        if (remainingConditions === 1) {
            highlights.push({
                id: String(loanHighlightID++),
                loanID: loan.id,
                loanNumber: loan.loanNumber,
                highlightType: 'One condition left',
                triggeredAt: subDays(new Date(), Math.floor(Math.random() * 3)).toISOString()
            });
        }

        // Check if loan needs repricing
        const repricing = needsRepricing(loan);
        if (repricing?.needsReprice) {
            highlights.push({
                id: String(loanHighlightID++),
                loanID: loan.id,
                loanNumber: loan.loanNumber,
                highlightType: 'Loan needs reprice',
                triggeredAt: addDays(today, repricing.daysToExpiration).toISOString()
            });
        }
    });

    // Sort by most recent first
    return highlights.slice(0, 3).sort((a, b) => parseISO(b.triggeredAt).getTime() - parseISO(a.triggeredAt).getTime());
}

const recentlyActiveLoans: LoanWithActivity[] = loans.map<LoanWithActivity>((loan, index) => ({
    ...loan,
    type: LoanActivityType.VIEW,
    userId: '1',
    date: createDate(randomNum(-2, -1 / 24 / 60, 10)).toISOString() // between 2 days and 1 minute ago
})).sort((a, b) => parseISO(b.date).getTime() - parseISO(a.date).getTime());

const loanRegistrationResponse: LoanRegistrationResponse = {
    clientLoanNumber: String(clientLoanNumber++),
    premicorrLoanID: String(loanID++)
};

const loanEventsBase: Omit<LoanEvent, 'loanId'>[] = [
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.CREATED,
        triggeredByUserId: internalUsers[0].id,
        triggeredAt: createDate(-.25).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.PRICED,
        triggeredByUserId: internalUsers[0].id,
        triggeredAt: createDate(-.2).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.REPRICED,
        triggeredByUserId: internalUsers[0].id,
        triggeredAt: createDate(-.1).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.LOCKED,
        triggeredByUserId: internalUsers[0].id,
        triggeredAt: createDate(-.05).toISOString()
    },
    {
        id: String(loanTimelineEventID++),
        eventType: LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED,
        triggeredByUserId: internalUsers[0].id,
        triggeredAt: createDate(-.025).toISOString()
    }
];

function getLoanEvents(loanID: string): LoanEvent[] {
    return (getItemById(loans, loanID).loanStatus === 'Ready for Docs'
        ? loanEventsBase.slice(0, -1) // if awaiting docs, don't include the INITIAL_DOC_PACKAGE_UPLOADED event
        : loanEventsBase
    ).map(event => ({
        ...event,
        loanId: loanID
    }));
}

function getLoanDetail(loanID: string): LoanDetail {
    const loan = loans.find(loan => loan.id === loanID)!;
    const amortizationType = getRandomEnumValue(AmortizationType);

    return {
        id: loanID,
        assignee: internalUsers[0],
        loanNumber: loan.loanNumber,
        loanStatus: loan.loanStatus,
        loanType: getRandomEnumValue(LoanType),
        loanAmount: loan.loanAmount,
        interestRate: loan.interestRate,
        pricingResultId: loan.pricingResultId,
        amortizationType,
        armMargin: 3,
        armInitialCap: 1,
        armSubsequentCap: 2,
        armLifeCap: 5,
        escrowsFlag: randomBoolean(),
        interestOnlyFlag: randomBoolean(),
        loanTerm: 360,
        lockPeriod: 30,
        borrowerName: 'John Doe',
        registrationType: getRandomEnumValue(RegistrationType),
        firstTimeHomeBuyer: randomBoolean(),
        borrowers: [
            {
                firstName: 'First',
                middleName: 'Middle',
                lastName: 'Last',
                fico: randomNum(500, 800),
                ssn: '123-44-2364',
                email: 'name@company.com',
                primaryWageEarner: randomBoolean()
            },
            {
                firstName: 'Some',
                middleName: 'Other',
                lastName: 'Person',
                fico: randomNum(500, 800),
                ssn: '485-73-1284',
                email: 'name@company.com',
                primaryWageEarner: randomBoolean()
            }
        ],
        propertyType: getRandomEnumValue(PropertyType),
        units: getRandomEnumValue(NumUnits),
        occupancy: getRandomEnumValue(OccupancyType),
        purpose: getRandomEnumValue(LoanPurpose),
        address: {
            street: '1234 Some Ln',
            city: 'Somewhere',
            state: State.SC,
            zip: '29414'
        },
        appraisedValue: randomNum(25, 30) * 1000,
        salePrice: randomNum(20, 25) * 1000,

        customerId: '1',
        customerName: 'Jeff Bowen',
        customerLoanNumber: '44759925',
        productCode: 'Some code',
        specialtyProgram: getRandomEnumValue(SpecialtyProgram),
        documentationType: getRandomEnumValue(DocumentationType),
        subordinatedBalance: randomNum(5, 10) * 1000,
        cashOutAmount: randomNum(5, 10) * 1000,
        limitedLiabilityCorp: 'Some corp',
        commitmentType: getRandomEnumValue(CommitmentType),
        commitmentIdentifier: 'SCO',
        comments: 'Adding a note',
        mortgageInsFlag: randomBoolean(),
        mortgageInsCompany: 'Ins co',
        mortgageInsCoverage: randomNum(10, 200) * 1000,
        underwriteFlag: randomBoolean(),
        automatedUwSystem: getRandomEnumValue(AutomatedUwSystem),
        automatedUwRecommendation: getRandomEnumValue(AutomatedUwRecommendation)
    };
}

const batches: PricingUploadBatch[] = [];

const entityBase: EntityBase = {
    entityId: '1',
    entityType: 'Loan Officer',
    entityName: 'LeBron James',
    entityUri: ''
};

const forRole: ForRole = {
    entityId: '1',
    entityType: 'Role',
    entityName: 'Loan Officer'
};

const comments: Comment[] = [
    {
        commentId: '1',
        comments: 'This is a sample comment.',
        forRoleId: 1,
        forRole,
        dateCreated: '2024-02-08T12:34:56Z',
        createdBy: 'tspUser1',
        createdByName: 'John Johnson'
    },
    {
        commentId: '2',
        comments: 'A comment relating to the condition.',
        forRoleId: 1,
        forRole,
        dateCreated: '2024-02-08T12:34:56Z',
        createdBy: 'tspUser1',
        createdByName: 'John Johnson'
    },
    {
        commentId: '3',
        comments: 'Some comment for some condition somewhere.',
        forRoleId: 1,
        forRole,
        dateCreated: '2024-02-08T12:34:56Z',
        createdBy: 'tspUser1',
        createdByName: 'John Johnson'
    },
    {
        commentId: '4',
        comments: 'This is a sample comment that is much longer than the other comments. This guy helps you notice weird style issues.',
        forRoleId: 1,
        forRole,
        dateCreated: '2024-02-08T12:34:56Z',
        createdBy: 'tspUser1',
        createdByName: 'John Johnson'
    }
];

const underwritingConditions: EncompassUnderwritingCondition[] = [
    {
        id: `${conditionId}`,
        title: 'Some Loan Condition',
        description: 'Some description of the loan condition.',
        category: PremicorrConditionCategory.APPRAISAL,
        priorTo: ConditionPriorTo.CLOSING,
        expectedDate: '2025-12-31',
        requestedFrom: 'Johnny Underwriter',
        daysToReceive: 5,
        allowToClear: true,
        printExternally: false,
        printInternally: true,
        isAddedToConditionSet: false,
        isCleared: false,
        isFulfilled: false,
        isReceived: true,
        isRejected: false,
        isRemoved: false,
        isRequested: false,
        isRerequested: false,
        isReviewed: false,
        isWaived: false,
        forAllApplications: false,
        fulfilledDate: '2023-01-15',
        receivedDate: '2023-01-10',
        requestedDate: '2023-01-05',
        statusDate: '2023-01-01',
        comments,
        documents: [],
        conditionType: ConditionType.UNDERWRITING,
        status: ConditionStatus.WAIVED,
        receivedBy: entityBase,
        createdBy: entityBase,
        clearedDate: '2023-02-01',
        createdDate: '2023-01-15',
        expirationDate: '2023-12-31',
        source: 'Manual',
        templateId: 'template1'
    },
    {
        id: `${conditionId++}`,
        title: 'Some Other Loan Condition',
        description: 'Some other description of the loan condition.',
        category: PremicorrConditionCategory.APPRAISAL,
        priorTo: ConditionPriorTo.PURCHASE,
        expectedDate: '2025-12-31',
        requestedFrom: 'Johnny Underwriter',
        daysToReceive: 5,
        allowToClear: true,
        printExternally: false,
        printInternally: true,
        isAddedToConditionSet: false,
        isCleared: false,
        isFulfilled: false,
        isReceived: true,
        isRejected: false,
        isRemoved: false,
        isRequested: false,
        isRerequested: false,
        isReviewed: false,
        isWaived: false,
        forAllApplications: false,
        fulfilledDate: '2023-01-15',
        receivedDate: '2023-01-10',
        requestedDate: '2023-01-05',
        statusDate: '2023-01-01',
        comments,
        documents: [],
        conditionType: ConditionType.UNDERWRITING,
        status: ConditionStatus.ADDED,
        receivedBy: entityBase,
        createdBy: entityBase,
        clearedDate: '2023-02-01',
        createdDate: '2023-01-15',
        expirationDate: '2023-12-31',
        source: 'Manual',
        templateId: 'template1'
    },
    {
        id: `${conditionId++}`,
        title: 'Another Loan Condition',
        description: 'The description is a much longer description of the loan condition. Can you believe LeBron James is working in the mortgage industry now?',
        category: PremicorrConditionCategory.FLOOD_AND_HAZARD,
        priorTo: ConditionPriorTo.DOCS,
        expectedDate: '2024-09-10',
        requestedFrom: 'Johnny Underwriter',
        daysToReceive: 5,
        allowToClear: false,
        printExternally: true,
        printInternally: true,
        isAddedToConditionSet: false,
        isCleared: false,
        isFulfilled: true,
        isReceived: false,
        isRejected: false,
        isRemoved: false,
        isRequested: true,
        isRerequested: false,
        isReviewed: true,
        isWaived: false,
        forAllApplications: false,
        fulfilledDate: '2023-01-15',
        receivedDate: '2023-01-10',
        requestedDate: '2023-01-05',
        statusDate: '2023-01-01',
        comments,
        documents: [],
        conditionType: ConditionType.UNDERWRITING,
        status: ConditionStatus.REQUESTED,
        fulfilledBy: entityBase,
        requestedBy: entityBase,
        createdBy: entityBase,
        clearedDate: '2023-02-01',
        createdDate: '2023-01-15',
        expirationDate: '2023-12-31',
        source: 'Escrow',
        templateId: 'template2'
    },
    {
        id: `${conditionId++}`,
        title: 'Another Better Loan Condition',
        description: 'This is a much longer description of the loan condition. Can you believe LeBron James is working in the mortgage industry now?',
        category: PremicorrConditionCategory.FLOOD_AND_HAZARD,
        priorTo: ConditionPriorTo.FUNDING,
        expectedDate: '2024-09-10',
        requestedFrom: 'Johnny Underwriter',
        daysToReceive: 5,
        allowToClear: false,
        printExternally: true,
        printInternally: true,
        isAddedToConditionSet: false,
        isCleared: false,
        isFulfilled: true,
        isReceived: false,
        isRejected: false,
        isRemoved: false,
        isRequested: true,
        isRerequested: false,
        isReviewed: true,
        isWaived: false,
        forAllApplications: false,
        fulfilledDate: '2023-01-15',
        receivedDate: '2023-01-10',
        requestedDate: '2023-01-05',
        statusDate: '2023-01-01',
        comments: [],
        documents: [],
        conditionType: ConditionType.UNDERWRITING,
        status: ConditionStatus.REREQUESTED,
        fulfilledBy: entityBase,
        requestedBy: entityBase,
        createdBy: entityBase,
        clearedDate: '2023-02-01',
        createdDate: '2023-01-15',
        expirationDate: '2023-12-31',
        source: 'Escrow',
        templateId: 'template2'
    }
];

const allUsers = [
    ...internalUsers, ...clientUsers, ...customerUsers
];

function generateMockConditionEvents(conditionId: string, loanId: string): UnderwritingConditionEvent[] {
    const eventTypes = Object.values(ConditionEventType).filter(type => type !== ConditionEventType.CONDITION_CREATED);
    const numberOfEvents = randomNum(2, 4);

    let currentTime = new Date();
    const events: UnderwritingConditionEvent[] = [];

    // Always added as the first event
    events.push({
        id: `event-${conditionId}-created`,
        conditionId,
        loanId,
        eventType: ConditionEventType.CONDITION_CREATED,
        triggeredByUserId: allUsers[randomNum(0, allUsers.length - 1)].id,
        triggeredAt: subHours(currentTime, randomNum(1, 48)).toISOString()
    });

    // Generate additional events
    for (let i = 0; i < numberOfEvents; i++) {
        const timeDiff = randomNum(0, 12);
        currentTime = subHours(currentTime, timeDiff);

        events.push({
            id: `event-${conditionId}-${i}`,
            conditionId,
            loanId,
            eventType: eventTypes[randomNum(0, eventTypes.length - 1)],
            triggeredByUserId: allUsers[randomNum(0, allUsers.length - 1)].id,
            triggeredAt: currentTime.toISOString()
        });
    }

    // Sort events from newest to oldest
    return events.sort((a, b) => new Date(b.triggeredAt).getTime() - new Date(a.triggeredAt).getTime());
}

function filterLoans(loans: RegisteredLoan[], params: LoanQueryParams): RegisteredLoan[] {
    return loans.filter(loan => {
        const loanNumberMatch = loan.loanNumber.toLowerCase().includes(params.loanNumber?.toLowerCase() || '');
        const borrowerNameMatch = loan.borrowerName.toLowerCase().includes(params.borrowerName?.toLowerCase() || '');
        const customerIdMatch = loan.customerId.toLowerCase().includes(params.customerId?.toLowerCase() || '');

        let loanStatusMatch = true;
        if (params.statusConfigId) {
            const statusConfig = loanStatusConfigs.find(config => config.id === params.statusConfigId);
            if (statusConfig) {
                const losStatus = losLoanStatuses.find(status => status.name === loan.loanStatus);
                loanStatusMatch = statusConfig.losLoanStatusIds.includes(losStatus?.id || '');
            }
        }

        return loanNumberMatch && borrowerNameMatch && customerIdMatch && loanStatusMatch;
    });
}

function paginateLoans(loans: RegisteredLoan[], page: number, pageSize: number): PaginatedResponse<RegisteredLoan> {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedItems = loans.slice(startIndex, endIndex);

    return {
        data: paginatedItems,
        pageNumber: page,
        pageSize,
        totalRecords: loans.length,
        totalPages: Math.ceil(loans.length / pageSize)
    };
}
