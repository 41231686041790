import api, {
    ActiveInactiveEnum, DueDiligenceConfig, DueDiligenceStepType, dueDiligenceStepTypeDisplay
} from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, Switch, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { replaceItemById, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import {
    Dispatch, SetStateAction, useEffect, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './DueDiligenceSettingsForm.module.scss';


interface DueDiligenceSettingsFormProps {
    defaultValues: DueDiligenceConfig | undefined;
    configs: DueDiligenceConfig[];
    setConfigs: Dispatch<SetStateAction<DueDiligenceConfig[]>>;
    closeForm: () => void;
}

export default function DueDiligenceSettingsForm({
    defaultValues, configs, setConfigs, closeForm
}: DueDiligenceSettingsFormProps) {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<DueDiligenceConfig>({
        defaultValues: defaultValues || {
            ...emptyConfig,
            displayOrder: configs.length
        }
    });
    const { setValue, watch, reset } = formMethods;

    const stepType = watch('type');

    const selectedTypes = configs.filter(({ id }) => id !== defaultValues?.id).map(({ type }) => type) || [];

    const inactiveTypes = configs.filter(({ status }) => status === ActiveInactiveEnum.INACTIVE)
        .map(({ type }) => type) || [];

    const checklistStepEnumOptions = Object.fromEntries(
        Object.entries(dueDiligenceStepTypeDisplay).filter(([ key ]) => (
            key === DueDiligenceStepType.CUSTOM
                || !selectedTypes.includes(key as DueDiligenceStepType)
                || inactiveTypes.includes(key as DueDiligenceStepType)
        ))
    );

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setLoading(true);

            const updatedConfig = formValues?.id
                ? await api.dueDiligenceConfig.updateDueDiligenceConfig(clientId, formValues)
                : await api.dueDiligenceConfig.createDueDiligenceConfig(clientId, formValues);
            setConfigs((configs) => (formValues?.id
                ? replaceItemById(configs, updatedConfig)
                : (configs).concat(updatedConfig)));

            closeForm();

            pageMessage.success(`Step ${formValues?.id ? 'updated' : 'added'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the due-diligence step', error);
        }

        setLoading(false);
    });

    useEffect(() => {
        const existingConfig = configs.find(
            config => config.type === stepType && stepType !== DueDiligenceStepType.CUSTOM
        );

        // if there is an existing inactive config, populate form values with the existing values
        if (existingConfig) {
            setValue('id', existingConfig.id);
            setValue('name', existingConfig.name);
            setValue('type', existingConfig.type);
            setValue('description', existingConfig.description);
            setValue('isRequired', existingConfig.isRequired);
            setValue('isDocRequired', existingConfig.isDocRequired);
            setValue('status', ActiveInactiveEnum.ACTIVE);
            setValue('displayOrder', configs?.length || 0);
        } else {
            reset(defaultValues);
        }
    }, [
        configs, setValue, stepType, defaultValues, reset
    ]);

    return (
        <>
            <DialogContent>
                <form
                    id={formID}
                    className={styles.root}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<DueDiligenceConfig>
                            name="type"
                            label="Step type"
                            size="small"
                            required
                            select
                        >
                            {renderEnumOptions(checklistStepEnumOptions)}
                        </TextField>

                        <TextField<DueDiligenceConfig>
                            name="name"
                            label="Step name"
                            size="small"
                            required
                        />

                        <TextField<DueDiligenceConfig>
                            name="description"
                            label="Step description"
                            size="small"
                            required
                            multiline
                            rows={4}
                        />

                        <div className={styles.switches}>
                            <Switch<DueDiligenceConfig>
                                label="Required"
                                name="isRequired"
                            />

                            <Switch<DueDiligenceConfig>
                                label="Document required"
                                name="isDocRequired"
                            />
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={closeForm}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={formID}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

const emptyConfig: Omit<DueDiligenceConfig, 'id'> = {
    name: '',
    description: '',
    status: ActiveInactiveEnum.ACTIVE,
    isDocRequired: false,
    displayOrder: 0,
    isRequired: true,
    type: DueDiligenceStepType.CUSTOM
};

const formID = 'due-diligence-config-form';
