import { LoanActivityType } from '@api';

import { apiUtils, getAccountBaseUrl } from '../api-utils';


export interface LoanActivity {
    id: string;
    loanId: string;
    loanNumber: string;
    customerId?: string;
    date: string;
    type: LoanActivityType;
    userId: string;
}

/**
 * Gets most recent 10 loan activities for a client or customer
 */
export function getLoanActivity(clientId: string, customerId?: string): Promise<LoanActivity[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan-activity`);
}
