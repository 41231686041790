import { LoanPricingResult, RegisteredLoan } from '@api';
import {
    Pagination, Paper, Skeleton, Typography
} from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import { ExpandableHeader } from '@tsp-ui/core/components';
import {
    Children,
    ComponentProps, Dispatch, ReactNode, SetStateAction, useLayoutEffect, useRef, useState
} from 'react';
import { TransitionGroup } from 'react-transition-group';

import styles from './LoanSectionBase.module.scss';


interface LoanSectionBaseProps extends Omit<
    ComponentProps<typeof ExpandableHeader>,
    'expandedContent' | 'secondaryText'
> {
    setPageNumber: Dispatch<SetStateAction<number>>;
    paginatedResponse: PaginatedResponse<RegisteredLoan | LoanPricingResult>;
    loading: boolean;
    noResultsMessage: ReactNode;
    loanCount?: number;
}

export function LoanSectionBase({
    children, setPageNumber, paginatedResponse, loading, noResultsMessage, loanCount, ...props
}: LoanSectionBaseProps) {
    const loanCardsRef = useRef<(HTMLDivElement | null)[]>([]);
    const [ isExpanded, setIsExpanded ] = useState(props.defaultExpand);
    const [ sectionHeight, setSectionHeight ] = useState('auto');

    const [ forceRenderCounter, setForceRenderCounter ] = useState(0);

    useLayoutEffect(() => {
        let totalHeight = 42; // Height of the section header

        if (isExpanded) {
            totalHeight += 16; // Top padding of section container
            const cardHeights = loanCardsRef.current.slice(0, 3).map(card => card?.offsetHeight ?? 0);

            cardHeights.forEach((cardHeight) => {
                totalHeight += cardHeight;
            });

            if (cardHeights.length > 1) {
                totalHeight += ((cardHeights!.length) - 1) * 8; // Gap between cards
            }
        }

        setSectionHeight(`${totalHeight}px`);

        if (isExpanded && loanCount && loanCount > 0 && !loanCardsRef.current.filter(Boolean).length) {
            const timeout = setTimeout(() => (
                setForceRenderCounter(forceRenderCounter + 1)
            ), 50);

            return () => clearTimeout(timeout);
        }
    }, [
        loanCount, isExpanded, forceRenderCounter, loading
    ]);

    const skeletonNumber = Math.min(paginatedResponse.data.length, paginatedResponse.pageSize);
    loanCount = loanCount === undefined ? paginatedResponse.totalRecords : loanCount;

    return (
        <div
            className={styles.root}
            style={{ minHeight: sectionHeight }}
        >
            <ExpandableHeader
                {...props}
                secondaryText={loanCount === undefined ? (
                    <Skeleton
                        width="60px"
                        height={30}
                    />
                ) : `${loanCount} loan${loanCount !== 1 ? 's' : ''}`}
                onExpandToggle={() => {
                    props?.onExpandToggle?.(!isExpanded);
                    setIsExpanded(!isExpanded);
                }}
                expandedContent={(
                    <div className={styles.expandedContent}>
                        {loading ? (
                            <div className={styles.loans}>
                                {Array(skeletonNumber).fill(true).map((_) => (
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                        height={50}
                                    />
                                ))}
                            </div>
                        ) : children && Children.count(children) > 0 ? (
                            <TransitionGroup className={styles.loans}>
                                {Children.map(children, (child, index) => (
                                    <div
                                        ref={el => {
                                            if (!loanCardsRef.current) {
                                                loanCardsRef.current = [];
                                            }

                                            loanCardsRef.current[index] = el;
                                        }}
                                    >
                                        {child}
                                    </div>
                                ))}
                            </TransitionGroup>
                        ) : noResultsMessage ? (
                            <div className={styles.loans}>
                                <Paper
                                    ref={el => loanCardsRef.current = [ el ]}
                                    variant="outlined"
                                    className={styles.noResultsPaper}
                                >
                                    <Typography>
                                        {noResultsMessage}
                                    </Typography>
                                </Paper>
                            </div>
                        ) : null}
                    </div>
                )}
            />

            {isExpanded && paginatedResponse.totalPages > 1 && (
                <Pagination
                    count={paginatedResponse.totalPages}
                    page={paginatedResponse.pageNumber}
                    onChange={(_, page) => setPageNumber(page)}
                    className={styles.pagination}
                />
            )}
        </div>
    );
}
