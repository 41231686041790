import { Branch, PermissionType } from '@api';
import { DialogContent, Paper, Typography } from '@mui/material';
import { Button, DialogActions, ExpandableHeader } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { Link, useLocation } from 'react-router-dom';

import BranchCard from './BranchCard';
import styles from './BranchesDialogContent.module.scss';


interface BranchesDialogContentProps {
    onEditClick: (branch: Branch) => void;
    onToggleActive: (branch: Branch) => void;
    branches: Branch[];
}

export default function BranchesDialogContent({ onEditClick, onToggleActive, branches }: BranchesDialogContentProps) {
    const { pathname } = useLocation();

    const activeBranches = branches.filter(branch => branch.isActive);
    const inactiveBranches = branches.filter(branch => !branch.isActive);

    const [ canViewBranches, canAddBranches ] = useHasPermission(
        [ PermissionType.VIEW_CUSTOMER_BRANCHES, PermissionType.CREATE_CUSTOMER_BRANCHES ]
    );

    return (
        <>
            <DialogContent className={styles.mainContent}>
                {!canViewBranches ? (
                    <NoBranchesCard message="You don't have permission to view branches" />
                ) : (
                    <>
                        <ExpandableHeader
                            title="Active"
                            defaultExpand
                            disableExpand={false}
                            secondaryText={`${activeBranches.length} branch${activeBranches.length === 1 ? '' : 'es'}`}
                            expandedContent={(
                                <div className={styles.expandedContent}>
                                    {activeBranches.length === 0 ? (
                                        <NoBranchesCard message="This customer has no active branches" />
                                    ) : activeBranches.map(branch => (
                                        <BranchCard
                                            key={branch.id}
                                            branch={branch}
                                            onEditClick={onEditClick}
                                            onToggleActive={onToggleActive}
                                        />
                                    ))}
                                </div>
                            )}
                        />

                        <ExpandableHeader
                            title="Inactive"
                            defaultExpand={false}
                            disableExpand={false}
                            secondaryText={`${inactiveBranches.length} branch${inactiveBranches.length === 1 ? '' : 'es'}`}
                            expandedContent={(
                                <div className={styles.expandedContent}>
                                    {inactiveBranches.length === 0 ? (
                                        <NoBranchesCard message="This customer has no inactive branches" />
                                    ) : inactiveBranches.map(branch => (
                                        <BranchCard
                                            key={branch.id}
                                            branch={branch}
                                            onEditClick={onEditClick}
                                            onToggleActive={onToggleActive}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    component={Link}
                    to={`${pathname}/add`}
                    disabled={!canAddBranches}
                    tooltip={!canAddBranches ? 'You don\'t have permission to add branches' : ''}
                >
                    Add branch
                </Button>
            </DialogActions>
        </>
    );
}

interface NoBranchesCardProps {
    message: string;
}

function NoBranchesCard({ message }: NoBranchesCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.noBranchesCard}
        >
            <Typography
                variant="body2"
                align="center"
            >
                {message}
            </Typography>
        </Paper>
    );
}
