import {
    UnderwritingAction, UnderwritingQuestion, UnderwritingQuestionType, UnderwritingStepDetail
} from '@api';
import { Paper } from '@mui/material';
import { HTMLAttributes } from 'react';

import { CustomQuestionCard } from './question-cards/CustomQuestionCard/CustomQuestionCard';
import { ForeachQuestionCard } from './question-cards/ForeachQuestionCard';
import { FormQuestionCard } from './question-cards/FormQuestionCard';
import { GroupQuestionCard } from './question-cards/GroupQuestionCard';
import { InfoQuestionCard } from './question-cards/InfoQuestionCard';
import { SingleSelectQuestionCard } from './question-cards/SingleSelectQuestionCard';
import { TableQuestionCard } from './question-cards/TableQuestionCard';


export interface UnderwritingQuestionCardProps {
    question: UnderwritingQuestion;
}

/**
 * **Either a Paper or a div**
 *
 * This component determins whether the underwriting question is a top-level question or a sub-question.
 * If it's a top level question, it renders children in a Paper component.
 * If it's a sub-question, it uses a div.
 */
export function UnderwritingCardWrapper(
    { question, ...props }: UnderwritingQuestionCardProps & HTMLAttributes<HTMLDivElement>
) {
    const isSubQuestion = question.id.includes('.'); // represents if this question belongs to a group
    const WrapperComponent = isSubQuestion ? 'div' : Paper;

    return (
        <WrapperComponent
            variant="outlined"
            {...props}
        />
    );
}

// pass in the ALREADY UPDATED questionIdsToDisplay array, along with the question.
// All of the following values will populate from there
export function getVisibleQuestionIds(
    underwritingStep: UnderwritingStepDetail,
    question: UnderwritingQuestion = underwritingStep.questions[0],
    questionIdsToDisplay: string[] = underwritingStep.questions.length ? [ underwritingStep.questions[0].id ] : []
): string[] {
    // gets the question from either underwritingStep.questions, or if it's a group, from the group's questions
    function getNextQuestion(questionId: string): UnderwritingQuestion | undefined {
        const subQuestions = underwritingStep.questions.reduce((acc, q) => {
            if (q.questions) {
                return [ ...acc, ...q.questions ];
            } else {
                return acc;
            }
        }, [] as UnderwritingQuestion[]);
        return [ ...underwritingStep.questions!, ...subQuestions ].find(q => q.id === questionId);
    }

    if (!question) {
        return questionIdsToDisplay;
    }

    // if showNextQuestion is true, mark the next question as visible without checking any other conditions
    if (question.showNextQuestion && question.next?.continueTo) {
        return getVisibleQuestionIds(
            underwritingStep,
            getNextQuestion(question.next.continueTo),
            [ ...questionIdsToDisplay, question.next.continueTo ]
        );
    }

    if (question.type === UnderwritingQuestionType.SINGLE_SELECT) {
        if (!question.answer) {
            return questionIdsToDisplay;
        } else {
            const answeredChoice = question.choices?.find(choice => choice.text === question!.answer);

            if (answeredChoice?.action === 'CONTINUE') {
                return getVisibleQuestionIds(underwritingStep, getNextQuestion(answeredChoice.continueTo || ''), [ ...questionIdsToDisplay, answeredChoice.continueTo || '' ]);
            } else if (answeredChoice?.action === 'END') {
                return questionIdsToDisplay;
            }
        }
    } else if (question.type === UnderwritingQuestionType.FORM) {
        const formIsCompleted = isUnderwritingFormComplete(question);

        if (!formIsCompleted) {
            return questionIdsToDisplay;
        } else if (question.next?.action === 'END') {
            return questionIdsToDisplay;
        } else {
            return getVisibleQuestionIds(underwritingStep, getNextQuestion(question.next!.continueTo || ''), [ ...questionIdsToDisplay, question.next?.continueTo || '' ]);
        }
    } else if (question.type === UnderwritingQuestionType.INFO) {
        if (question.answer) {
            if (question.next?.action === 'END') {
                return questionIdsToDisplay;
            } else {
                return getVisibleQuestionIds(underwritingStep, getNextQuestion(question.next!.continueTo || ''), [ ...questionIdsToDisplay, question.next?.continueTo || '' ]);
            }
        } else {
            return questionIdsToDisplay;
        }
    } else if (question.type === UnderwritingQuestionType.GROUP) {
        const [ firstQuestionInGroup ] = question.questions!;
        if (firstQuestionInGroup) {
            return getVisibleQuestionIds(
                underwritingStep, firstQuestionInGroup, [ ...questionIdsToDisplay, firstQuestionInGroup.id ]
            );
        } else {
            return questionIdsToDisplay;
        }
    } else if (question.type === UnderwritingQuestionType.FOREACH) {
        const subQuestionIds = question.arrayData?.flatMap((item, index) => {
            const subQuestionsForIndex = question.questions?.map(
                forEachSubQuestionConfig => createForEachQuestionWithIndex(
                    forEachSubQuestionConfig, question, index
                )
            ) || [];

            const visibleSubQuestionIdsForIndex = getVisibleQuestionIds(
                {
                    ...underwritingStep,
                    questions: subQuestionsForIndex
                },
                subQuestionsForIndex[0],
                [ subQuestionsForIndex[0]?.id ]
            );

            return visibleSubQuestionIdsForIndex;
        }) || [];

        if (areAllForeachItemsComplete(question)) {
            return getVisibleQuestionIds(underwritingStep, getNextQuestion(question.next!.continueTo || ''), [
                ...questionIdsToDisplay, ...subQuestionIds, question.next?.continueTo || ''
            ]);
        } else {
            return [ ...questionIdsToDisplay, ...subQuestionIds ];
        }
    } else if (question.type === UnderwritingQuestionType.TABLE_ENTRY) {
        const tableIsCompleted = question.values?.every(row => Object.values(row).every(value => ![
            null, undefined, ''
        ].includes(value)));

        if (tableIsCompleted) {
            if (question.next?.action === 'END') {
                return questionIdsToDisplay;
            } else {
                return getVisibleQuestionIds(underwritingStep, getNextQuestion(question.next!.continueTo || ''), [ ...questionIdsToDisplay, question.next?.continueTo || '' ]);
            }
        } else {
            return questionIdsToDisplay;
        }
    } else if (question.type === UnderwritingQuestionType.CUSTOM) {
        // if there are any custom questions that require user input before the next question is displayed,
        // this function will need to be updated
        if (question.next?.action === 'END') {
            return questionIdsToDisplay;
        } else {
            return getVisibleQuestionIds(underwritingStep, getNextQuestion(question.next!.continueTo || ''), [ ...questionIdsToDisplay, question.next?.continueTo || '' ]);
        }
    } else {
        return questionIdsToDisplay;
    }

    return questionIdsToDisplay;
}

/**
 * This function is used to create a foreach question with the correct index.
 * It generates the correct ids for the question and its continueTo actions based on the provided index.
 * It also populates the answer in the subquestion with the correct answer from the arrayData.
 */
export function createForEachQuestionWithIndex(
    subQuestion: UnderwritingQuestion,
    parentQuestion: UnderwritingQuestion,
    index: number
): UnderwritingQuestion {
    const [
        parentQuestionId, , subQuestionId
    ] = subQuestion.id.split('.');

    return {
        ...subQuestion,
        id: `${parentQuestionId}.${index}.${subQuestionId}`,
        ...(subQuestion.choices && {
            choices: subQuestion.choices.map(
                choice => createForEachActionWithIndex(choice, index)
            )
        }),
        ...(subQuestion.next && {
            next: createForEachActionWithIndex(subQuestion.next, index)
        }),
        answer: parentQuestion.arrayData?.[index]?.answers?.find((answer: any) => answer.id === subQuestionId)?.value
    };
}

function createForEachActionWithIndex(action: UnderwritingAction, index: number): UnderwritingAction {
    if (!action.continueTo) {
        return action;
    }

    const [
        parentQuestionId, , continueToQuestionId
    ] = action.continueTo.split('.');

    return {
        ...action,
        continueTo: `${parentQuestionId}.${index}.${continueToQuestionId}`
    };
}

/**
 * This function is used to determine if all of the foreach items have been completed.
 * When all subquestions on all items in the foreach have been answered, this function will return true,
 * and the question's next action will be triggered.
 */
function areAllForeachItemsComplete(question: UnderwritingQuestion): boolean {
    // question.questions is the question configs
    // question.arrayData is the data for the foreach
    // for each item in arrayData, check if each question from question.questions has an answer
    // if there are no items in arrayData, return false
    return !!question.arrayData?.length && question.arrayData.every((_, index) => {
        const subQuestions = question.questions?.map(
            subQuestion => createForEachQuestionWithIndex(subQuestion, question, index)
        );
        return !subQuestions || subQuestions.every(subQuestion => !!subQuestion.answer);
    });
}

/**
 * Maps the underwriting question type to the correct card component.
 * - SINGLE_SELECT questions are mapped to SingleSelectQuestionCard
 * - FORM questions are mapped to FormQuestionCard
 * - INFO questions are mapped to InfoQuestionCard
 * - TABLE_ENTRY questions are mapped to TableQuestionCard
 * - GROUP questions are mapped to GroupQuestionCard
 */
export const questionTypeToComponentMap = {
    [UnderwritingQuestionType.SINGLE_SELECT]: SingleSelectQuestionCard,
    [UnderwritingQuestionType.FORM]: FormQuestionCard,
    [UnderwritingQuestionType.INFO]: InfoQuestionCard,
    [UnderwritingQuestionType.TABLE_ENTRY]: TableQuestionCard,
    [UnderwritingQuestionType.GROUP]: GroupQuestionCard,
    [UnderwritingQuestionType.FOREACH]: ForeachQuestionCard,
    [UnderwritingQuestionType.CUSTOM]: CustomQuestionCard
};

export function isUnderwritingFormComplete(question: UnderwritingQuestion): boolean {
    const emptyFormValues: (string | Date | null | undefined)[] = [
        '', null, undefined
    ];

    return !!question.fields?.every(
        field => field.isDisabled || field.isRequired === false || (
            !emptyFormValues.includes(field.value)
            && !(field.value instanceof Date && isNaN(field.value.getTime()))
        )
    );
}

/**
 * Replaces placeholders in the template string with corresponding values from the provided object.
 */
export function injectValues(template: string | undefined, values: Record<string, any>): string | undefined {
    // Matches placeholders like {{ loan.data.commitment_expiry_date }} or {{ myKey }}
    const placeholderRegex = /\{\{\s*([\w.]+)\s*\}\}/g;

    return template?.replace(
        placeholderRegex,
        (_, path) => {
            // Split the path by dots to traverse the nested structure
            const keys = path.split('.');
            let value = values;

            // Traverse the object using each key in the path
            for (const key of keys) {
                if (value && key in value) {
                    value = value[key];
                } else {
                    // If a key is missing, return an empty string
                    return '';
                }
            }

            // Convert the resolved value to a string, or use an empty string if undefined
            return value !== undefined ? String(value) : '';
        }
    );
}
