import api, {
    Contact, ContactResponsibility, ContactTitle, contactResponsibilityDisplay, contactTitleDisplay
} from '@api';
import { Close } from '@mui/icons-material';
import {
    Button, Chip, DialogContent, Tooltip
} from '@mui/material';
import {
    Autocomplete, DialogActions, PhoneField, Switch
    , TextField, isEmail
} from '@tsp-ui/core/components';
import {
    isEnumValue, useConfirm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AddEditContactDialogContent.module.scss';


interface AddEditContactDialogContentProps {
    defaultValues?: Partial<Contact>;
    onAdd?: (formValues: Contact) => void;
    onEdit: (formValues: Contact) => void;
    onCancel: () => void;
}

export function AddEditContactDialogContent({
    defaultValues, onAdd, onEdit, onCancel
}: AddEditContactDialogContentProps) {
    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<Contact>({ defaultValues });
    const isPrimary = formMethods.watch('isPrimary');

    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const clientID = useActingClientID();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const { configurableValues } = useContext(ClientContext);

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (defaultValues?.id) {
                onEdit(await api.customer.contacts.editContact(clientID, customerID, formValues));
            } else {
                onAdd?.(await api.customer.contacts.addContact(clientID, customerID, formValues));
            }

            setLoading(false); // set loading before component unmounts

            pageMessage.success('Contact saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the contact', error);

            setLoading(false);
        }
    });

    return (
        <>
            <DialogContent>
                <form
                    noValidate
                    id="add-edit-contact-form"
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.formContent}>
                            <TextField<Contact>
                                name="name"
                                label="Name"
                                required
                            />

                            <PhoneField<Contact>
                                name="phone"
                                label="Phone Number"
                                required
                            />

                            <TextField<Contact>
                                name="email"
                                label="Email"
                                required
                                rules={isEmail}
                            />

                            <Autocomplete<Contact>
                                name="title"
                                label="Title"
                                freeSolo
                                required
                                options={configurableValues?.togglableValues.contactTite as string[]
                                    || Object.values(ContactTitle)}
                                inputProps={{ maxLength: 100 }}
                                getOptionLabel={option => (
                                    isEnumValue(option, ContactTitle) ? contactTitleDisplay[option] : option
                                )}
                            />

                            <Autocomplete<Contact>
                                name="responsibilities"
                                label="Responsibilities"
                                className={styles.fullWidth}
                                multiple
                                required
                                options={configurableValues?.togglableValues.contactResponsibility as string[]
                                    || Object.keys(ContactResponsibility)}
                                getOptionLabel={option => contactResponsibilityDisplay[option as ContactResponsibility]}
                                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={contactResponsibilityDisplay[option as ContactResponsibility]}
                                        {...getTagProps({ index })}
                                        className={styles.chip}
                                        deleteIcon={<Close />}
                                    />
                                ))}
                            />

                            <div className={styles.fullWidth}>
                                <Tooltip
                                    title={defaultValues?.isPrimary ? 'You must have a primary contact. To change the primary contact, edit the contact you would like to assign as primary.' : ''}
                                >
                                    <span>
                                        <Switch<Contact>
                                            name="isPrimary"
                                            label="Primary contact"
                                            disabled={defaultValues?.isPrimary}
                                            confirm={!isPrimary ? () => confirm('This action will override the current primary contact. Are you sure?') : undefined}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onCancel}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form="add-edit-contact-form"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
