import { TextField, Typography } from '@mui/material';
import { Button, CardTable } from '@tsp-ui/core';
import { replaceItemByIndex } from '@utils';
import { Markdown } from '@views/components/Markdown';
import { useContext, useEffect, useMemo } from 'react';

import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, UnderwritingQuestionCardProps, getVisibleQuestionIds } from '../underwriting-common';

import styles from './TableQuestionCard.module.scss';


export function TableQuestionCard({ question }: UnderwritingQuestionCardProps) {
    const {
        setQuestionIdsToDisplay, setReadyToSubmit, setUnderwritingStep, underwritingStep, updateUnderwritingQuestion
    } = useContext(UnderWritingStepContext);

    // an object where every key is the column name and the value is an empty string
    const emptyRow = useMemo(() => question.columns?.reduce((acc, column) => ({
        ...acc,
        [column.columnName]: ''
    }), {}) || {}, [ question.columns ]);

    const tableIsCompleted = question.values?.every(row => Object.values(row).every(value => ![
        null, undefined, ''
    ].includes(value)));

    // populate default set of values (first empty row)
    useEffect(() => {
        if (!question.values || !question.values.length) {
            updateUnderwritingQuestion({
                ...question,
                values: [ emptyRow ]
            });
        }
    }, [
        emptyRow, question, setUnderwritingStep, underwritingStep, updateUnderwritingQuestion
    ]);

    useEffect(() => {
        if (question.next?.action === 'END') {
            if (tableIsCompleted) {
                setReadyToSubmit(true);
            } else {
                setReadyToSubmit(false);
            }
        }
    }, [
        tableIsCompleted, question, setReadyToSubmit
    ]);

    return (
        <UnderwritingCardWrapper
            question={question}
            className={styles.tableCard}
        >
            <Typography color="textSecondary">
                <Markdown valuesToInject={question.data}>
                    {question.text}
                </Markdown>
            </Typography>

            <CardTable
                className={styles.editableCardTable}
                headers={question.columns?.map(column => column.columnName) || []}
            >
                {question.values?.map((row, rowIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={rowIndex}>
                        {question.columns?.map((column, columnIndex) => (
                            <td key={column.loanFieldId}>
                                <TextField
                                    id={column.loanFieldId + rowIndex}
                                    variant="standard"
                                    size="small"
                                    value={row[column.columnName]}
                                    autoFocus={rowIndex === 0 && columnIndex === 0}
                                    onChange={(event) => {
                                        updateUnderwritingQuestion({
                                            ...question,
                                            values: replaceItemByIndex(question.values!, {
                                                ...row,
                                                [column.columnName]: event.target.value
                                            }, rowIndex)
                                        }, false);
                                    }}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </CardTable>

            <div className={styles.tableCardButtons}>
                <Button onClick={() => {
                    updateUnderwritingQuestion({
                        ...question,
                        values: [
                            ...question.values || [],
                            emptyRow
                        ]
                    });
                }}
                >
                    Add row
                </Button>

                {/* if question is last in the step, use ready to submit.
                    if question has a next action, use this next button instead */}
                {question.next?.action === 'CONTINUE' && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setQuestionIdsToDisplay(getVisibleQuestionIds(underwritingStep!));
                        }}
                        disabled={!tableIsCompleted}
                    >
                        {question.next.text}
                    </Button>
                )}
            </div>
        </UnderwritingCardWrapper>
    );
}
