import { MenuItem } from '@aws-amplify/ui-react';
import { Autocomplete, TextField } from '@mui/material';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import React, { Dispatch, SetStateAction, useContext } from 'react';


interface CustomerAutocompleteProps {
    className?: string;
    customerIdSearch?: string;
    setCustomerIdSearch: Dispatch<SetStateAction<string>> | undefined;
    isRequired?: boolean;
}

export default function CustomerAutocomplete({
    className,
    customerIdSearch,
    setCustomerIdSearch,
    isRequired
}: CustomerAutocompleteProps) {
    const { customers } = useContext(ClientContext);
    const customerOptions = customers?.map(c => c.id) || [];

    return (
        <Autocomplete
            className={className}
            options={customerOptions}
            value={customerIdSearch}
            size="small"
            loading={!customerOptions.length}
            onChange={(_, newValue) => {
                setCustomerIdSearch?.(newValue || '');
            }}
            getOptionLabel={option => customers?.find(c => c.id === option)?.name || '-- All --'}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Filter by customer"
                    variant="filled"
                    required={isRequired}
                >
                    <MenuItem>
                        Loading...
                    </MenuItem>
                </TextField>
            )}
        />
    );
};
