import { PaginatedResponse } from '@tsp-ui/core';
import { LoanActivity } from 'api/loan-activity/loan-activity-api';

import {
    Address,
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    Borrower,
    CommitmentType,
    ConditionEventType,
    ConditionPriorTo,
    ConditionStatus,
    ConditionType,
    DocumentationType, FileUpload,
    LoanEventType,
    LoanPurpose,
    LoanType,
    LockPeriod,
    NumUnits,
    OccupancyType,
    PaginatedGetParams,
    PremicorrConditionCategory,
    PricingUploadBatch,
    PricingUploadBatchRequest,
    PropertyType,
    RegistrationType,
    SpecialtyProgram,
    User
} from '..';
import { apiUtils, getAccountBaseUrl } from '../api-utils';


export * as document from './document/loanDocument-api';

/**
 * loanID - premicorr loan ID, unique guid we use to track a loan, created when loan is created
 *
 * loanNumber - represents either the non-unique customer loan number (before the loan is registered),
 * or the unique client loan number (after the loan is registered)
 *
 * customerLoanNumber - only used after the loan is registered when both the client loan number &
 * customer loan number need to be displayed
 */

export function uploadLoans(
    clientId: string,
    customerId: string,
    files: FileList | File[],
    request: PricingUploadBatchRequest
): Promise<PricingUploadBatch> {
    const formData = new FormData();
    [ ...files ].forEach(file => formData.append('files', file));

    formData.append('dto', JSON.stringify(request));

    return apiUtils.post(`/client/${clientId}/customer/${customerId}/loan/file-upload`, formData, { useAutoContentType: true });
}

export function getLoanUploadBatch(clientId: string, batchId: string, customerId?: string):
    Promise<PricingUploadBatch> {
    return apiUtils.get(customerId
        ? `/client/${clientId}/customer/${customerId}/loan/batch/${batchId}`
        : `/client/${clientId}/loan/batch/${batchId}`);
}

export function forcePricingForBatch(clientId: string, customerId: string, batchId: string) {
    return apiUtils.post(`/client/${clientId}/customer/${customerId}/loan/batch/${batchId}`, null);
}

export function updateBatchFile(clientId: string, customerId: string, batchId: string, fileId: string, file: File):
    Promise<FileUpload> {
    const formData = new FormData();
    formData.append('file', file);

    return apiUtils.put(`/client/${clientId}/customer/${customerId}/loan/batch/${batchId}/file/${fileId}`, formData, {
        useAutoContentType: true
    });
}

export async function downloadFile(clientId: string, batchId: string, fileId: string): Promise<Blob> {
    const response: Response = await apiUtils.get(`/client/${clientId}/loan/batch/${batchId}/file/${fileId}/errors`, null, {
        emptyResponse: true
    });

    return await response.blob();
}

export function downloadTemplate(clientId: string, customerId?: string): Promise<TemplateFile> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/file-upload/template`);
}

export function updateLoanStatus(clientId: string, loanId: string, loanStatusId: string): Promise<RegisteredLoan> {
    return apiUtils.patch(`/client/${clientId}/loan/${loanId}/status`, loanStatusId);
}

export function getLoanNumbers(clientId: string, params: Partial<LoanQueryParams>, customerId?: string) {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/loan-number`, params);
}

export function getRegisteredLoans(
    clientId: string,
    customerId?: string,
    params?: LoanQueryParams
): Promise<PaginatedResponse<RegisteredLoan>> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan`, params);
}

export function getLoansSummary(
    clientId: string,
    customerId?: string,
    params?: LoanSummaryParams
): Promise<LoanSummary[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/summary`, params);
}

export function getFloatedLoans(
    clientId: string,
    customerId?: string,
    params?: LoanQueryParams
): Promise<PaginatedResponse<RegisteredLoan>> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan`, {
        ...params,
        registrationType: RegistrationType.FLOAT
    });
}

export function getLoanHighlights(clientId: string, customerId?: string): Promise<LoanHighlight[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/highlight`);
}

export function getRecentLoans(clientId: string, customerId: string | undefined): Promise<LoanWithActivity[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/recent`);
}

export function registerLoan(
    clientId: string, loanId: string, body: LoanRegistrationParams, customerId?: string
): Promise<LoanRegistrationResponse> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/register`, body);
}

export function getLoanEvents(clientId: string, loanID: string, customerId?: string): Promise<LoanEvent[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanID}/events`);
}

export function getLoanDetail(clientId: string, loanId: string, customerId?: string): Promise<LoanDetail> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}`);
}

export function getProspectiveLoanDetail(
    clientId: string,
    prospectiveLoanId: string,
    customerId?: string
): Promise<LoanDetail> {
    return apiUtils.get(
        `${getAccountBaseUrl(clientId, customerId)}/loan/${prospectiveLoanId}/pending`
    );
}

export function updateLoan(
    clientId: string, loanId: string, loanDetail: LoanDetail, customerId?: string
): Promise<LoanDetail> {
    return apiUtils.put(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}`, loanDetail);
}

export function getLoanComments(clientId: string, loanId: string, customerId?: string): Promise<LoanComment[]> {
    return apiUtils.get(`/client/${clientId}${customerId ? `/customer/${customerId}` : ''}/loan/${loanId}/comment`);
}

export function createLoanComment(
    clientId: string, loanId: string, comment: LoanComment, customerId?: string
): Promise<LoanComment> {
    return apiUtils.post(`/client/${clientId}${customerId ? `/customer/${customerId}` : ''}/loan/${loanId}/comment`, comment);
}

export function updateLoanComment(
    clientId: string, loanId: string, commentId: string, comment: LoanComment, customerId?: string
): Promise<LoanComment> {
    return apiUtils.put(`/client/${clientId}${customerId ? `/customer/${customerId}` : ''}/loan/${loanId}/comment/${commentId}`, comment);
}

export function deleteLoanComment(
    clientId: string, loanId: string, commentId: string, customerId?: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientId}${customerId ? `/customer/${customerId}` : ''}/loan/${loanId}/comment/${commentId}`);
}

// Encompass Loan Condition APIs
export function getUnderwritingConditions(
    clientID: string, customerID: string | undefined, loanID: string
): Promise<EncompassUnderwritingCondition[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientID, customerID)}/loan/${loanID}/conditions/underwriting`);
}

export function getPostClosingConditions(
    clientID: string, loanID: string, customerID?: string
): Promise<PostClosingCondition[]> {
    return apiUtils.get(`/client/${clientID}/client${clientID}${customerID ? `/customer/${customerID}` : ''}/loan/${loanID}/conditions/post-closing`);
}

export function createUnderwritingCondition(
    clientID: string, loanID: string, condition: Omit<EncompassUnderwritingCondition, 'id'>
): Promise<EncompassUnderwritingCondition> {
    return apiUtils.post(`/client/${clientID}/loan/${loanID}/conditions/underwriting`, condition);
}

export function createPostClosingCondition(
    clientID: string, loanID: string, condition: PostClosingCondition
): Promise<PostClosingCondition> {
    return apiUtils.post(`/client/${clientID}/loan/${loanID}/conditions/post-closing`, condition);
}

export function updateUnderwritingCondition(
    clientID: string, loanID: string, condition: EncompassUnderwritingCondition, customerID?: string
): Promise<EncompassUnderwritingCondition> {
    return apiUtils.put(`${getAccountBaseUrl(clientID, customerID)}/loan/${loanID}/conditions/underwriting/${condition.id}`, condition);
}

// separate endpoint for this because customers do not have permission for full management
export function updateUWConditionIsReceived(
    clientID: string, loanID: string, condition: Partial<EncompassUnderwritingCondition>, customerID?: string
): Promise<EncompassUnderwritingCondition> {
    return apiUtils.patch(`/client/${clientID}${customerID ? `/customer/${customerID}` : ''}/loan/${loanID}/conditions/underwriting/${condition.id}`, condition);
}

export function updatePostClosingCondition(
    clientID: string, loanID: string, condition: PostClosingCondition[], customerID?: string
): Promise<PostClosingCondition[]> {
    return apiUtils.patch(`/client/${clientID}/client${clientID}${customerID ? `/customer/${customerID}` : ''}/loan/${loanID}/conditions/post-closing`, condition);
}

export function createUWConditionComment(
    clientID: string, loanID: string, conditionID: string, comment: Omit<Comment, 'id'>, customerID?: string
): Promise<Comment> {
    return apiUtils.patch(`${getAccountBaseUrl(clientID, customerID)}/loan/${loanID}/conditions/underwriting/${conditionID}/comments`, comment);
}

export function deleteUWConditionComment(
    clientID: string, loanID: string, conditionID: string, commentID: string, customerID?: string
): Promise<void> {
    return apiUtils.delete(`${getAccountBaseUrl(clientID, customerID)}/loan/${loanID}/conditions/underwriting/${conditionID}/comments/${commentID}`);
}

export function getUWConditionEvents(
    clientID: string, loanID: string, conditionID: string, customerID?: string
): Promise<UnderwritingConditionEvent[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientID, customerID)}/loan/${loanID}/conditions/${conditionID}/events`);
}

// Encompass Loan Condition APIs end

export interface LoanRegistrationParams {
    pricingResultId: string;
    registrationType: RegistrationType;
    productId: string;
    noteRate?: number;
}

export interface LoanRegistrationResponse {
    clientLoanNumber: string;
    premicorrLoanID: string;
}

// TODO update interface to include the pricing product id (for floated loans)
export interface RegisteredLoan {
    id: string; // (premicorr loanID)
    loanNumber: string; // losLoanNumber
    losLoanId?: string; // guid (used after registering / creating in the los)
    customerLoanNumber: string;
    borrowerName: string;
    loanAmount?: number;
    loanStatus: string; // los loan status
    expirationDate?: string;
    interestRate?: number;
    registrationType: RegistrationType;
    customerId: string;
    pricingResultId: string;
}

/**
 * Registered loan with activity information
 */
export interface LoanWithActivity extends RegisteredLoan, Omit<LoanActivity, 'loanId' | 'loanNumber' | 'customerId'> {}

export interface LoanSummary {
    statusConfigId: number | null;
    loanCount: number;
}

export interface LoanSummaryParams {
    loanNumber?: string | null;
    borrowerName?: string | null;
    customerId?: string | null;
}

export interface LoanQueryParams extends LoanSummaryParams, PaginatedGetParams {
    statusConfigId?: number | null;
}

export interface LoanDetail extends RegisteredLoan {
    assignee?: User;
    loanType: LoanType;

    amortizationType?: AmortizationType;
    armMargin?: number;
    armInitialCap?: number;
    armSubsequentCap?: number;
    armLifeCap?: number;

    escrowsFlag: boolean;
    escrowReserveMonths?: number;
    interestOnlyFlag: boolean;
    loanTerm?: number;
    lockPeriod?: LockPeriod;
    coborrowerFlag?: boolean;
    borrowers: Borrower[];
    borrower1QualifyingTotalIncome?: number;
    borrower2QualifyingTotalIncome?: number;
    propertyType?: PropertyType;
    units?: NumUnits;
    occupancy?: OccupancyType;
    purpose?: LoanPurpose;
    address: Partial<Address>;
    appraisedValue?: number;
    salePrice?: number;
    firstTimeHomeBuyer?: boolean;

    customerId: string;
    customerName: string;
    productCode?: string;
    specialtyProgram?: SpecialtyProgram;
    documentationType?: DocumentationType;
    subordinatedBalance?: number;
    cashOutAmount?: number;
    limitedLiabilityCorp?: string;
    commitmentType?: CommitmentType;
    commitmentIdentifier?: string;
    comments?: string;
    mortgageInsFlag?: boolean;
    mortgageInsCompany?: string;
    mortgageInsCoverage?: number;
    underwriteFlag?: boolean;
    automatedUwSystem?: AutomatedUwSystem;
    automatedUwRecommendation?: AutomatedUwRecommendation;
    proposedHousingPayment?: number;
    otherPayments?: number;
}

export interface TemplateFile {
    fileName: string;
    fileUrl: string;
}

export interface LoanHighlight {
    id: string;
    loanID: string;
    loanNumber: string;
    highlightType: string;
    triggeredAt: string;
}

export interface LoanEvent {
    id: string;
    loanId: string;
    eventType: LoanEventType;
    triggeredByUserId: string;
    triggeredAt: string;
}

export interface LoanComment {
    id: string;
    loanId: string;
    comment: string;
    createdDate: string;
    creatorUserId?: string;
    lastModifiedDate?: string;
}

// Encompass Loan Condition Interfaces
export interface EncompassUnderwritingCondition extends UnderwritingCondition {
    isAddedToConditionSet: boolean;
    isCleared: boolean;
    isFulfilled: boolean;
    isReceived: boolean;
    isRejected: boolean;
    isRemoved: boolean;
    isRequested: boolean;
    isRerequested: boolean;
    isReviewed: boolean;
    isWaived: boolean;
    printExternally: boolean;
    printInternally: boolean;
    forAllApplications: boolean;

    application?: EntityBase;
    clearedBy?: EntityBase;
    clearedDate?: string;
    comments?: Comment[];
    createdBy?: EntityBase;
    createdDate?: string;
    documents?: EntityBase[];
    expirationDate?: string;
    fulfilledBy?: EntityBase;
    fulfilledDate?: string;
    ownerRole?: string;
    receivedBy?: EntityBase;
    receivedDate?: string;
    rejectedBy?: EntityBase;
    requestedBy?: EntityBase;
    requestedDate?: string;
    rerequestedBy?: EntityBase;
    rerequestedDate?: string;
    reviewedBy?: EntityBase;
    reviewedDate?: string;
    source?: string;
    statusDate?: string;
    templateId?: string;
    waivedBy?: EntityBase;
    waivedDate?: string;
}

export interface UnderwritingConditionEvent {
    id: string;
    conditionId: string;
    loanId: string;
    eventType: ConditionEventType;
    triggeredByUserId: string;
    triggeredAt: string;
}

export interface LoanCondition {
    id: string;
    title: string;
    description: string;
    category: PremicorrConditionCategory;
    priorTo: ConditionPriorTo;
    source?: string;
    requestedFrom?: string;
    expectedDate: string;
    daysToReceive: number;
    allowToClear: boolean;
    printExternally: boolean;
    printInternally: boolean;
}

export interface UnderwritingCondition extends LoanCondition {
    underwritingStepId?: string; // foreign key to connect the underwriting condition to the step
    isFulfilled: boolean;
    isRequested: boolean;
    isReceived: boolean;
    isRemoved: boolean;
    forAllApplications: boolean;

    fulfilledDate?: string;
    receivedDate?: string;
    requestedDate?: string;
    statusDate?: string;
    comments?: Comment[];
    documents?: EntityBase[];
    conditionType?: ConditionType;
    status?: ConditionStatus;
}

export interface PostClosingCondition {
    id?: string;
    recipient?: string;
    isSent: boolean;
    sentDate?: string;
    sentBy?: EntityBase;
    isCleared: boolean;
    clearedDate?: string;
    clearedBy?: EntityBase;
    isWaived: boolean;
    waivedDate?: string;
    waivedBy?: EntityBase;
    templateId?: string;
    conditionType?: string;
    isRemoved: boolean;
    title?: string;
    description?: string;
    application?: EntityBase;
    forAllApplications: boolean;
    source?: string;
    expectedDate?: string;
    status?: string;
    statusDate?: string;
    daysToReceive?: number;
    requestedFrom?: string;
    createdDate?: string;
    createdBy?: EntityBase;
    isRequested: boolean;
    requestedDate?: string;
    requestedBy?: EntityBase;
    isRerequested: boolean;
    rerequestedDate?: string;
    rerequestedBy?: EntityBase;
    isReceived: boolean;
    receivedDate?: string;
    receivedBy?: EntityBase;
    isAddedToConditionSet: boolean;
    comments?: Comment[];
    documents?: EntityBase[];
}

export interface PostClosingConditionRequest {
    recipient?: string;
    conditionType?: ConditionType;
    isRemoved: boolean;
    title?: string;
    description?: string;
    forAllApplications: boolean;
    source?: string;
    expectedDate?: string;
    status?: ConditionStatus;
    statusDate?: string;
    daysToReceive?: number;
    requestedFrom?: string;
    isRequested: boolean;
    requestedDate?: string;
    isRerequested: boolean;
    rerequestedDate?: string;
    isReceived: boolean;
    receivedDate?: string;
    comments?: Comment[];
    documents?: EntityBase[];
}

export interface EntityBase {
    entityId?: string;
    entityType?: string;
    entityName?: string;
    entityUri?: string;
}

export interface Comment {
    commentId?: string;
    comments?: string;
    forRoleId?: number;
    forRole?: ForRole;
    dateCreated?: string;
    createdBy?: string;
    createdByName?: string;
    creatorUserId?: string;
}

export interface ForRole {
    entityId?: string;
    entityType?: string;
    entityName?: string;
}
// Encompass Loan Condition Interfaces end
