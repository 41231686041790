import api, { ActiveInactiveEnum, DueDiligenceStep } from '@api';
import { Search } from '@mui/icons-material';
import {
    Checkbox, FormControlLabel, InputAdornment, TextField, Typography
} from '@mui/material';
import {
    CardDialogContent, ExpandableHeader, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useCallback, useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './DueDiligenceChecklistDialog.module.scss';
import DueDiligenceStepCard from './components/DueDiligenceStepCard';


export default function DueDiligenceChecklistDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { customer, refreshCustomer } = useContext(CustomerDetailContext);
    const { id: customerID } = customer!;
    const { id: clientID } = useGetCurrentAccount();

    const [ showOptional, setShowOptional ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ steps, setSteps ] = useState<DueDiligenceStep[]>([]);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setSteps(await api.customer.dueDiligenceStep.getDueDiligenceSteps(clientID, customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the due diligence checklist', error);
        }

        setLoading(false);
    }, [
        clientID, customerID, pageMessage
    ]));

    const activeSteps = steps.filter(step => step.status === ActiveInactiveEnum.ACTIVE);
    const completeSteps = activeSteps.filter(step => step.isCompleted && filterByName(step.name));
    const incompleteSteps = activeSteps.filter(step => (
        !step.isCompleted && (showOptional || step.isRequired) && filterByName(step.name)
    ));

    function filterByName(name: string) {
        return name.toLocaleLowerCase().includes(debouncedTerm);
    }
    function saveStep(savedStep: DueDiligenceStep, isCompletion?: boolean) {
        setSteps(replaceItemById(steps, savedStep));

        const isLastStep = activeSteps.filter(step => !step.isCompleted && step.isRequired).length === 1;

        if (isLastStep && isCompletion) {
            try {
                refreshCustomer();
            } catch (error) {
                pageMessage.handleApiError('An error occurred while marking due diligence as completed', error);
            }
        }
    }

    return (
        <RoutedDialog
            {...props}
            title="Due-diligence checklist"
            maxWidth={false}
            loading={loading}
            saveLoading={saveLoading}
            classes={{
                paper: styles.dialogPaper
            }}
        >
            <CardDialogContent>
                <div className={styles.filterContainer}>
                    <TextField
                        autoFocus
                        variant="standard"
                        placeholder="Filter checklist items"
                        onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />

                    <FormControlLabel
                        control={<Checkbox />}
                        checked={showOptional}
                        onChange={() => setShowOptional(!showOptional)}
                        label="Show optional"
                        className={styles.showOptionalCheckbox}
                    />
                </div>

                <div className={styles.root}>
                    <ExpandableHeader
                        title="Pending"
                        defaultExpand
                        disableExpand={false}
                        secondaryText={`${incompleteSteps.length} item${incompleteSteps.length === 1 ? '' : 's'}`}
                        expandedContent={(
                            <div className={styles.expandedContent}>
                                {incompleteSteps.length === 0 ? (
                                    <Typography>
                                        No pending items exist for this customer
                                    </Typography>
                                ) : incompleteSteps.map(step => (
                                    <DueDiligenceStepCard
                                        key={step.id}
                                        dueDiligenceStep={step}
                                        onSave={saveStep}
                                        setSaveLoading={setSaveLoading}
                                    />
                                ))}
                            </div>
                        )}
                    />

                    <ExpandableHeader
                        title="Completed"
                        defaultExpand={false}
                        disableExpand={false}
                        secondaryText={`${completeSteps.length} item${completeSteps.length === 1 ? '' : 's'}`}
                        expandedContent={(
                            <div className={styles.expandedContent}>
                                {completeSteps.length === 0 ? (
                                    <Typography>
                                        No completed items exist for this customer
                                    </Typography>
                                ) : completeSteps.map(step => (
                                    <DueDiligenceStepCard
                                        key={step.id}
                                        dueDiligenceStep={step}
                                        onSave={saveStep}
                                        setSaveLoading={setSaveLoading}
                                    />
                                ))}
                            </div>
                        )}
                    />
                </div>
            </CardDialogContent>
        </RoutedDialog>
    );
}
