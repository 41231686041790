import {
    Button,
    DialogRoutes,
    FilledSection, FilledSectionProps,
    FilterTextField, OptionalWrapper, SentryRoutes
} from '@tsp-ui/core/components';
import {
    Either, capitalize, replaceItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import clsx from 'clsx';
import {
    ComponentType, Context,
    Dispatch, ReactNode, SetStateAction, useCallback, useState
} from 'react';
import {
    Link, Route, useLocation, useMatch
} from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Page, { PageProps } from '../../components/Page';

import AdminAddEditDialogTemplate from './AdminAddEditDialogTemplate';
import AdminAddPageTemplate, { AdminAddEditForm } from './AdminAddPageTemplate';
import styles from './AdminPageTemplate.module.scss';


export type AdminEntityName = 'client' | 'customer' | 'product' | 'investor' | 'notification' | 'reference guide'
    | 'role' | 'user' | 'llpa';

export type AdminRouteParams<TEntityName extends AdminEntityName = AdminEntityName> = {
    [key in TEntityName as `${TEntityName}ID`]: string;
} & { accountID: string };

type AdminSubEntityName = 'loanProgram' | 'guidelineSet';

export type AdminSubRouteParams<TSubEntityName extends AdminSubEntityName = AdminSubEntityName> = {
    [key in TSubEntityName as `${TSubEntityName}ID`]: string;
} & (TSubEntityName extends 'loanProgram'
    ? AdminRouteParams<'investor'>
    : {}
) & (TSubEntityName extends 'guidelineSet'
    ? AdminSubRouteParams<'loanProgram'>
    : {}
);

export type TDetailPage = ComponentType<{ loading?: boolean }>;

interface AdminPageTemplatePropsBase<TEntityType, TGroup, TEntityDetail = TEntityType> extends Omit<PageProps, 'header' | 'children'> {
    children?: ReactNode;
    Context?: Context<AdminPageContextValues<TEntityType>>;
    CreateEditForm?: AdminAddEditForm<TEntityDetail>;
    DetailPage?: TDetailPage;
    entityName: AdminEntityName;
    EntityGroupComponent: ComponentType<AdminEntityGroupProps<TEntityType, TGroup>>;
    fetchEntities: () => Promise<TEntityType[]>;
    filterByLabel: string;
    justifyFilters?: 'flex-start';
    autoFocusFilter?: boolean;
    labelOverrides?: {
        addButton?: string;
        header?: string;
        filterPlaceholder?: string;
    };
    otherFilters?: ReactNode;
    renderContext?: (entities: TEntityType[], setEntities: Dispatch<SetStateAction<TEntityType[]>>) => ReactNode;
    sortEntitiesBy?: keyof TEntityType;
    disableAddEntity?: boolean;
    disabledAddTooltip?: string;
    editDetails?: boolean;
    dialogRoutes?: DialogRoutes;
    routeQueryParam?: string;
    classes?: {
        entityGroupContainer?: string;
    };
}

type AdminPageTemplateProps<TEntityType, TGroup, TEntityDetail = TEntityType> =
    AdminPageTemplatePropsBase<TEntityType, TGroup, TEntityDetail>
    & Either<
        { filterEntity: (entity: TEntityType, filterInputValue: string) => boolean | undefined },
        { filterEntities: (entities: TEntityType[], filterInputValue: string) => TEntityType[] }
    > & Either<
        { visibleGroups: TGroup[] },
        { getVisibleGroups?: (entities: TEntityType[]) => TGroup[] }
    >;

export interface AdminEntityGroupProps<TEntityType, TGroup> {
    entities: TEntityType[];
    group: TGroup;
    filterText?: string;
}

export interface AdminPageContextValues<TEntityType> {
    entities: TEntityType[];
    setEntities: Dispatch<SetStateAction<TEntityType[]>>;
}

export const defaultAdminPageContextValues: AdminPageContextValues<any> = {
    entities: [],
    setEntities: () => {}
};

export default function AdminPageTemplate
    <TEntityType extends { id: string | number }, TGroup, TEntityDetail extends TEntityType>({
    children,
    Context,
    CreateEditForm,
    DetailPage,
    entityName,
    EntityGroupComponent,
    filterByLabel,
    filterEntity,
    filterEntities,
    fetchEntities,
    headerActions,
    justifyFilters,
    labelOverrides,
    otherFilters,
    sortEntitiesBy,
    visibleGroups,
    getVisibleGroups,
    autoFocusFilter = true,
    disableAddEntity,
    disabledAddTooltip,
    editDetails,
    dialogRoutes,
    routeQueryParam,
    classes,
    ...otherProps
}: AdminPageTemplateProps<TEntityType, TGroup, TEntityDetail>) {
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const [ loading, setLoading ] = useState(true);
    const [ entities, setEntities ] = useState<TEntityType[]>([]);

    const customerID = useGetCurrentAccount().customerId;

    const pageMessage = usePageMessage();
    const { clientID } = useParams();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);
            const newEntities = await fetchEntities();

            if (sortEntitiesBy) {
                newEntities.sort((a, b) => (
                    String(a[sortEntitiesBy]).localeCompare(String(b[sortEntitiesBy]))
                ));
            }

            setEntities(newEntities);
        } catch (error) {
            pageMessage.handleApiError(`An error occurred while fetching ${entityName}s`, error);
        }

        setLoading(false);
    }, [
        sortEntitiesBy, fetchEntities, pageMessage, entityName
    ]));

    const filteredEntities = filterEntities?.(entities, debouncedTerm) || entities.filter(
        (entity) => filterEntity?.(entity, debouncedTerm)
    );

    const renderedChildren = (
        <>
            {(visibleGroups || getVisibleGroups?.(filteredEntities) || []).map((group) => (
                <EntityGroupComponent
                    key={String(group)}
                    entities={filteredEntities}
                    group={group}
                    filterText={debouncedTerm}
                />
            ))}

            {children}
        </>
    );

    const baseRoute = `/accounts/:accountID${customerID ? '/:customerID' : ''}/admin${clientID ? '/clients/:clientID' : ''}/${entityName}s`;
    const entityIDKeyName: `${typeof entityName}ID` = `${entityName}ID`;

    const match = useMatch(`${baseRoute}/:${entityIDKeyName}/*`);
    const entityID = match?.params[entityIDKeyName];

    const closeTo = useMatch(`${baseRoute}/*`)?.pathnameBase || `${baseRoute}`;

    const baseDialogProps = CreateEditForm ? {
        entityName,
        Form: CreateEditForm,
        closeTo: `${closeTo}${routeQueryParam || ''}`
    } : undefined;

    const addPath = '/new';
    const editPath = `/:${entityIDKeyName}/edit`;
    const detailPath = `/:${entityIDKeyName}/*`;

    const renderManagementPage = !DetailPage || !entityID;

    const location = useLocation();
    const params = useParams();

    const managementPage = (
        <Page
            header={labelOverrides?.header || `${capitalize(entityName)} Management`}
            loading={loading}
            headerActions={(
                <>
                    {headerActions}

                    <Button
                        variant="contained"
                        component={Link}
                        to={`new${routeQueryParam || ''}`}
                        tooltip={disableAddEntity ? disabledAddTooltip || '' : ''}
                        disabled={disableAddEntity}
                    >
                        {labelOverrides?.addButton || `Add ${entityName}`}
                    </Button>
                </>

            )}
            {...otherProps}
        >
            <div
                className={clsx(styles.filters, {
                    [styles.justifyStart]: justifyFilters === 'flex-start'
                })}
            >
                <FilterTextField
                    autoFocus={autoFocusFilter}
                    placeholder={labelOverrides?.filterPlaceholder || `Filter ${entityName}s`}
                    helperText={`Filter by ${filterByLabel}`}
                    onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                />

                {otherFilters}
            </div>

            <div className={clsx(styles.sections, classes?.entityGroupContainer)}>
                {renderedChildren}
            </div>

            {baseDialogProps && !DetailPage && (
                <SentryRoutes>
                    <Route
                        path={addPath}
                        element={(
                            <AdminAddEditDialogTemplate<TEntityDetail>
                                {...baseDialogProps}
                                onFormSubmit={newEntity => setEntities(entities.concat(newEntity))}
                            />
                        )}
                    />

                    <Route
                        path={editPath}
                        element={(
                            <AdminAddEditDialogTemplate<TEntityDetail>
                                {...baseDialogProps}
                                entityToEdit={entities.find(({ id }) => `${id}` === entityID) as TEntityDetail}
                                onFormSubmit={updatedEntity => setEntities(
                                    replaceItemById(entities, updatedEntity)
                                )}
                            />
                        )}
                    />
                </SentryRoutes>
            )}
        </Page>
    );

    return (
        <OptionalWrapper
            Component={Context?.Provider!}
            renderWrapper={!!Context}
            value={{
                entities,
                setEntities
            }}
        >
            <SentryRoutes>
                {renderManagementPage && (
                    <Route
                        path="*"
                        element={managementPage}
                    />
                )}

                {dialogRoutes && Object.entries(dialogRoutes).map(([ path, DialogComponent ]) => (
                    <Route
                        key={path}
                        path={`/${path}`}
                        element={(
                            <>
                                {managementPage}

                                <DialogComponent
                                    closeTo={
                                        location.pathname.replace(`/${params['*']}`, '')
                                        + (routeQueryParam || '')
                                    }
                                />
                            </>
                        )}
                    />
                ))}

                {CreateEditForm && DetailPage && (
                    <Route
                        path={addPath}
                        element={(
                            <AdminAddPageTemplate
                                entityName={entityName}
                                Form={CreateEditForm}
                                onFormSubmit={newEntity => setEntities(entities.concat(newEntity))}
                                routeQueryParam={routeQueryParam}
                            />
                        )}
                    />
                )}

                {DetailPage && (
                    <Route
                        path={detailPath}
                        element={(
                            <>
                                <DetailPage loading={loading} />

                                {editDetails && baseDialogProps && (
                                    <SentryRoutes>
                                        <Route
                                            path="edit"
                                            element={(
                                                <AdminAddEditDialogTemplate<TEntityDetail>
                                                    {...baseDialogProps}
                                                    closeTo={`..${routeQueryParam || ''}`}
                                                    entityToEdit={
                                                        entities.find(({ id }) => `${id}` === entityID) as TEntityDetail
                                                    }
                                                    onFormSubmit={(updatedEntity) => {
                                                        setEntities(replaceItemById(entities, updatedEntity));
                                                    }}
                                                />
                                            )}
                                        />
                                    </SentryRoutes>
                                )}
                            </>
                        )}
                    />
                )}
            </SentryRoutes>
        </OptionalWrapper>
    );
}

export function AdminEntityGroup(props: FilledSectionProps) {
    return (
        <FilledSection
            {...props}
            className={clsx(styles.section, props.className)}
        />
    );
}
