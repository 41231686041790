import api, {
    ActiveInactiveEnum,
    AgencyApproval,
    Document,
    DueDiligenceStep,
    DueDiligenceStepType,
    InvestorRelationship,
    License,
    MICompanyRelationship,
    PermissionType,
    WarehouseRelationship,
    dueDiligenceStepTypeDisplayMultipleLowercase,
    dueDiligenceStepTypeDisplaySingularLowercase
} from '@api';
import {
    AssignmentTurnedIn, ExpandLess, ExpandMore, NoteAdd, Save
} from '@mui/icons-material';
import {
    Paper, Tooltip, Typography
} from '@mui/material';
import {
    Button, FileInput, IconButton, LinkedFile, Loader, TextField
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { DocumentLink } from '@views/components/DocumentLink';
import {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './DueDiligenceStepCard.module.scss';
import { AgencyApprovalCard } from './agency-approval/AgencyApprovalCard';
import { InvestorRelationshipCard } from './investor-relationships/InvestorRelationshipCard';
import { LicenseCard } from './licenses/LicenseCard';
import { MIRelationshipCard } from './mi-company-relationship/MIRelationshipCard';
import { WarehouseRelationshipCard } from './warehouse-relationships/WarehouseRelationshipCard';


const acceptedFileTypes = [ 'pdf' ];

interface DueDiligenceStepCardProps {
    dueDiligenceStep: DueDiligenceStep;
    onSave: (step: DueDiligenceStep, isCompletion?: boolean) => void;
    setSaveLoading: Dispatch<SetStateAction<boolean>>;
}

const {
    AGENCY, INVESTORS, WAREHOUSE, INSURANCE, STATE_LICENSE, CUSTOM
} = DueDiligenceStepType;

export default function DueDiligenceStepCard(
    { dueDiligenceStep, onSave, setSaveLoading }: DueDiligenceStepCardProps
) {
    const {
        id: dueDiligenceStepID,
        name,
        description,
        isDocRequired,
        documents: initialDocs,
        isRequired,
        customerId,
        isCompleted,
        type
    } = dueDiligenceStep;

    const [ expanded, setExpanded ] = useState(false);
    const [ documents, setDocuments ] = useState<Document[]>(initialDocs ?? []);
    const { id: clientID } = useGetCurrentAccount();

    const [ canManageDueDiligenceStep ] = useHasPermission([ PermissionType.MANAGE_DUE_DILIGENCE_STEP ]);

    const [ showDocuments, setShowDocuments ] = useState(isDocRequired || !!initialDocs?.length);

    const formMethods = useForm({ defaultValues: dueDiligenceStep });
    const pageMessage = usePageMessage();

    const {
        dueDiligenceEntities,
        setDueDiligenceEntities,
        entitiesLoading,
        canManageDueDiligenceEntities,
        canViewDueDiligenceEntities
    } = useDueDiligenceEntities(type);

    const filteredEntities = dueDiligenceEntities.filter(entity => entity.status === ActiveInactiveEnum.ACTIVE);

    const [ adding, setAdding ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setSaveLoading(true);

        try {
            onSave(await api.customer.dueDiligenceStep.updateDueDiligenceStep(clientID, customerId, formData));

            pageMessage.success('Checklist item saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the checklist item', error);
        }

        setSaveLoading(false);
    });

    const handleComplete = formMethods.handleSubmit(async formData => {
        setSaveLoading(true);

        try {
            onSave(await api.customer.dueDiligenceStep.updateDueDiligenceStep(clientID, customerId, {
                ...formData,
                isCompleted: true
            }), true);

            pageMessage.success('Checklist item completed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while marking the checklist item as completed', error);
        }

        setSaveLoading(false);
    });

    async function uploadFiles(newFiles: File[]) {
        setSaveLoading(true);

        try {
            const newDocs = await api.customer.dueDiligenceStep.uploadDueDiligenceStepDocument(
                clientID, customerId, dueDiligenceStepID, newFiles
            );

            setDocuments(documents.concat(newDocs));

            pageMessage.success(`File${newFiles.length > 1 ? 's' : ''} uploaded`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading documents', error);
        }

        setSaveLoading(false);
    }

    async function removeFile(removedFile: LinkedFile) {
        if (removedFile.id) {
            try {
                await api.customer.dueDiligenceStep.removeDueDiligenceStepDocument(
                    clientID, customerId, dueDiligenceStepID, removedFile.id
                );

                setDocuments(documents.filter(doc => doc.id !== removedFile.id));

                pageMessage.success('File deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the file', error);
            }
        }
    }

    return (
        <Paper variant="outlined">
            <div className={styles.mainRow}>
                <div>
                    <Typography>
                        {`${name}${isRequired ? ' *' : ''}`}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {description}
                    </Typography>
                </div>

                <IconButton
                    onClick={() => setExpanded(!expanded)}
                    tooltip={`${expanded ? 'Hide' : 'Show'} details`}
                >
                    {expanded ? (
                        <ExpandLess color="secondary" />
                    ) : (
                        <ExpandMore color="secondary" />
                    )}
                </IconButton>
            </div>

            {expanded && (entitiesLoading ? <Loader loading /> : (
                <div className={styles.expandedRow}>
                    <div className={styles.buttonContainer}>
                        {type !== CUSTOM && (
                            <Button
                                onClick={() => setAdding(true)}
                                disabled={adding || !canManageDueDiligenceEntities}
                                className={styles.addButton}
                                tooltip={adding
                                    ? `You are already adding a ${dueDiligenceStepTypeDisplaySingularLowercase[type]}`
                                    : !canManageDueDiligenceEntities
                                        ? `You do not have permission to add ${dueDiligenceStepTypeDisplaySingularLowercase[type]}`
                                        : ''}
                            >
                                Add {dueDiligenceStepTypeDisplaySingularLowercase[type]}
                            </Button>
                        )}

                        {!isDocRequired && !showDocuments && (
                            <IconButton
                                tooltip="Attach documents (optional)"
                                onClick={() => setShowDocuments(true)}
                                disabled={!canManageDueDiligenceStep}
                            >
                                <NoteAdd />
                            </IconButton>
                        )}

                        <IconButton
                            tooltip={canManageDueDiligenceStep ? 'Save' : 'You do not have permission to edit due diligence steps'}
                            disabled={!canManageDueDiligenceStep}
                            form="due-diligence-step-form"
                            type="submit"
                        >
                            <Save color="secondary" />
                        </IconButton>

                        {!isCompleted && (
                            <IconButton
                                onClick={handleComplete}
                                tooltip={canManageDueDiligenceStep ? 'Mark as completed' : 'You do not have permission to complete due diligence steps'}
                                disabled={!canManageDueDiligenceStep}
                            >
                                <AssignmentTurnedIn color="success" />
                            </IconButton>
                        )}
                    </div>

                    {type !== CUSTOM && (
                        <div className={styles.dueDiligenceEntities}>
                            {canViewDueDiligenceEntities ? filteredEntities?.map(dueDiligenceEntity => (
                                <DueDiligenceEntityCard
                                    type={type}
                                    key={dueDiligenceEntity.id}
                                    dueDiligenceEntity={dueDiligenceEntity}
                                    setDueDiligenceEntities={setDueDiligenceEntities}
                                    setAdding={setAdding}
                                />
                            )) : (
                                <Typography variant="caption">
                                    You do not have permission to view&nbsp;
                                    {dueDiligenceStepTypeDisplayMultipleLowercase[type]}.
                                </Typography>
                            )}

                            {!filteredEntities?.length && !adding && (
                                <Typography variant="body2">
                                    No {dueDiligenceStepTypeDisplayMultipleLowercase[type]} exist for this customer.
                                </Typography>
                            )}

                            {adding && (
                                <DueDiligenceEntityCard
                                    type={type}
                                    dueDiligenceEntity={undefined}
                                    setDueDiligenceEntities={setDueDiligenceEntities}
                                    setAdding={setAdding}
                                />
                            )}
                        </div>
                    )}

                    {showDocuments && (
                        <>
                            <Typography color="textSecondary">
                                Documents {isDocRequired && '*'}
                            </Typography>

                            <FileInput
                                inputId={`file-upload-${dueDiligenceStepID}`}
                                className={styles.fileInput}
                                title="document"
                                files={documents.map(
                                    doc => new LinkedFile(
                                        [], doc.url.split('/').pop() || 'unnamed document', doc.id, undefined, doc.preSignedUrl, doc.expiration
                                    )
                                ) ?? []}
                                acceptedFileTypes={acceptedFileTypes}
                                onAddFiles={uploadFiles}
                                onRemoveFile={removeFile}
                                canManage={canManageDueDiligenceStep}
                                compact
                                renderLink={file => (
                                    <DocumentLink
                                        document={{
                                            id: file.id!,
                                            url: file.url!,
                                            preSignedUrl: file.url!,
                                            expiration: file.expiration!
                                        }}
                                        name={file.name}
                                        variant="body2"
                                    />
                                )}
                            />
                        </>
                    )}

                    <form
                        noValidate
                        id="due-diligence-step-form"
                        onSubmit={handleSubmit}
                    >
                        <FormProvider {...formMethods}>
                            <Tooltip title={canManageDueDiligenceStep ? '' : 'You do not have permission to edit due diligence steps'}>
                                <span>
                                    <TextField<DueDiligenceStep>
                                        name="notes"
                                        label="Notes"
                                        multiline
                                        disabled={!canManageDueDiligenceStep}
                                        rows={5} // rows is needed until this gets fixed https://github.com/mui/material-ui/issues/33081
                                        fullWidth
                                    />
                                </span>
                            </Tooltip>
                        </FormProvider>
                    </form>
                </div>
            ))}
        </Paper>
    );
}

export type DueDiligenceEntity =
WarehouseRelationship | InvestorRelationship | AgencyApproval | MICompanyRelationship | License;

interface DueDiligenceEntityCardProps {
    type: DueDiligenceStepType;
    dueDiligenceEntity?: DueDiligenceEntity;
    setDueDiligenceEntities: Dispatch<SetStateAction<DueDiligenceEntity[]>>;
    setAdding: Dispatch<SetStateAction<boolean>>;
    locked?: boolean;
}

export function DueDiligenceEntityCard({
    type, dueDiligenceEntity, setDueDiligenceEntities, setAdding, locked
}: DueDiligenceEntityCardProps) {
    return type === AGENCY ? (
        <AgencyApprovalCard
            agencyApproval={dueDiligenceEntity as AgencyApproval}
            setAgencyApprovals={setDueDiligenceEntities as Dispatch<SetStateAction<AgencyApproval[]>>}
            setAdding={setAdding}
            locked={locked}
        />
    ) : type === INVESTORS ? (
        <InvestorRelationshipCard
            investorRelationship={dueDiligenceEntity as InvestorRelationship}
            setInvestorRelationships={setDueDiligenceEntities as Dispatch<SetStateAction<InvestorRelationship[]>>}
            setAdding={setAdding}
            locked={locked}
        />
    ) : type === WAREHOUSE ? (
        <WarehouseRelationshipCard
            warehouseRelationship={dueDiligenceEntity as WarehouseRelationship}
            setWarehouseRelationships={setDueDiligenceEntities as Dispatch<SetStateAction<WarehouseRelationship[]>>}
            setAdding={setAdding}
            locked={locked}
        />
    ) : type === INSURANCE ? (
        <MIRelationshipCard
            miRelationship={dueDiligenceEntity as MICompanyRelationship}
            setMIRelationships={setDueDiligenceEntities as Dispatch<SetStateAction<MICompanyRelationship[]>>}
            setAdding={setAdding}
            locked={locked}
        />
    ) : type === STATE_LICENSE ? (
        <LicenseCard
            license={dueDiligenceEntity as License}
            setLicenses={setDueDiligenceEntities as Dispatch<SetStateAction<License[]>>}
            setAdding={setAdding}
            locked={locked}
        />
    ) : null;
}

export function useDueDiligenceEntities(type: DueDiligenceStepType) {
    const [ dueDiligenceEntities, setDueDiligenceEntities ] = useState<DueDiligenceEntity[]>([]);
    const [ entitiesLoading, setEntitiesLoading ] = useState(true);

    const pageMessage = usePageMessage();
    const { id: clientID, customerId } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const getDueDilEntities = {
        [INSURANCE]: api.customer.miCompanyRelationship.getMICompanyRelationships,
        [WAREHOUSE]: api.customer.warehouseRelationship.getWarehouseRelationships,
        [AGENCY]: api.customer.agencyApproval.getAgencyApprovals,
        [INVESTORS]: api.customer.investorRelationship.getInvestorRelationships,
        [STATE_LICENSE]: api.customer.licenses.getLicenses,
        [CUSTOM]: () => []
    }[type];

    const [ canManageDueDiligenceEntities, canViewDueDiligenceEntities ] = useHasPermission(
        {
            [INSURANCE]: [ PermissionType.MANAGE_MI_COMPANY_RELATIONSHIP, PermissionType.VIEW_MI_COMPANY_RELATIONSHIP ],
            [WAREHOUSE]: [ PermissionType.MANAGE_WAREHOUSE_RELATIONSHIP, PermissionType.VIEW_WAREHOUSE_RELATIONSHIP ],
            [AGENCY]: [ PermissionType.MANAGE_AGENCY_APPROVAL, PermissionType.VIEW_AGENCY_APPROVAL ],
            [INVESTORS]: [ PermissionType.MANAGE_INVESTOR_RELATIONSHIP, PermissionType.VIEW_INVESTOR_RELATIONSHIP ],
            [STATE_LICENSE]: [ PermissionType.MANAGE_LICENSES, PermissionType.VIEW_LICENSES ],
            [CUSTOM]: [ PermissionType.MANAGE_DUE_DILIGENCE_STEP, PermissionType.VIEW_DUE_DILIGENCE_STEP ]
        }[type]
    );

    useAsyncEffect(useCallback(async () => {
        setEntitiesLoading(true);

        if (type !== CUSTOM) {
            try {
                // Both customers and clients have access to this, so customerId can be from route or current account
                setDueDiligenceEntities(await getDueDilEntities(clientID, customerId || customerID));
            } catch (error) {
                pageMessage.handleApiError(`An error occurred while fetching ${dueDiligenceStepTypeDisplayMultipleLowercase[type]}`, error);
            }
        }

        setEntitiesLoading(false);
    }, [
        clientID, customerID, getDueDilEntities, pageMessage, type, customerId
    ]));

    return {
        dueDiligenceEntities,
        setDueDiligenceEntities,
        entitiesLoading,
        canManageDueDiligenceEntities,
        canViewDueDiligenceEntities
    };
}
