import api, {
    AutomatedUwRecommendation, Borrower, FieldConfig, ManualLoanEntry,
    PricingFieldName, WebSocketEventType, pricingFieldNameDisplay
} from '@api';
import {
    Button, Link as MuiLink, Step, StepLabel, Stepper, Typography
} from '@mui/material';
import { PaperSaveLoader } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Page from '../components/Page';

import styles from './ManualLoanEntryPage.module.scss';
import AdditionalDetailsStep from './components/AdditionalDetailsStep';
import BorrowersStep from './components/BorrowersStep';
import LoanStep from './components/LoanStep';
import PropertyStep from './components/PropertyStep';


export interface ManualLoanEntryFormValues extends Omit<ManualLoanEntry, 'automatedUwRecommendation'> {
    automatedUwRecommendation?: AutomatedUwRecommendation | '';
}

const defaultValues: Partial<ManualLoanEntry> = {
    borrowers: [ {} ]
};

/**
 * Renders the manual loan entry page
 *
 * @constructor
 */
export default function ManualLoanEntryPage() {
    const { customerId } = useGetCurrentAccount();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<ManualLoanEntry>({ defaultValues });
    const [ fieldConfigs, setFieldConfigs ] = useState<FieldConfig[]>([]);
    const [ activeStep, setActiveStep ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ customerIdSearch, setCustomerIdSearch ] = useState('');

    const { id: clientId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        try {
            const configs = await api.fieldConfig.getFieldConfigs(clientId);
            setFieldConfigs(configs);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching field configurations', error);
        }
    },
    [ clientId, pageMessage ]));

    const goToPreviousStep = () => setActiveStep(activeStep - 1);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        if (activeStep === 3) {
            try {
                setLoading(true);

                const enabledFields = fieldConfigs
                    .filter(config => config.enabled)
                    .map(config => config.fieldName);

                // Combine enabled fields with always required fields for validation
                const allRequiredFields = [ ...new Set([ ...enabledFields, ...ALWAYS_REQUIRED_FIELDS ]) ];

                // Create the filtered form data - include all fields that have values
                const filteredFormData = Object.fromEntries(
                    Object.entries(formData).filter(([ key, value ]) => {
                        // Special handling for borrowers array
                        if (key === 'borrowers') {
                            return true;
                        }

                        // Include the field if it has a non-empty value
                        return value !== undefined && value !== '' && value !== null;
                    })
                ) as Partial<ManualLoanEntry>;

                // Special handling for borrowers
                if (formData.borrowers?.length) {
                    filteredFormData.borrowers = formData.borrowers
                        .filter(borrower => Object.values(borrower).some(value => value !== undefined && value !== '' && value !== null))
                        .map(borrower => {
                            // Include all non-empty fields for each borrower
                            const filteredBorrower: Partial<Borrower> = {};
                            Object.entries(borrower).forEach(([ key, value ]) => {
                                if (value !== undefined && value !== '' && value !== null) {
                                    filteredBorrower[key as keyof Borrower] = value;
                                }
                            });
                            return filteredBorrower;
                        });
                }

                // Validate that all required fields are present
                const missingFields = allRequiredFields.filter(field => {
                    const propertyName = fieldNameToPropertyMap[field];
                    return propertyName && !(propertyName in filteredFormData);
                });

                if (missingFields.length > 0) {
                    const missingFieldNames = missingFields
                        .map(field => pricingFieldNameDisplay[field])
                        .join(', ');
                    throw new Error(`Missing required fields: ${missingFieldNames}`);
                }

                // Ensure at least one borrower if borrowers were entered
                if (filteredFormData.borrowers?.length === 0) {
                    delete filteredFormData.borrowers;
                }

                await api.pricing.priceLoan(
                    clientId,
                    filteredFormData as ManualLoanEntry,
                    customerIdSearch || customerId!
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while creating the loan', error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    });

    useEffect(() => api.webSocket.subscribe(
        WebSocketEventType.PRICING_COMPLETE,
        () => navigate('./../..')
    ), [ navigate ]);

    function isFieldEnabled(fieldName: PricingFieldName): boolean {
        const config = fieldConfigs.find(config => config.fieldName === fieldName);
        return config?.enabled ?? false;
    }

    return (
        <Page
            header="Price new loan manually"
            variant="centered"
        >
            <Stepper
                activeStep={activeStep}
                className={styles.stepper}
            >
                <Step>
                    <StepLabel>
                        Borrower(s)
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Property
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Loan
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Additional details

                        <Typography
                            variant="caption"
                            className={styles.optionalCaption}
                        >
                            (optional)
                        </Typography>
                    </StepLabel>
                </Step>
            </Stepper>

            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <FormProvider {...formMethods}>
                    {activeStep === 0 ? (
                        <BorrowersStep isFieldEnabled={isFieldEnabled} />
                    ) : activeStep === 1 ? (
                        <PropertyStep
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                        />
                    ) : activeStep === 2 ? (
                        <LoanStep
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                        />
                    ) : (
                        <AdditionalDetailsStep
                            setCustomerIdSearch={setCustomerIdSearch}
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                            submitButton={(
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            )}
                        />
                    )}
                </FormProvider>
            </form>

            <Typography>
                Have a loan file?
                {' '}

                <MuiLink
                    component={Link}
                    to="./../.."
                >
                    Upload loans
                </MuiLink>

                {' '}
                instead
            </Typography>

            <PaperSaveLoader loading={loading} />
        </Page>
    );
}

const fieldNameToPropertyMap: Record<PricingFieldName, keyof ManualLoanEntry> = {
    [PricingFieldName.PROPERTY_CITY]: 'propertyCity',
    [PricingFieldName.LOAN_LIMIT_TYPE]: 'loanLimitType',
    [PricingFieldName.LOAN_AMOUNT]: 'loanAmount',
    [PricingFieldName.INTEREST_RATE]: 'interestRate',
    [PricingFieldName.LOCK_PERIOD]: 'lockPeriod',
    [PricingFieldName.BASE_LOAN_AMOUNT]: 'baseLoanAmount',
    [PricingFieldName.CUSTOMER_LOAN_NUMBER]: 'customerLoanNumber',
    [PricingFieldName.PROP_STATE]: 'propertyState',
    [PricingFieldName.PROP_COUNTY]: 'propertyCounty',
    [PricingFieldName.PROP_ZIP]: 'propertyZipCode',
    [PricingFieldName.APPRAISED_VALUE]: 'appraisedValue',
    [PricingFieldName.SALES_PRICE]: 'salesPrice',
    [PricingFieldName.PROPERTY_TYPE]: 'propertyType',
    [PricingFieldName.LOAN_TYPE]: 'loanType',
    [PricingFieldName.AMORT_TYPE]: 'amorType',
    [PricingFieldName.LOAN_TERM]: 'loanTerm',
    [PricingFieldName.LOAN_PURPOSE]: 'loanPurpose',
    [PricingFieldName.OCCUPANCY_TYPE]: 'occupancyType',
    [PricingFieldName.FICO_CREDIT_SCORE]: 'loanFICO',
    [PricingFieldName.DTI]: 'dti',
    [PricingFieldName.BORR1_QUALIFYING_TOTAL_INCOME]: 'borrower1QualifyingTotalIncome',
    [PricingFieldName.BORR2_QUALIFYING_TOTAL_INCOME]: 'borrower2QualifyingTotalIncome',
    [PricingFieldName.PROPOSED_HOUSING_PAYMENT]: 'proposedHousingPayment',
    [PricingFieldName.OTHER_PAYMENTS]: 'otherPayments',
    [PricingFieldName.UNITS]: 'propertyUnits',
    [PricingFieldName.DOC_TYPE]: 'documentationType',
    [PricingFieldName.SUBORDINATED_LOAN_AMOUNT]: 'subordinatedBalance',
    [PricingFieldName.FIRST_TIME_HOME_BUYER]: 'firstTimeHomeBuyer',
    [PricingFieldName.ESCROWS_FLAG]: 'escrowsFlag',
    [PricingFieldName.ESCROW_RESERVES_MONTHS]: 'escrowReservesMonths',
    [PricingFieldName.INTEREST_ONLY_FLAG]: 'interestOnlyFlag',
    [PricingFieldName.AUTOMATED_UW_SYSTEM]: 'automatedUwSystem'
};

const ALWAYS_REQUIRED_FIELDS = [
    PricingFieldName.CUSTOMER_LOAN_NUMBER,
    PricingFieldName.PROP_STATE,
    PricingFieldName.BASE_LOAN_AMOUNT
] as const;
