import api, {
    ConditionEventType,
    ConditionStatus, EncompassUnderwritingCondition, PermissionType,
    UnderwritingConditionEvent, conditionEventTypeDisplay, conditionPriorToDisplay, conditionStatusDisplay
} from '@api';
import {
    AddBox, Assignment, AssignmentReturn, Comment, Description, Edit,
    Update
} from '@mui/icons-material';
import {
    CircularProgress,
    Paper, SvgIcon, SvgIconProps, Tooltip, Typography
} from '@mui/material';
import {
    formatDate, replaceItemById, useAsyncEffect, useParams
} from '@tsp-ui/core';
import {
    Button, ExpandableCard, FileInput, IconButton, Loader, usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle, useGetCurrentAccount, useHasPermission } from '@utils';
import CommentCard from '@views/admin/condition-config/CommentCard';
import UserLink from '@views/admin/users/UserLink';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import clsx from 'clsx';
import {
    format, formatDistanceStrict, formatDistanceToNowStrict, parseISO
} from 'date-fns';
import {
    Fragment, useCallback, useContext, useState
} from 'react';

import AddConditionCommentDialog from './AddConditionCommentDialog';
import AddEditConditionDialog from './AddEditConditionDialog';
import styles from './ConditionsSummary.module.scss';


interface ConditionsSummaryProps {
    header?: string;
    conditions?: EncompassUnderwritingCondition[];
    onStartClick?: () => void;
    className?: string;
}

export default function ConditionsSummary({
    header, conditions: conditionsProp, onStartClick, className
}: ConditionsSummaryProps) {
    const { conditions: allConditions } = useContext(LoanDetailContext);
    const conditions = conditionsProp || allConditions;
    const [ conditionDialogOpen, setConditionDialogOpen ] = useState(false);
    const [ conditionToEdit, setConditionToEdit ] = useState<EncompassUnderwritingCondition>();

    const { customerId } = useGetCurrentAccount();

    const openConditionDialog = (conditionId?: string | undefined) => {
        if (conditionId !== undefined) {
            setConditionToEdit(
                conditions?.find((condition) => condition.id === conditionId) as EncompassUnderwritingCondition
            );
        } else {
            setConditionToEdit(undefined);
        }

        setConditionDialogOpen(true);
    };

    const [ canView, canManageConditions ] = useHasPermission([
        PermissionType.VIEW_LOAN_CONDITIONS,
        PermissionType.MANAGE_LOAN_CONDITIONS
    ]);

    return !canView ? (
        <Typography variant="body2">
            You do not have permission to view loan conditions.
        </Typography>
    ) : (
        <div className={clsx(styles.root, className)}>
            <Typography
                variant="h6"
                className={styles.header}
            >
                {header}

                {!customerId && (
                    <Button
                        onClick={() => openConditionDialog()}
                        disabled={!canManageConditions}
                        tooltip={canManageConditions ? '' : 'You do not have permission to add loan conditions'}
                    >
                        Add condition
                    </Button>
                )}
            </Typography>

            {!conditions?.length ? (
                <NoConditions onStartClick={onStartClick} />
            ) : (
                <ConditionsGroup
                    conditions={conditions}
                    openConditionDialog={openConditionDialog}
                />
            )}

            {conditionDialogOpen && (
                <AddEditConditionDialog
                    open={conditionDialogOpen}
                    conditionToEdit={conditionToEdit}
                    onClose={() => setConditionDialogOpen(false)}
                />
            )}
        </div>
    );
}

interface ConditionsGroupProps {
    conditions: EncompassUnderwritingCondition[] | undefined;
    openConditionDialog: (conditionId: string) => void;
}

function ConditionsGroup({
    conditions, openConditionDialog
}: ConditionsGroupProps) {
    const conditionsByPriorTo = conditions ? groupByPriorTo(conditions) : {};

    return (
        <>
            {Object.entries(conditionsByPriorTo).map(([ priorTo, conditionsGroup ]) => (
                <div
                    key={priorTo}
                    className={styles.conditionPriorToGroup}
                >
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {`Prior to ${conditionPriorToDisplay[priorTo as keyof typeof conditionPriorToDisplay].toLowerCase()}`}
                    </Typography>

                    <div className={styles.conditions}>
                        {conditionsGroup.map(condition => (
                            <ConditionCard
                                key={condition.id}
                                condition={condition}
                                openConditionDialog={openConditionDialog}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
}

interface ConditionCardProps {
    condition: EncompassUnderwritingCondition;
    openConditionDialog: (conditionId: string) => void;
}

const acceptedFileTypes = [ 'pdf' ];

function ConditionCard({ condition, openConditionDialog }: ConditionCardProps) {
    const { id: clientId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const { setConditions, loanDetail } = useContext(LoanDetailContext);
    const loanID = loanDetail?.losLoanId;

    const [ eventsLoading, setEventsLoading ] = useState(false);
    const [ conditionEvents, setConditionEvents ] = useState<UnderwritingConditionEvent[]>();
    const [ files, setFiles ] = useState<File[]>([]);
    const [ updateLoading, setUpdateLoading ] = useState(false);

    const { customerId } = useGetCurrentAccount();
    const [
        canManageConditions, canManageComments, canMarkAsReceived
    ] = useHasPermission([
        PermissionType.MANAGE_LOAN_CONDITIONS,
        PermissionType.MANAGE_LOAN_CONDITION_COMMENTS,
        PermissionType.UPDATE_LOAN_CONDITION_ISRECEIVED
    ]);

    const [ hideDropZone, setHideDropZone ] = useState(true);
    const [ commentDialogOpen, setCommentDialogOpen ] = useState(false);

    const isClearedOrWaived = condition.status === ConditionStatus.CLEARED
        || condition.status === ConditionStatus.WAIVED;

    const handleToggleDropZone = () => {
        setHideDropZone(!hideDropZone);
    };

    const openCommentDialog = () => {
        setCommentDialogOpen(true);
    };

    const conditionId = condition.id;

    useAsyncEffect(useCallback(async () => {
        setEventsLoading(true);

        if (!conditionEvents) {
            try {
                setConditionEvents(await api.loans.getUWConditionEvents(clientId, loanID!, conditionId, customerId));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching condition events', error);
            }
        }

        setEventsLoading(false);
    }, [
        clientId, conditionId, conditionEvents, customerId, loanID, pageMessage
    ]));

    async function updateIsCleared(isCleared: boolean) {
        setUpdateLoading(true);

        try {
            const updatedCondition = await api.loans.updateUnderwritingCondition(
                clientId, loanID!, {
                    ...condition,
                    // passing isCleared as true will update the condition status to 'Cleared'
                    // passing isCleared as false will revert the condition status to what it was prior to being cleared
                    isCleared
                }
            );

            setConditions(conditions => replaceItemById(conditions, updatedCondition));

            pageMessage.success(`Condition ${isCleared ? 'cleared' : 'updated'}`);
        } catch (error) {
            pageMessage.handleApiError(`An error occurred while ${isCleared ? 'clearing' : 'updating'} the condition`, error);
        }

        setUpdateLoading(false);
    }

    async function updateIsReceived() {
        setUpdateLoading(true);

        try {
            const updatedCondition = await api.loans.updateUWConditionIsReceived(
                clientId, loanID!, {
                    ...condition,
                    isReceived: !condition.isReceived
                }, customerId
            );

            setConditions(conditions => replaceItemById(conditions, updatedCondition));

            pageMessage.success(`Condition marked as ${condition.isReceived ? 'not' : ''} received`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the condition', error);
        }

        setUpdateLoading(false);
    }

    return (
        <ExpandableCard
            className={styles.positionRelative}
            indentContent
            expandedContent={(
                <div className={styles.root}>
                    <div className={styles.expandedContent}>
                        <Paper
                            variant="outlined"
                            className={styles.filledCard}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Comments
                            </Typography>

                            {condition.comments?.map(comment => (
                                <CommentCard
                                    key={comment.commentId}
                                    conditionID={condition.id}
                                    comment={comment}
                                    onEventsUpdate={setConditionEvents}
                                />
                            ))}
                        </Paper>

                        <div className={styles.leftColumn}>
                            <FileInput
                                compact
                                className={styles.fileInput}
                                title="document"
                                files={files}
                                acceptedFileTypes={acceptedFileTypes}
                                hideDropZone={hideDropZone}
                                onAddFiles={(newFiles) => setFiles([ ...files, ...newFiles ])}
                                onRemoveFile={(removedFile) => (
                                    setFiles(files?.filter(({ name }) => name === removedFile.name))
                                )}
                            />
                        </div>

                        <Paper
                            variant="outlined"
                            className={styles.filledCard}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Timeline
                            </Typography>

                            {eventsLoading ? (
                                <div className={styles.timelineLoading}>
                                    <CircularProgress
                                        size={40}
                                        color="primary"
                                    />

                                    <Typography
                                        variant="body2"
                                    >
                                        Loading timeline...
                                    </Typography>
                                </div>
                            ) : conditionEvents?.map((event, index, { length }) => (
                                <Fragment key={event.id}>
                                    <ActionCard
                                        Icon={conditionEventIconMappings[event.eventType]}
                                        description={conditionEventTypeDisplay[event.eventType]}
                                        date={event.triggeredAt}
                                        actor={event.triggeredByUserId}
                                    />

                                    {index !== length - 1 && (
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            className={styles.timelineSeparator}
                                        >
                                            {formatDistanceStrict(
                                                parseISO(event.triggeredAt),
                                                parseISO(conditionEvents[index + 1].triggeredAt)
                                            )}
                                        </Typography>
                                    )}
                                </Fragment>
                            ))}
                        </Paper>
                    </div>

                    <div className={styles.buttonContainer}>
                        <div>
                            <Button
                                onClick={openCommentDialog}
                                disabled={!canManageComments || isClearedOrWaived}
                                tooltip={tooltipTitle({
                                    'You do not have permission to add comments': !canManageComments,
                                    'You cannot add comments to a resolved condition': isClearedOrWaived
                                })}
                            >
                                Add comment
                            </Button>

                            {/* TODO cannot implement docs until we hear back from Virpack */}
                            <Button onClick={handleToggleDropZone}>
                                {hideDropZone ? 'Add documents' : 'Hide documents'}
                            </Button>
                        </div>

                        {customerId ? (
                            <Button
                                variant="contained"
                                className={styles.actionButton}
                                onClick={updateIsReceived}
                                disabled={!canMarkAsReceived || updateLoading || isClearedOrWaived}
                                tooltip={tooltipTitle({
                                    'You do not have permission to update this condition': !canMarkAsReceived,
                                    'You cannot update a cleared or waived condition': isClearedOrWaived
                                })}
                            >
                                {condition.isReceived ? 'Mark as not received' : 'Mark as received'}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                className={styles.actionButton}
                                onClick={() => updateIsCleared(!isClearedOrWaived)}
                                disabled={!canManageConditions || updateLoading}
                                tooltip={canManageConditions ? '' : 'You do not have permission to manage conditions'}
                            >
                                {isClearedOrWaived ? 'Revert condition' : 'Clear condition'}
                            </Button>
                        )}

                    </div>

                    <Loader
                        loading={updateLoading}
                        className={styles.positionAbsolute}
                    />
                </div>
            )}
        >
            <div className={styles.card}>
                <div className={styles.conditionInfo}>
                    <Typography fontWeight={500}>
                        {condition.title}
                    </Typography>

                    <Typography>
                        {condition.description}
                    </Typography>
                </div>

                <div className={styles.info}>
                    <div className={styles.chipsRow}>
                        <Tooltip title="Condition status">
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                className={styles.chip}
                            >
                                {conditionStatusDisplay[condition.status!] || ''}
                            </Typography>
                        </Tooltip>

                        <Tooltip
                            title={condition?.documents?.length === 0 ? 'No documents' : 'Documents attached'}
                            enterDelay={0}
                            className={styles.infoIcon}
                        >
                            <Description
                                color={condition?.documents?.length === 0 ? 'disabled' : 'primary'}
                                fontSize="small"
                            />
                        </Tooltip>

                        <Tooltip
                            title={condition?.comments?.length === 0 ? 'No comments' : 'Comments noted'}
                            enterDelay={0}
                            className={styles.infoIcon}
                        >
                            <Comment
                                color={condition?.comments?.length === 0 ? 'disabled' : 'primary'}
                                fontSize="small"
                            />
                        </Tooltip>

                        {!customerId && (
                            <IconButton
                                className={styles.editButton}
                                tooltip={tooltipTitle({
                                    'You do not have permission to edit conditions': !canManageConditions,
                                    'You cannot edit a cleared or waived condition': isClearedOrWaived,
                                    'Edit condition': canManageConditions && !isClearedOrWaived
                                })}
                                disabled={!canManageConditions || isClearedOrWaived}
                                onClick={() => openConditionDialog(condition.id)}
                            >
                                <Edit color="secondary" />
                            </IconButton>
                        )}
                    </div>

                    <Typography
                        variant="caption"
                        color="textSecondary"
                        whiteSpace="nowrap"
                        align="right"
                    >
                        {`created ${formatDate(condition.createdDate)} by`}

                        <UserLink userId={condition.createdBy?.entityId || ''} />
                    </Typography>
                </div>
            </div>

            {commentDialogOpen && (
                <AddConditionCommentDialog
                    open={commentDialogOpen}
                    onClose={() => setCommentDialogOpen(false)}
                    conditionID={condition.id}
                    onEventsUpdate={setConditionEvents}
                />
            )}
        </ExpandableCard>
    );
}

interface ActionCardProps {
    Icon?: typeof SvgIcon;
    iconColor?: SvgIconProps['color'];
    description: string;
    date: string;
    actor: string;
}

function ActionCard({
    Icon, iconColor = 'secondary', description, date, actor
}: ActionCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.actionCard}
        >
            <Typography variant="body2">
                {Icon && (
                    <Icon
                        color={iconColor}
                        fontSize="small"
                        className={styles.actionCardIcon}
                    />
                )}

                {description}

                <Typography
                    variant="caption"
                    align="right"
                    color="textSecondary"
                    className={styles.actionCardDate}
                >
                    <Tooltip
                        title={format(parseISO(date), 'MM/dd/yyyy')}
                    >
                        <span>
                            {formatDistanceToNowStrict(parseISO(date))} ago by
                        </span>
                    </Tooltip>

                    <UserLink userId={actor} />
                </Typography>
            </Typography>
        </Paper>
    );
}

function groupByPriorTo(conditions: EncompassUnderwritingCondition[]) {
    const groupedPriorTos: Record<string, EncompassUnderwritingCondition[]> = {};

    conditions.forEach((condition) => {
        const priorTo: string = condition.priorTo.toUpperCase() || '';

        if (!groupedPriorTos[priorTo]) {
            groupedPriorTos[priorTo] = [];
        }
        groupedPriorTos[priorTo].push(condition);
    });
    return groupedPriorTos;
}

export function NoConditions({ onStartClick }: {onStartClick?(): void}) {
    // TODO fix logic for isCredit to be based on the underwritingCategoryId instead of the tab
    const isCredit = useParams().underwritingTab === 'CREDIT_LIABILITIES'; // broken logic

    return (
        <Paper
            variant="outlined"
            className={styles.noConditions}
        >
            {isCredit ? (
                <>
                    <Typography>
                        This loan does not have any credit & liabilities conditions yet
                    </Typography>

                    <Button
                        variant="contained"
                        onClick={onStartClick}
                    >
                        Start credit & liabilities
                    </Button>
                </>
            ) : (
                <>
                    <Typography>
                        This loan does not have any conditions yet
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        Use the sidebar to navigate or click below to
                    </Typography>

                    <Button variant="contained">
                        Start underwriting
                    </Button>
                </>
            )}
        </Paper>
    );
}

const conditionEventIconMappings = {
    [ConditionEventType.DOCUMENTS_ADDED]: Description,
    [ConditionEventType.READY_FOR_REVIEW]: Assignment,
    [ConditionEventType.NOTE_ADDED]: Comment,
    [ConditionEventType.NOTE_DELETED]: Comment,
    [ConditionEventType.CONDITION_CREATED]: AddBox,
    [ConditionEventType.CONDITION_UPDATED]: Update,
    [ConditionEventType.CONDITIONAL_APPROVAL_SUBMITTED]: AssignmentReturn
};

// TODO: Decide if code below is needed for future implementation.
// It should correspond to Premicorr conditions, but since the page
// handles Encompass UW conditions, this is currently unnecessary &
// would probably be moved to the loan.api file anyway

// interface Condition {
//     id: string;
//     description: string;
//     detail: string;
//     category: ConditionCategory;
//     group: ConditionGroup;
//     stage: ConditionStage;
//     source: ConditionSource;
//     status: ConditionStatus;
//     responsibility: ConditionResponsibility;

//     exception?: ConditionException;
//     documents?: Document[];
//     events?: ConditionEvent[];
//     notes?: Note[];
//     includeOnApproval: boolean; // may not need - can this be based on responsibility?
// }

// interface ConditionEvent {
//     type: ConditionEventType;
//     date: Date;
//     by: User;
// }

// interface ConditionException {
//     date: Date;
//     by: User;
//     status: ConditionExceptionStatus;
// }

// enum ConditionCategory { // Byte Pro 'Class'
//     LOAN_SET_UP = 'LOAN_SET_UP',
//     NON_DELEGATED = 'NON_DELEGATED',
//     DELEGATED = 'DELEGATED' // Post-closing
// }

// enum ConditionGroup { // UW menu items, Byte Pro 'Type'
//     AUS = 'AUS',
//     CREDIT = 'CREDIT',
//     INCOME = 'INCOME',
//     ASSETS = 'ASSETS',
//     APPRAISAL = 'APPRAISAL',
//     APPLICATION = 'APPLICATION',
//     DISCLOSURE = 'DISCLOSURE',
//     TITLE_FLOOD = 'TITLE_FLOOD',
//     INSURANCE = 'INSURANCE',
//     REO = 'REO'
// }

// enum ConditionStage {
//     PRIOR_TO_DOCS = 'PRIOR_TO_DOCS',
//     PRIOR_TO_CLOSING = 'PRIOR_TO_CLOSING',
//     AT_CLOSING = 'AT_CLOSING',
//     PRIOR_TO_PURCHASE = 'PRIOR_TO_PURCHASE',
//     SUSPENSE = 'SUSPENSE'
// }

// enum ConditionSource {
//     SYSTEM_ADDED = 'SYSTEM_ADDED',
//     INVESTOR_OVERLAY = 'INVESTOR_OVERLAY',
//     UNDERWRITER_ADDED = 'UNDERWRITER_ADDED'
// }

// enum ConditionResponsibility {
//     CUSTOMER = 'CUSTOMER',
//     UNDERWRITER = 'UNDERWRITER',
//     CLOSER = 'CLOSER',
//     ESCROW_COMPANY = 'ESCROW_COMPANY',
//     LOAN_OFFICER = 'LOAN_OFFICER',
//     LOAN_PROCESSOR = 'PROCESSOR',
//     SHIPPER = 'SHIPPER',
//     TITLE_COMPANY = 'TITLE_COMPANY'
// }

// enum ConditionEventType {
//     CREATED = 'CREATED',
//     REQUESTED = 'REQUESTED', // when loan goes to Conditional Approval status
//     RECEIVED = 'RECEIVED', // optionally entered by responsibile party
//     SUBMITTED = 'SUBMITTED', // when loan goes to Conditions Submitted status
//     CLEARED = 'CLEARED'
// }

// enum ConditionExceptionStatus {
//     APPROVED_BY_LENDER = 'APPROVED_BY_LENDER',
//     APPROVED_BY_INVESTOR = 'APPROVED_BY_INVESTOR',
//     DENIED_BY_LENDER = 'DENIED_BY_LENDER',
//     DENIED_BY_INVESTOR = 'DENIED_BY_INVESTOR',
//     PENDING_WITH_LENDER = 'PENDING_WITH_LENDER',
//     PENDING_WITH_INVESTOR = 'PENDING_WITH_INVESTOR',
//     WAIVED_NOT_REQUIRED = 'WAIVED_NOT_REQUIRED'
// }
