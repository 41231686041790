import { LoanHighlight } from '@api';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';

import { AlertCard } from './AlertCard';
import styles from './LoanHighlights.module.scss';


interface LoanHighlightsProps {
    loanHighlights: LoanHighlight[];
}

export function LoanHighlights({ loanHighlights }: LoanHighlightsProps) {
    const highlightTypes = [ ...new Set(loanHighlights.map(({ highlightType }) => highlightType)) ];

    return (
        <div>
            <Typography
                fontWeight={500}
                className={styles.sectionHeader}
            >
                Loan Highlights
            </Typography>

            <div className={styles.highlightSection}>
                {highlightTypes.map((highlightType) => (
                    <HighlightGroup
                        key={highlightType}
                        title={highlightType}
                        highlights={loanHighlights.filter(highlight => highlight.highlightType === highlightType)}
                    />
                ))}
            </div>
        </div>
    );
}

interface HighlightGroupProps {
    title: ReactNode;
    highlights: LoanHighlight[];
}

function HighlightGroup({ title, highlights }: HighlightGroupProps) {
    return (
        <>
            <Typography
                variant="body2"
                color="textSecondary"
                className={styles.highlightTitle}
            >
                {title}
            </Typography>

            {highlights.map(highlight => (
                <AlertCard
                    key={highlight.id}
                    loanID={highlight.loanID}
                    loanNumber={highlight.loanNumber}
                    date={highlight.triggeredAt}
                />
            ))}
        </>
    );
}
