import api, { LoanPricingResult, LoanWithActivity, PermissionType } from '@api';
import {
    Description, OpenInNew, PriorityHigh, Settings
} from '@mui/icons-material';
import {
    Avatar, Button, Divider, IconButton, Link as MuiLink, Paper, Tooltip, Typography
} from '@mui/material';
import {
    FilledSection, IconTypography, RoutedDialogManager, usePageMessage
} from '@tsp-ui/core/components';
import { formatCurrency, formatCurrencyAbbreviation, useAsyncEffect } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, withAuth } from '@utils';
import LoanNumberLink from '@views/components/MainNav/components/NotificationsButton/components/LoanNumberLink';
import { LoanCard } from '@views/loans/components/LoanCard';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import LoanNumberAutoComplete from '@views/loans/components/LoanNumberAutoComplete';
import { RecentLoanCard } from '@views/loans/components/RecentLoanCard';
import {
    ActiveElement,
    ArcElement, ChartData, ChartEvent, Chart as ChartJS, ChartOptions, Tooltip as ChartTooltip, Legend
// @ts-ignore
} from 'chart.js';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
// @ts-ignore
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import { getMockedPricingResult } from '../../api/pricing/pricing-mocks';
import Page from '../components/Page';

import styles from './AccountDashboardPage.module.scss';


const results: LoanPricingResult[] = [
    getMockedPricingResult('Jim Burrows'),
    getMockedPricingResult('John Burrows'),
    getMockedPricingResult('Jeff Burrows', true)
].reverse();

export default function AccountDashboardPage() {
    const [ recentLoans, setRecentLoans ] = useState<LoanWithActivity[]>([]);
    const [ loading, setLoading ] = useState(false);
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setRecentLoans(await api.loans.getRecentLoans(clientId, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching recent loans', error);
        }

        setLoading(false);
    },  [
        clientId, customerId, pageMessage
    ]));

    const [ loanNumberSearch, setLoanNumberSearch ] = useState('');

    return (
        <Page
            className={styles.main}
            loading={loading}
            header={(
                <div className={styles.pageHeader}>
                    Dashboard

                    <LoanNumberAutoComplete
                        className={styles.autocomplete}
                        loanNumberSearch={loanNumberSearch}
                        setLoanNumberSearch={setLoanNumberSearch}
                        renderAsLinks
                    />
                </div>
            )}
            headerActions={(
                <IconButton onClick={() => api.webSocket.simulateNotification()}>
                    <Settings color="secondary" />
                </IconButton>
            )}
        >
            <div className={styles.root}>
                <div className={styles.mainContent}>
                    <div className={styles.charts}>
                        <ChartSection
                            title="Loans by pipeline stage"
                            data={pipelineStatusData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans in pipeline"
                        />

                        <ChartSection
                            title="Loans by conditions outstanding"
                            data={otherData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans with conditions outstanding"
                        />

                        <ChartSection
                            title="Current volume by product type"
                            data={volumeData}
                            renderValue={(value) => (
                                <AbbreviatedCurrencyDisplay value={value} />
                            )}
                            totalLabel="total volume"
                        />
                    </div>

                    <FilledSection
                        header="Watched loans"
                        variant="light"
                        className={styles.loans}
                    >
                        {results?.map((result) => (
                            <LoanCard
                                isPendingLoan
                                loanPricingResult={result}
                                className={styles.loanRow}
                                additionalDetails={(
                                    <IconTypography
                                        compact
                                        className={styles.iconTypography}
                                        icon={(
                                            <Tooltip title="3 indexed documents">
                                                <Description color="primary" />
                                            </Tooltip>
                                        )}
                                    >
                                        3
                                    </IconTypography>
                                )}
                            />
                        ))}
                    </FilledSection>

                    <FilledSection
                        header="Recent loans"
                        variant="light"
                        className={styles.loans}
                    >
                        {recentLoans.length ? recentLoans?.map((recentLoan) => (
                            <RecentLoanCard
                                key={recentLoan.id}
                                loan={recentLoan}
                            />
                        )) : (
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                Your recently viewed loans will appear here!<br />

                                <>
                                    <MuiLink
                                        component={Link}
                                        to="../loans"
                                    >
                                        Go to the loans page
                                    </MuiLink> to get started.
                                </>
                            </Typography>
                        )}
                    </FilledSection>
                </div>

                <div className={styles.sidebar}>
                    <div>
                        <Typography className={styles.header}>
                            Alerts
                        </Typography>

                        <div className={styles.alerts}>
                            <Paper
                                variant="outlined"
                                className={styles.alertCard}
                            >
                                <Typography>
                                    Seller guide changed
                                </Typography>

                                <div className={styles.alertActions}>
                                    <div className={styles.alertIcons}>
                                        <Tooltip
                                            title="This alert was sent with high importance"
                                            enterDelay={0}
                                        >
                                            <PriorityHigh
                                                color="warning"
                                                fontSize="small"
                                            />
                                        </Tooltip>

                                        <Tooltip title="View alert details">
                                            <IconButton size="small">
                                                <OpenInNew
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        align="right"
                                        component="div"
                                        className={styles.date}
                                    >
                                        3 days ago
                                    </Typography>
                                </div>
                            </Paper>

                            <Paper
                                variant="outlined"
                                className={styles.alertCard}
                            >
                                <Typography>
                                    Lending limits changed
                                </Typography>

                                <div className={styles.alertActions}>
                                    <div className={styles.alertIcons}>
                                        <Tooltip title="View alert details">
                                            <IconButton size="small">
                                                <OpenInNew
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        align="right"
                                        component="div"
                                        className={styles.date}
                                    >
                                        4 days ago
                                    </Typography>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    <div className={styles.sidebar}>
                        <div>
                            <Typography className={styles.header}>
                                Activity stream
                            </Typography>

                            <div className={styles.activityStream}>
                                <ActivityStreamItem
                                    avatarColor="#7092BF"
                                    initials="MO"
                                    loanNumber="7543672"
                                    dateText="5 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Awaiting Docs & Setup</b>

                                            {' '}to

                                            {' '}<b>Underwriting</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#846ca5"
                                    initials="JK"
                                    loanNumber="7543671"
                                    dateText="15 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#954271"
                                    initials="NP"
                                    loanNumber="7543672"
                                    dateText="45 minutes ago"
                                    message="Loan document uploaded"
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#846ca5"
                                    initials="JK"
                                    loanNumber="7543670"
                                    dateText="1 hour ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#7092BF"
                                    initials="MO"
                                    loanNumber="7543670"
                                    dateText="2 hours ago"
                                    message="Loan locked"
                                />

                                <Divider className={styles.divider} />

                                <Button size="small">Load more activity</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    ':loanID/loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    ':loanID/loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true)
};

interface ActivityStreamItemProps {
    avatarColor: string;
    initials: string;
    message: ReactNode;
    loanNumber: string;
    dateText: string;
}

function ActivityStreamItem({
    avatarColor, initials, message, loanNumber, dateText
}:ActivityStreamItemProps) {
    return (
        <div className={styles.activityStreamItem}>
            <Avatar sx={{ bgcolor: avatarColor }}>
                {initials}
            </Avatar>

            <div>
                <Typography
                    variant="body2"
                    className={styles.activityStreamMessage}
                >
                    {message}
                </Typography>

                <div className={styles.activityStreamBottomRow}>
                    <LoanNumberLink
                        color="textPrimary"
                        loanNumber={loanNumber}
                    />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {dateText}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

interface ChartSectionProps {
    title: ReactNode;
    data: Array<{
        title: string;
        value: number;
        color: string;
    }>;
    renderValue: (value: number) => ReactNode;
    totalLabel: ReactNode;
}

ChartJS.register(ArcElement, ChartTooltip, Legend);

function ChartSection({
    title,
    data,
    renderValue,
    totalLabel
}: ChartSectionProps) {
    const [ hovered, setHovered ] = useState<number | undefined>(undefined);
    const chartRef = useRef<ChartJS<'doughnut'>>(null);
    const total = data.reduce((sum, v) => sum + v.value, 0);

    useEffect(() => {
        const chart = chartRef.current;
        if (!chart) {
            return;
        }

        const { canvas } = chart;
        const handleMouseLeave = () => {
            setHovered(undefined);
        };

        canvas.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            canvas.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    const chartData: ChartData<'doughnut'> = {
        labels: data.map(d => d.title),
        datasets: [
            {
                data: data.map(d => d.value),
                backgroundColor: data.map((d, i) => (hovered === i ? 'var(--app-color_secondary)' : d.color)),
                borderColor: 'white',
                borderWidth: 2.5,
                hoverOffset: 4,
                hoverBackgroundColor: '#2E7D32'
            }
        ]
    };

    // Create a string version of the renderValue result
    function getTooltipLabel(value: number) {
        const element = renderValue(value);

        if (React.isValidElement(element)) {
            return formatCurrencyAbbreviation(value);
        }

        return element?.toString();
    }

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '75%',
        animation: {
            duration: 1000
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'black',
                bodyAlign: 'center',
                displayColors: false,
                callbacks: {
                    label: (context) => `${getTooltipLabel(context.raw as number)}`
                }
            }
        },
        onHover: (event: ChartEvent, elements: ActiveElement[]) => {
            setHovered(elements.length ? elements[0].index : undefined);
        }
    };

    return (
        <div>
            <Typography
                fontWeight={500}
                align="center"
            >
                {title}
            </Typography>

            <div className={styles.chartContainer}>
                <div className={styles.chartLabel}>
                    <Typography
                        color="textSecondary"
                        variant="h4"
                    >
                        {renderValue(total)}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                    >
                        {totalLabel}
                    </Typography>
                </div>

                <Doughnut
                    ref={chartRef}
                    data={chartData}
                    options={options}
                />
            </div>
        </div>
    );
}

interface AbbreviatedCurrencyDisplayProps {
    value: number;
}

function AbbreviatedCurrencyDisplay({ value }: AbbreviatedCurrencyDisplayProps) {
    return (
        <Tooltip title={formatCurrency(value)}>
            <span>
                {formatCurrencyAbbreviation(value)}
            </span>
        </Tooltip>
    );
}

const pipelineStatusData = [
    {
        title: 'Awaiting Docs & Setup',
        value: 10,
        color: '#7092BF'
    },
    {
        title: 'Underwriting',
        value: 15,
        color: '#7684B8'
    },
    {
        title: 'Conditioned',
        value: 10,
        color: '#7F74AD'
    },
    {
        title: 'Clear to Close',
        value: 10,
        color: '#89649D'
    },
    {
        title: 'Closed',
        value: 20,
        color: '#915389'
    },
    {
        title: 'Denied/Withdrawn',
        value: 20,
        color: '#954271'
    }
];

const volumeData = [
    {
        title: 'Conventional',
        value: 3435636,
        color: '#7092BF'
    },
    {
        title: 'FHA',
        value: 1231515,
        color: '#7880b6'
    },
    {
        title: 'VA',
        value: 5005213,
        color: '#846ca5'
    },
    {
        title: 'USDA',
        value: 2500566,
        color: '#8f588e'
    },
    {
        title: 'Non-agency',
        value: 3453671,
        color: '#954271'
    }
];

const otherData = [
    {
        title: '1 condition outstanding',
        value: 5,
        color: '#7092BF'
    },
    {
        title: '2 conditions outstanding',
        value: 15,
        color: '#846ca5'
    },
    {
        title: '3 conditions outstanding',
        value: 10,
        color: '#954271'
    }
];
