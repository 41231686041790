import api, {
    DocumentType, LOSLoanStatus, LoanStatusConfig, PermissionType
} from '@api';
import { Edit, InfoOutlined } from '@mui/icons-material';
import {
    Paper, Tooltip, Typography
} from '@mui/material';
import {
    Button, FilledSection, IconButton, RoutedDialogManager
} from '@tsp-ui/core/components';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { withAuth } from '@utils';
import { useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission } from '@utils/hooks';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import { LoanStatusConfigCard } from './LoanStatusConfigCard';
import { LoanStatusDialog } from './LoanStatusDialog';
import styles from './LoanStatusPage.module.scss';
import { RequiredDocumentsDialog } from './RequiredDocumentsDialog';


export interface LoanStatusContextValue {
    loanStatusConfigs: LoanStatusConfig[];
    setLoanStatusConfigs: Dispatch<SetStateAction<LoanStatusConfig[]>>;
    losLoanStatuses: LOSLoanStatus[];
    requiredDocCodes: string[];
    setRequiredDocCodes: Dispatch<SetStateAction<string[]>>;
    availableDocs: DocumentType[];
}

export const LoanStatusContext = createContext<LoanStatusContextValue>({
    loanStatusConfigs: [],
    setLoanStatusConfigs: () => {},
    losLoanStatuses: [],
    requiredDocCodes: [],
    setRequiredDocCodes: () => {},
    availableDocs: []
});

export default function LoanStatusManagementPage() {
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ loanStatusConfigs, setLoanStatusConfigs ] = useState<LoanStatusConfig[]>([]);
    const [ losLoanStatuses, setLosLoanStatuses ] = useState<LOSLoanStatus[]>([]);
    const [ availableDocs, setAvailableDocs ] = useState<DocumentType[]>([]);
    const [ requiredDocCodes, setRequiredDocCodes ] = useState<string[]>([]);

    const requiredDocsByContainer = requiredDocCodes.reduce((acc, code) => {
        const {
            name, containerLabel
        } = availableDocs.find(({ code: docCode }) => docCode === code)!;

        return {
            ...acc,
            [containerLabel]: [
                ...(acc[containerLabel] || []),
                name
            ]
        };
    }, {} as Record<string, string[]>);

    const orderedConfigs = loanStatusConfigs.sort((a, b) => a.displayOrder - b.displayOrder);

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_BULK_COMMITMENT ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        const [
            loanStatusConfigsResult, losLoanStatusesResult, availableDocsResult, requiredDocCodesResult
        ] = await Promise.allSettled([
            api.loanStatus.getLoanStatusConfigs(clientId),
            api.loanStatus.getLOSLoanStatuses(clientId),
            api.documentType.getAvailableDocTypes(clientId),
            api.documentType.getRequiredDocCodes(clientId)
        ]);

        handlePromiseSettledResult(loanStatusConfigsResult, setLoanStatusConfigs, 'An error occurred while fetching loan status configs');
        handlePromiseSettledResult(losLoanStatusesResult, setLosLoanStatuses, 'An error occurred while fetching los loan statuses');
        handlePromiseSettledResult(availableDocsResult, setAvailableDocs, 'An error occurred while fetching available docs');
        handlePromiseSettledResult(requiredDocCodesResult, setRequiredDocCodes, 'An error occurred while fetching required doc codes');

        setLoading(false);
    }, [ clientId, handlePromiseSettledResult ]));

    const loanStatusContextValue = useMemo(() => ({
        loanStatusConfigs,
        setLoanStatusConfigs,
        losLoanStatuses,
        requiredDocCodes,
        setRequiredDocCodes,
        availableDocs
    }), [
        loanStatusConfigs, losLoanStatuses, requiredDocCodes, availableDocs
    ]);

    return (
        <LoanStatusContext.Provider value={loanStatusContextValue}>
            <Page
                header="Loan Status Management"
                loading={loading}
                headerActions={(
                    <Button
                        component={Link}
                        to="add"
                        variant="contained"
                        disabled={!canManage}
                        tooltip={canManage ? '' : 'You do not have permission to add loan status configurations'}
                    >
                        Add loan status configuration
                    </Button>
                )}
            >
                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                Premicorr loan statuses

                                <Tooltip title="The display order configured here will be used in the loan pipeline. Documents are required for the first configured loan status.">
                                    <InfoOutlined color="info" />
                                </Tooltip>
                            </>
                        )}
                        noResultsMessage="No loan status configurations are currently configured."
                    >
                        {orderedConfigs.map((config) => (
                            <LoanStatusConfigCard
                                key={config.id}
                                loanStatusConfig={config}
                            />
                        ))}
                    </FilledSection>

                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                Required Documents

                                <IconButton
                                    tooltip={canManage ? 'Edit required documents' : 'You do not have permission to edit required documents'}
                                    disabled={!canManage}
                                    component={Link}
                                    to="required-docs"
                                >
                                    <Edit color="secondary" />
                                </IconButton>
                            </>
                        )}
                        noResultsMessage="No required documents are currently configured."
                    >
                        {Object.entries(requiredDocsByContainer).map(([ container, docs ]) => (
                            <Paper
                                className={styles.documentContainer}
                                elevation={0}
                                key={container}
                            >
                                <Typography variant="body2">
                                    {container}
                                </Typography>

                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {docs.join(', ')}
                                </Typography>
                            </Paper>
                        ))}
                    </FilledSection>
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </LoanStatusContext.Provider>
    );
}

const dialogRoutes = {
    add: withAuth(LoanStatusDialog, [ PermissionType.MANAGE_LOAN_STATUS_CONFIGS ], true),
    ':loanStatusConfigId': withAuth(LoanStatusDialog, [ PermissionType.MANAGE_LOAN_STATUS_CONFIGS ], true),
    'required-docs': withAuth(RequiredDocumentsDialog, [ PermissionType.MANAGE_LOAN_STATUS_CONFIGS ], true)
};
