import api, { Account, UserType } from '@api';
import { Paper, Typography } from '@mui/material';
import { getAccounts, updateAccounts } from '@redux/auth';
import { useDispatch, useSelector } from '@redux/store';
import {
    HorizontalLabelGroup, HorizontalLabeledValue, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import Page from '../components/Page';

import styles from './SwitchAccountsPage.module.scss';


export default function SwitchAccountsPage() {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();
    const reduxAccounts = useSelector(getAccounts);
    const { state } = useLocation();
    const [ loading, setLoading ] = useState(false);
    const accounts = loading ? undefined : reduxAccounts;

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const authUser = await api.auth.fetchAuthUser();
            dispatch(updateAccounts(authUser));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching user accounts', error);
        }

        setLoading(false);
    }, [
        dispatch, pageMessage, setLoading
    ]));

    return (
        <Page
            header="Select an account"
            variant="centered"
            loading={loading}
        >
            {accounts?.length === 1 && !state?.disableRedirect ? (
                <Navigate
                    to={!accounts[0].customerId
                        ? `/accounts/${accounts[0].id}`
                        : `/accounts/${accounts[0].id}/${accounts[0].customerId}`}
                />
            ) : (
                accounts?.length === 0 ? (
                    <div className={styles.root}>
                        <Typography>
                            Your user is not assigned to any active accounts.
                            Please seek assistance from your system adminsitrator.
                        </Typography>
                    </div>
                ) : (
                    <div className={styles.root}>
                        {accounts?.map((account) => (
                            <AccountCard
                                key={`${account.id}${account.customerId ? `-${account.customerId}` : ''}`}
                                account={account}
                            />
                        ))}
                    </div>
                ))}
        </Page>
    );
}

interface AccountCardProps {
    account: Account;
}

function AccountCard({ account }: AccountCardProps) {
    return (
        <Paper
            component={Link}
            to={account.customerId
                ? `/accounts/${account.id}/${account.customerId}`
                : `/accounts/${account.id}`}
            className={clsx(styles.card, {
                [styles.customer]: account.accountUserType === UserType.CUSTOMER
            })}
        >
            <div className={styles.logoContainer}>
                <img
                    alt={`${account.name} logo`}
                    src={account.logoUrl}
                />
            </div>

            <div>
                <Typography
                    variant="h6"
                    color="textSecondary"
                >
                    {account.name}
                </Typography>

                {account.accountUserType === UserType.CUSTOMER && (
                    <HorizontalLabelGroup>
                        <HorizontalLabeledValue
                            label="Customer:"
                            value={account.customerName}
                        />
                    </HorizontalLabelGroup>
                )}
            </div>
        </Paper>
    );
}
